import React, { useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import {
  AdditionalDocumentsRowActions,
  AdditionalDocumentsRowProps
} from "../../../../../types";
import { formatDateWithTime } from "../../../../../helpers/date-formatter";
import { extractId } from "../../../../../helpers/converter-helper";
import { connect } from "react-redux";
import { IAnyProps } from "../../../../../interfaces/any-props.interface";
import { downloadDocumentFile } from "../../../../../api/requests/document-download";
import { bindActionCreators } from "redux";
import { removeReportsAdditionalDocument } from "../../../../../actions";
import { ConfirmModal } from "../../../../../components/ConfirmModal";

//TODO fix the component props type
const AdditionalDocumentsRow = ({
  additionalDocument,
  reloadItems,
  downloadDocumentFile,
  removeReportsAdditionalDocument
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const {
    name,
    documentUploadedAt,
    document: documentLink
  } = additionalDocument;
  const documentId = extractId(documentLink);
  const removeItem = () =>
    removeReportsAdditionalDocument({
      id: documentId,
      documentName: name,
      reloadItems
    });

  return (
    <>
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell>{formatDateWithTime(documentUploadedAt)}</TableCell>
        <TableCell align="right" style={{ maxWidth: 100 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => downloadDocumentFile(documentId)}
          >
            Download
          </Button>
        </TableCell>
        <TableCell align="right" style={{ maxWidth: 30 }}>
          <IconButton onClick={() => setIsOpened(true)}>
            <Icon>delete</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
      {isOpened && (
        <ConfirmModal
          isOpened={isOpened}
          onClose={() => setIsOpened(false)}
          onConfirm={removeItem}
          confirmDescription="Are you sure you want to delete this document?"
        />
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      downloadDocumentFile,
      removeReportsAdditionalDocument
    },
    dispatch
  );
};

const connected = connect<
  IAnyProps,
  AdditionalDocumentsRowActions,
  AdditionalDocumentsRowProps
>(
  null,
  mapDispatchToProps
)(AdditionalDocumentsRow);

export default connected;
