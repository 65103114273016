import * as React from "react";
import {Modal} from "@material-ui/core";

class DocumentDeleteModal extends React.Component<any & any> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.props.onClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <p>Delete document confirmation</p>
          <h4>Are you sure?</h4>
          <hr/>
          <p>Are you sure you want to delete this document?</p>
          <div className="text-right">
            <button className="btn-cancel" onClick={this.props.onClose}>No</button>
            <button type="submit" className="btn-save" onClick={this.props.onDelete(this.props.item)}>Yes</button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default DocumentDeleteModal;
