import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import DocumentTable from "./DocumentTable";
import {Button} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {Link, Route, Switch, RouteComponentProps} from "react-router-dom";
import DocumentCreate from "./DocumentCreate";
import PermissionBlock from "../../../components/auth/PermissionBlock";
import * as Roles from "../../../constants/roles";
import {TestStatus} from "../../../constants/test-status";
import TestStatusBlock from "../../../components/auth/TestStatusBlock";

class DocumentsBlock extends React.Component<RouteComponentProps<any> & any, {}> {
  baseUrl = this.props.match.url;
  basePath = this.props.match.path;

  createDocument = () => <DocumentCreate backRoute={this.baseUrl} testId={this.props.match.params.test}/>;

  render() {
    return (
      <div className="styled-block">
        <div className="block-header-wrapper">
          <h4 className="block-header">Documents</h4>
          <PermissionBlock roles={[Roles.ROLE_PLANNER]}>
            <TestStatusBlock
              currentStatus={this.props.test.status}
              requiredStatuses={[
                TestStatus.STATUS_DRAFT,
                TestStatus.STATUS_SCHEDULED,
                TestStatus.STATUS_BOOKING_REQUESTED,
                TestStatus.STATUS_BOOKING_REQUESTED_EDITED
              ]}>
              <Link to={this.baseUrl + '/new-test-document'}>
                <button className="btn-new">
                  New document
                </button>
              </Link>
            </TestStatusBlock>
          </PermissionBlock>
        </div>
        <DocumentTable test={this.props.test}/>
        <Switch>
          <Route path={this.basePath + '/new-test-document'} render={this.createDocument}/>
        </Switch>
      </div>
    );
  }
}

export default withRouter(DocumentsBlock);