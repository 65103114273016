import * as React from 'react';
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import PermissionBlock from "../../../components/auth/PermissionBlock";
import * as Roles from "../../../constants/roles";
import {TestStatus} from "../../../constants/test-status";
import TestStatusBlock from "../../../components/auth/TestStatusBlock";

class RiskChartHeader extends React.Component<any, {}> {
  baseUrl = this.props.match.url;

  render() {
    return (
      <div>
        <div className="block-header-wrapper">
          <h5 className="block-header">Risk Chart</h5>
          <PermissionBlock roles={[Roles.ROLE_TESTER]}>
            <TestStatusBlock
              currentStatus={this.props.test.status}
              requiredStatuses={[
                TestStatus.STATUS_BOOKED,
                TestStatus.STATUS_REPORT_SUBMITTED,
                TestStatus.STATUS_AMENDMENT_SENT,
                TestStatus.STATUS_AMENDMENT_RESPONDED,
              ]}>
              <Link to={this.baseUrl + "/new-risk"}>
                <button className="btn-new">
                  New&nbsp;Risk
                </button>
              </Link>
            </TestStatusBlock>
          </PermissionBlock>
          <PermissionBlock roles={[Roles.ROLE_PLANNER]}>
            <TestStatusBlock
              currentStatus={this.props.test.status}
              requiredStatuses={[
                TestStatus.STATUS_DRAFT,
                TestStatus.STATUS_SCHEDULED,
                TestStatus.STATUS_BOOKING_REQUESTED,
                TestStatus.STATUS_BOOKING_REQUESTED_EDITED
              ]}>
              <Link to={this.baseUrl + "/new-risk"}>
                <button className="btn-new">
                  New&nbsp;Risk
                </button>
              </Link>
            </TestStatusBlock>
          </PermissionBlock>
        </div>
      </div>
    );
  }
}

export default withRouter(RiskChartHeader);