import React from "react";
import { DayCellProps } from "../../../../types/timesheets-and-expenses";
import { DayTimeExpensesLog } from "./DayTimeExpensesLog";
import {
  getConsultantViewTimeLogLink,
  getCreateTimeLogLink,
  getEditTimeLogLink
} from "../../../../helpers/router-helper";
import {
  CellCreateButton,
  CellEditButton,
  CellViewButton
} from "./CellActionButton";
import {
  consultantCanCreateDay,
  consultantCanEditDay,
  consultantCanViewDay
} from "../../../../helpers/timesheets-expenses";

const ConsultantDayCell = (props: DayCellProps) => {
  const { isActiveWeek, days, expensesAmount, id, day, status, vat } = props;
  const dayTimeExpensesLog = !!days || !!expensesAmount;
  if (dayTimeExpensesLog && isActiveWeek && consultantCanEditDay(status)) {
    return (
      <div className="timesheet-day-cell-wrap">
        <DayTimeExpensesLog days={days} expenses={expensesAmount} vat={vat} />
        <CellEditButton to={`${getEditTimeLogLink()}/${id}`} />
      </div>
    );
  }
  if (!dayTimeExpensesLog && isActiveWeek && consultantCanCreateDay(status)) {
    return (
      <div className="timesheet-day-cell-wrap">
        <CellCreateButton to={`${getCreateTimeLogLink()}/${day}`} />
      </div>
    );
  }
  if (dayTimeExpensesLog && (!isActiveWeek || consultantCanViewDay(status))) {
    return (
      <div className="timesheet-day-cell-wrap">
        <DayTimeExpensesLog days={days} expenses={expensesAmount} vat={vat} />
        <CellViewButton to={`${getConsultantViewTimeLogLink()}/${id}`} />
      </div>
    );
  }
  return null;
};

export default ConsultantDayCell;
