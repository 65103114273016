import { getItem as getUserById } from "./users";
import {
  convertorAssignedProjectsFromSwagger,
  convertorCreateConsultantToSwagger,
  convertorFromSwagger,
  convertorReDeActivateConsultantToSwagger,
  convertorToSwagger
} from "../convertors/consultants";
import { extractJSONLD, fetchRequestBody } from "../../helpers/request-helper";
import { getApiJSONLD } from "../swagger/api-factory";
import {
  consultantProfileProject,
  consultantProfileType,
  FilterParamsType,
  FormValuesType,
  ProjectRepeater,
  ResponseDataType
} from "../../types/consultant";
import { extractId } from "../../helpers/converter-helper";

export const getItems = async (params: FilterParamsType) => {
  return getApiJSONLD()
    .then(api => api.getUserCollection(convertorToSwagger(params)))
    .then(extractJSONLD(convertorFromSwagger));
};

export const getAllAssignedProjects = async () => {
  return getApiJSONLD()
    .then(api => api.getProjectCollection({ consultantsProjects: 1 }))
    .then(extractJSONLD(convertorAssignedProjectsFromSwagger));
};

export const createItem = async (params: FormValuesType) => {
  const convertedParams = convertorCreateConsultantToSwagger(params);
  return getApiJSONLD().then(api =>
    api.createProjectConsultant({ consultant: convertedParams })
  );
};
export const updateItem = async ({ id, consultant }) => {
  const convertedParams = convertorCreateConsultantToSwagger(consultant);
  return getApiJSONLD().then(api =>
    api.updateProjectConsultant({ id, consultant: convertedParams })
  );
};
export const deleteItem = async (params: { id: number }) => {
  return getApiJSONLD().then(api => api.deleteUserItem(params));
};
export const archiveItem = async (params: {
  id: number;
  user: ResponseDataType;
}) => {
  return getApiJSONLD()
    .then(api =>
      api.archiveUserItem(convertorReDeActivateConsultantToSwagger(params))
    )
    .then(fetchRequestBody);
};
export const unarchiveItem = async (params: {
  id: number;
  user: ResponseDataType;
}) => {
  return getApiJSONLD()
    .then(api =>
      api.unarchiveUserItem(convertorReDeActivateConsultantToSwagger(params))
    )
    .then(fetchRequestBody);
};
export const getConsultantProfile = async (id: number) => {
  return getApiJSONLD()
    .then(api => api.getConsultantProfileById({ id }))
    .then(fetchRequestBody);
};

export const getConsultantData = async (consultantId: number | string) => {
  try {
    const consultant = await getUserById(consultantId);
    const { firstName, lastName, email, phone, consultantProfile } = consultant;
    const consultantProfileId = extractId(consultantProfile);
    const consultantProfileData: consultantProfileType = await getConsultantProfile(
      consultantProfileId
    );
    const { rate, consultantProjects } = consultantProfileData;
    const projects: ProjectRepeater[] = consultantProjects.map(
      (project: consultantProfileProject) => ({
        project: project.project_id,
        projectRate: project.project_rate
      })
    );
    return {
      id: consultantProfileId,
      firstName,
      lastName,
      email,
      phone,
      consultantRate: +rate,
      projects
    };
  } catch (error) {
    throw new Error(error?.message || "Can`t get consultant`s data");
  }
};
