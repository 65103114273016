import * as React from "react";
import { withRouter } from "react-router-dom";
import { testRequest } from "../../../actions/test";
import {
  AfterBookedStatuses,
  DisplayBookingProposalStatuses,
  TestStatus,
  TestStatusesForQuestionsBlock
} from "../../../constants/test-status";
import QuestionsBlock from "../questions/QuestionsBlock";
import TestInfo from "./TestInfo";
import { Grid } from "@material-ui/core";
import MessagesBlock from "../messages/MessagesBlock";
import { dataFetcher } from "../../../components/dataFetcher";
import selector from "../../../selectors/test";
import { result } from "lodash";
import TestDetailButtons from "./TestDetailsButtons";
import DocumentsBlock from "../documents/DocumentsBlock";
import BookingProposalEdit from "./BookingProposalEdit";
import BookingProposal from "./BookingProposal";
import PermissionBlock from "../../../components/auth/PermissionBlock";
import {
  ROLE_ADMIN,
  ROLE_PLANNER,
  ROLE_TESTER
} from "../../../constants/roles";
import RiskChart from "../risk-chart/RiskChart";
import StatusBlock from "../../common/StatusBlock";
import { countriesRequest } from "../../../actions/countries";
import { ACTIVE } from "../../../constants/filter";
import countriesSelector from "../../../selectors/countries";
import { EMPTY_COUNTRY_ID, UK_CODE } from "../../../constants/countries";
import { ICountry } from "../../../models/dialing-code.interface";
import TestDetailsTesterCard from "./TestDetailsTesterCard";
import AdminBackToTest from "./button/AdminBackToTest";
import { ITest } from "../../../models/test.interface";
import { ILocation } from "../../../interfaces/location.interface";
import { IWithRouter } from "../../../interfaces/with-router.interface";
import AdditionalServiceEdit from "./AdditionalServiceEdit";

interface ITestDetailsProps {
  test: ITest;
  countries: ICountry[];
  locationTest?: ILocation;
}

type TestDetailsProps = ITestDetailsProps & IWithRouter;

class TestDetails extends React.Component<TestDetailsProps> {
  baseUrl = this.props.match.path;

  isNeedToShowBookingProposalEditBlock(): boolean {
    const { test } = this.props;

    return (
      test.status === TestStatus.STATUS_BOOKING_REQUESTED ||
      test.status === TestStatus.STATUS_BOOKING_REQUESTED_EDITED
    );
  }

  isNeedToShowBookingProposalViewBlock(): boolean {
    const { test } = this.props;

    return DisplayBookingProposalStatuses.indexOf(test.status) !== -1;
  }

  getUk() {
    const { countries } = this.props;

    return countries.find((country: ICountry) => country.code === UK_CODE);
  }

  render() {
    const { test, locationTest } = this.props;
    if (!test) {
      return <div className="table-no-items">Loading...</div>;
    }

    const countryUk = this.getUk();
    const item = test.tester;
    const isQuestionBlockVisible =
      test.status && TestStatusesForQuestionsBlock.indexOf(test.status) !== -1;
    const isBookedStatusVisible =
      test.status && AfterBookedStatuses.indexOf(test.status) !== -1;

    const isAdminValidate = test.status === TestStatus.STATUS_AVORD_VALIDATION;

    return (
      <>
        <Grid container spacing={16}>
          <Grid item xs={12} lg={8}>
            <h4 className="main-content-header">
              {test.name} Details
              <span className="status-label ml-3">
                {test.status && <StatusBlock status={test.status} />}
              </span>
            </h4>
          </Grid>
          <Grid item xs={12} lg={4}>
            <AdminBackToTest
              showValidateButton={isAdminValidate}
              testId={test.id}
            />
            <TestDetailButtons test={test} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TestInfo test={test} locationTest={locationTest} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <PermissionBlock roles={[ROLE_PLANNER, ROLE_ADMIN]}>
              <Grid item xs={12}>
                {item && (
                  <TestDetailsTesterCard
                    key={item.id}
                    item={item}
                    test={test}
                    countryUk={countryUk ? countryUk.id : EMPTY_COUNTRY_ID}
                  />
                )}
              </Grid>
            </PermissionBlock>

            <Grid item xs={12}>
              <DocumentsBlock test={test} />
            </Grid>

            <PermissionBlock roles={[ROLE_PLANNER, ROLE_ADMIN, ROLE_TESTER]}>
              <Grid item xs={12}>
                <RiskChart />
              </Grid>
            </PermissionBlock>

            {isQuestionBlockVisible && (
              <Grid item xs={12}>
                <QuestionsBlock />
              </Grid>
            )}

            {isBookedStatusVisible && (
              <Grid item xs={12}>
                <MessagesBlock />
              </Grid>
            )}
          </Grid>
          <Grid item lg={12}>
            <PermissionBlock roles={[ROLE_TESTER]}>
              {this.isNeedToShowBookingProposalEditBlock() ? (
                <BookingProposalEdit {...this.props} />
              ) : (
                <BookingProposal {...this.props} />
              )}
            </PermissionBlock>
            <PermissionBlock roles={[ROLE_PLANNER, ROLE_ADMIN]}>
              {this.isNeedToShowBookingProposalViewBlock() && (
                <BookingProposal {...this.props} />
              )}
            </PermissionBlock>
            {isAdminValidate && (
              <PermissionBlock roles={[ROLE_ADMIN]}>
                <AdditionalServiceEdit {...this.props} />
              </PermissionBlock>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

const fetched = dataFetcher(TestDetails, [
  {
    key: "test",
    selector: (state, props) => {
      return selector.getItemById(result(props, "match.params.test", false))(
        state
      );
    },
    action: props =>
      testRequest.getItem(result(props, "match.params.test", false)),
    alwaysReceiveFreshData: true
  },
  {
    key: "countries",
    action: () => countriesRequest.getItems(1, 1000, ACTIVE),
    selector: state => countriesSelector.getItemsObject(state),
    alwaysReceiveFreshData: true
  }
]);

export default withRouter(fetched as any);
