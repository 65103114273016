import slidesReducer from "./slide";
import modals from "./modal";
import linkedin from "./linkedin";
import loading from "./loading";
import information from "./information";
import drawer from "./drawer";
import auth, { IPermissionsState } from "./auth";
import anchorEl from "./account-menu";
import faq from "./faq";
import emailTemplates from "./email-templates";
import specialismsWithoutReportTemplates from "./specialisms-without-report-templates";
import { reducer as reduxForm } from "redux-form";
import contentManagement from "./content-management";
import countries from "./countries";
import carousel from "./carousel";
import sector from "./sector";
import risks from "./risks";
import specialisms from "./specialisms";
import finances from "./finances";
import testers from "./testers";
import testerProfile from "./tester-profile";
import certificate from "./certificate";
import insurance from "./insurance";
import dbsCertificate from "./dbs-certificate";
import workHistory from "./work-history";
import locations from "./locations";
import users from "./users";
import project from "./project";
import projectsForScheduling from "./projects-for-scheduling";
import test from "./test";
import formSearchFields from "./form-search-fields";
import testerReviews from "./tester-reviews";
import calendar from "./calendar";
import { currentUser } from "./users";
import adminTesterProfile from "./admin-tester-profile";
import dashboard from "./dashboard";
import question from "./question";
import message from "./message";
import invoice from "./invoice";
import testDocument from "./test-document";
import reports from "./reports";
import reportDocuments from "./report-documents";
import document from "./document";
import companyDetails from "./company-details";
import reassignUsers from "./reassign-users";
import testAndProjects from "./test-and-projects";
import testReports from "./test-reports";
import clientBookingRequests from "./client-booking-requests";
import testerBookingRequests from "./tester-booking-requests";
import testerBookings from "./tester-bookings";
import { IAnyProps } from "interfaces/any-props.interface";
import * as Types from "../constants/action-type";
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import resetPasswordConfirmation from "./reset-password-confirmation";
import applicationSettings from "./application-settings";
import testerRightToWorkCountries from "./tester-rights-to-work-countries";
import certificateType from "./certificate-type";
import favoriteTesters from "./favorite-testers";
import groups from "./groups";
import groupsSearch from "./groupsSearch";
import testerExternalCompanies from "./tester-external-companies";
import inviteToCompanyConfirmation from "./invite-to-company-confirmation";
import testingCompanies from "./testing-companies";
import additionalServices from "./additonalServices";
import auditLog from "./audit-log";
import emailDomains from "./email-domains";
import ipRanges from "./ip-ranges";
import consultants from "./consultants";
import timeExpenses from "./time-expenses";
import timesheetTimeCategories from "./timesheet-time-categories";
import timesheetExpensesTypes from "./timesheet-expenses-types";

export interface IGlobalState extends IAnyProps {
  auth: IPermissionsState;
}

const reducers = {
  slides: slidesReducer,
  specialismsWithoutReportTemplates,
  modals,
  linkedin,
  loading,
  information,
  drawer,
  auth,
  anchorEl,
  form: reduxForm,
  faq,
  emailTemplates,
  contentManagement,
  countries,
  carousel,
  sector,
  specialisms,
  finances,
  testerProfile,
  testers,
  certificate,
  dbsCertificate,
  insurance,
  workHistory,
  locations,
  users,
  project,
  test,
  formSearchFields,
  testerReviews,
  calendar,
  currentUser,
  adminTesterProfile,
  dashboard,
  question,
  message,
  invoice,
  reports,
  testDocument,
  reportDocuments,
  document,
  companyDetails,
  risks,
  reassignUsers,
  testAndProjects,
  testReports,
  clientBookingRequests,
  testerBookingRequests,
  testerBookings,
  projectsForScheduling,
  resetPasswordConfirmation,
  applicationSettings,
  testerRightToWorkCountries,
  certificateType,
  favoriteTesters,
  groups,
  groupsSearch,
  testerExternalCompanies,
  inviteToCompanyConfirmation,
  testingCompanies,
  additionalServices,
  auditLog,
  emailDomains,
  ipRanges,
  consultants,
  timeExpenses,
  timesheetTimeCategories,
  timesheetExpensesTypes
};

const rootReducer = combineReducers({
  ...reducers,
  router: routerReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default (state, action) => {
  if (action.type === Types.CLEAR_STORE) {
    const { router } = state;
    state = { router };
  }
  return rootReducer(state, action);
};
