import React from "react";
import TimeSheetsAndExpenses from "../../../components/timesheets-and-expenses/TimeSheetsAndExpenses";

const Dashboard = () => {
  return (
    <div className="full-height">
      <TimeSheetsAndExpenses />
    </div>
  );
};

export default Dashboard;
