import * as React from "react";
import Modal from "@material-ui/core/Modal/Modal";
import {withStyles} from "@material-ui/core/styles/index";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";

export interface IBackRoute {
  backRoute: string;
}

interface IClassesProps {
  classes: { paper: string };
}

export default (ChildComponent) => {
  class RouterModal extends React.Component<IBackRoute & IClassesProps & RouteComponentProps<{}>, {}> {
    onClose = () => {
      this.props.history.push(this.props.backRoute);
    };

    render() {
      return (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={true}
          onClose={this.onClose}
          className="d-flex overflow-auto"
        >
          <div className="styled-modal md">
            <ChildComponent {...this.props}/>
          </div>
        </Modal>
      );
    }
  }

  return withRouter(withStyles({})(RouterModal));
}
