import {WithStyles} from "@material-ui/core/styles/withStyles";
import * as React from "react";
import {InjectedFormProps, reduxForm} from "redux-form";
import {defaultStyleWidth} from "../../../styles/material/popup-style";
import {composeValidators, fieldsNotEmpty} from "../../../helpers/validations/validation";
import {RouteComponentProps, withRouter} from "react-router-dom";
import routerModal from "../../../components/routerModal";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {postTestDocumentAction} from "../../../actions/test";
import DocumentForm from "./DocumentForm";


const FORM_NAME = 'DocumentCreate';

class DocumentCreate extends React.Component<InjectedFormProps<{}, {}> & RouteComponentProps<any> & {
  postData: (...any) => void,
  backRoute: string,
  testId: number,
} & WithStyles<any>> {
  render() {
    const {handleSubmit, postData, backRoute} = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          postData(values, FORM_NAME, backRoute, this.props.testId);
        })}
      >
        <DocumentForm backRoute={backRoute}/>
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['testDocuments']),
  )
})(DocumentCreate);

const connected = connect(undefined, {
  postData: postTestDocumentAction,
})(formConnected);

const withStyle = withStyles(defaultStyleWidth(400))(connected as any);

export default routerModal(withRouter(withStyle as any)) as any;
