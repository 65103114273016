import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import * as _ from 'lodash';
import {IAnyProps} from "../interfaces/any-props.interface";
import {IReducerItem} from "../interfaces/reducer-item.interface";
import {isChangesExist} from "../helpers/props-checker";
import {getAuthState} from "../selectors/auth";

export interface IParameterInquiry {
  selector: (state, props) => any;
  action: (props?: any) => any;
  key: string;
  alwaysReceiveFreshData?: boolean;
}

type typeDataFetcher = <T>(
  ChildComponent: React.ComponentClass,
  initialValues: IAnyProps,
  ...inquiries: IParameterInquiry[][]
) => any;

const dataFetcherRelated: typeDataFetcher = (
  ChildComponent: React.ComponentClass,
  initialValues: IAnyProps,
  ...inquiries: IParameterInquiry[][]
) => {
  const currentInquiry = inquiries.shift();

  if (!currentInquiry) {
    return (props) => <ChildComponent {...props} />;
  }

  const keys = currentInquiry.map(inquiry => inquiry.key);

  class ComposedComponent extends React.Component<{values: IAnyProps} & DispatchProp & IAnyProps, {}> {
    notInitialized: (key: string) => boolean = (key: string) => {
      if (!this.isNeedToUpdateData(key)) {
        return true
      }

      /** Todo: Could extend in case of false */
      const item = this.props.values[key];

      /** Try to test shape of data */
      if (!!item && typeof item.error !== 'undefined' && typeof item.loaded !== 'undefined') {
        return !item.loaded;
      }

      /** Try to test shape of data */
      if (!!item && typeof item.items !== 'undefined' && typeof item.loader !== 'undefined') {
        return !item.loader.isLoaded;
      }

      if (_.isArray(item)) {
        return !this.props.values[key].length;
      }

      return !this.props.values[key];
    };

    isNeedToUpdateData: (key: string) => boolean = (key: string) => {
      return !_.find(currentInquiry, {'key': key, 'alwaysReceiveFreshData': true});
    };

    existsErrors: (key: string) => boolean = (key: string) => {
      const item = this.props.values[key];

      /** Try to test shape of data */
      if (!!item && typeof item.error !== 'undefined') {
        return item.error;
      }

      return false;
    };

    componentDidMount() {
      /** Check */
      keys
        .filter(this.notInitialized)
        .forEach(key => {
          const a = (_.find(currentInquiry, {key}) as IParameterInquiry).action;
          this.resetFreshDataParam();
          this.props.dispatch(a({...this.props, ...initialValues}));
        });
    }

    shouldComponentUpdate(nextProps, nextState): boolean {
      return isChangesExist(['values', 'match', 'shouldDataFetcherUpdateProps',], nextProps, this.props);
    }


    render() {
      if (keys.some(this.existsErrors)) {
        return <div>Error occurred on while data had been fetching</div>;
      }

      if (!!keys.filter(this.notInitialized).filter(this.isNeedToUpdateData).length) {
        return <div>Loading...</div>;
      }

      this.resetFreshDataParam();

      const values = keys.reduce((acc, key) => {
        const item: IReducerItem<any> | any= this.props.values[key];

        /** Try to test shape of data */
        if (typeof item !== 'undefined'
          && typeof item.error !== 'undefined'
          && typeof item.loaded !== 'undefined'
          && typeof item.item !== 'undefined') {
          return {
            ...acc,
            [key]: item.item,
          };
        }

        /** Try to test shape of data */
        if (typeof item !== 'undefined' && typeof item.items !== 'undefined' && typeof item.loader !== 'undefined') {
          return {
            ...acc,
            [key]: item.items,
          };
        }

        return {...acc, [key]: item};
      }, {} as IAnyProps);

      if (inquiries.length) {
        const ResultChildComponent = dataFetcherRelated(ChildComponent, {...initialValues, ...values}, ...inquiries);

        return (<ResultChildComponent {...{...initialValues, ...values}} {...this.props} />);
      }

      return (<ChildComponent {...{...initialValues, ...values}} {...this.props} />);
    }

    private resetFreshDataParam(): void {
      keys.forEach((key) => {
        const item = _.find(currentInquiry, {'key': key,'alwaysReceiveFreshData': false});
        if (item) {
          item.alwaysReceiveFreshData = true;
        }
      });
    };
  }

  function mapStateToProps(state, props): IAnyProps {
    if (!currentInquiry) {
      return (props) => <ChildComponent {...props} />;
    }

    return currentInquiry.reduce((acc: IAnyProps & { values: IAnyProps}, value:  IParameterInquiry) => {
      return {
        values: {
          ...acc.values,
          [value.key]: value.selector(state, props),
        },
        auth: getAuthState(state),
      };
    }, {values: initialValues});
  }

  return connect(mapStateToProps)(ComposedComponent);
};

export {dataFetcherRelated};
