import { Field, formValueSelector } from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import React from "react";
import { handleKeyPressOnNumberField } from "../../../../helpers/validations/numberFieldValidator";
import ReduxSelectField from "../../../../components/forms/ReduxSelectField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { FieldGeneric } from "../../../../components/forms/FieldGeneric";
import projectsSelector from "../../../../selectors/project";
import { connect } from "react-redux";
import { SelectProjectType } from "../../../../types/consultant";
import { WrappedFieldArrayProps } from "redux-form/lib/FieldArray";
import { CONSULTANT_FORM_NAME } from "../../../../constants/consultant";
import {
  getRatePercent,
  selectOptionsSort
} from "../../../../helpers/consultant-create-form";
import ReduxSelectSearchField from "../../../../components/forms/ReduxSelectSearchField";
import { SelectOptionType } from "../../../../types";

interface ProjectsRepeaterItem {
  project: number;
  projectRate: number;
}
interface ProjectsRepeaterProps
  extends WrappedFieldArrayProps<ProjectsRepeaterItem> {
  allProjects?: SelectOptionType[];
  consultantRate?: number;
}
const searchFormSelector = formValueSelector(CONSULTANT_FORM_NAME);

const ProjectsRepeater = (props: ProjectsRepeaterProps) => {
  const { fields, allProjects = [], consultantRate = 0 } = props;

  const rows = fields.map((name, index) => {
    const projectRate = fields.get(index).projectRate;
    const projectPercent = getRatePercent(consultantRate, projectRate);

    return (
      <div key={index} className="project-repeater__row mb-2">
        <div className="row align-items-center">
          <div className="col-12 col-md-1 pt-3 repeater-remove-button-wrap">
            <button
              className="btn-remove"
              type="button"
              onClick={() => fields.remove(index)}
            />
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5 project-repeater__project">
            <FieldGeneric
              name={`${name}.project`}
              label="Project"
              fullWidth
              component={ReduxSelectSearchField}
              items={allProjects}
              renderItem={(option: SelectOptionType) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              )}
            />
          </div>
          <div className="col-7 col-md-4 col-lg-4">
            <Field
              name={`${name}.projectRate`}
              component={ReduxTextField}
              margin="none"
              normalize={(value: string) => (value ? +value : "")}
              label="£ Client Day Rate *"
              onKeyPress={handleKeyPressOnNumberField}
              inputProps={{ min: 0 }}
              type="number"
            />
          </div>
          {projectPercent !== null && (
            <div className="col-5 col-md-2 col-lg-2">
              <div className="form-text-wrap project-repeater__percent">{`${projectPercent} %`}</div>
            </div>
          )}
        </div>
      </div>
    );
  });

  return <>{rows}</>;
};

function mapStateToProps(state) {
  const allProjects: SelectOptionType[] = projectsSelector
    .getItems(state)
    .map((item: SelectProjectType) => ({
      id: item.id,
      name: `${item.companyName} - ${item.name}`
    }))
    .sort(selectOptionsSort);
  return {
    allProjects,
    consultantRate: searchFormSelector(state, "consultantRate")
  };
}
export default connect(mapStateToProps)(ProjectsRepeater);
