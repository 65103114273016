import * as React from 'react';
import {connect} from 'react-redux';
import {change} from 'redux-form';
import * as _ from 'lodash';
import {IAnyProps} from "../interfaces/any-props.interface";
import {toPairsIn} from 'lodash';

interface IComponentStateProps {
  initialValues?: IAnyProps,
  initialised: boolean,
}

interface IComponentDispatchProps {
  dataFetcher: (any: any) => void,
  change: (...any) => void,
}

export default (СhildComponent, formName: string, dataFetcher: (...any) => any, fetchFromStoreById: (...any) => any) => {
  class ComposedComponent extends React.Component<IComponentStateProps & IComponentDispatchProps, {}> {
    fetchItem() {
      this.props.dataFetcher(getIdParameter(this.props));
    }

    componentDidMount() {
      if (!this.props.initialised) {
        this.fetchItem();
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const idParameterPrev = getIdParameter(prevProps);
      const idParameterCurrent = getIdParameter(this.props);

      if (idParameterPrev !== idParameterCurrent) {
        toPairsIn(this.props.initialValues)
          .forEach(([key, value]) => {
            this.props.change(formName, key, value);
          })
      }
    }

    render() {
      if (!this.props.initialised) {
        return <div>Loading</div>;
      }

      return (<СhildComponent {...this.props} />);
    }
  }

  const getInitialValues: (...any) => IComponentStateProps  = (state, props) => {
    const id = getIdParameter(props);

    if (id === false) {
      return {initialised: false};
    }

    const item = fetchFromStoreById(id)(state);

    if (!item) {
      return {initialised: false};
    }

    return {
      initialValues: {
        ...item,
      },
      initialised: true,
    };
  };

  const getIdParameter = (props) => {
    return _.result(props, 'match.params.id', false);
  };

  function mapStateToProps(state, props): IComponentStateProps {
    return getInitialValues(state, props);
  }

  return connect(mapStateToProps, {
    dataFetcher,
    change
  })(ComposedComponent);
};
