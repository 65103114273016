import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { downloadDocumentFile } from "../../../../api/requests/document-download";
import { connect } from "react-redux";

type DownloadRepeaterButton = {
  id?: number;
  name: string;
  downloadDocumentFile: (id: number) => void;
};

const DownloadRepeaterButton = ({
  id,
  name,
  downloadDocumentFile
}: DownloadRepeaterButton) => {
  if (!id && !name) {
    return null;
  }
  if (id) {
    return (
      <div className="document-repeater__download">
        <button
          className="btn-download"
          type="button"
          onClick={() => downloadDocumentFile(id)}
        >
          {name}
        </button>
      </div>
    );
  }

  return (
    <div className="document-repeater__download">
      <span>{name}</span>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      downloadDocumentFile: downloadDocumentFile
    },
    dispatch
  );
};
const connected = connect(null, mapDispatchToProps)(DownloadRepeaterButton);

export default connected;
