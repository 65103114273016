import * as Types from '../constants/action-type';
import {
  createLoaderAction,
  createCrudAction,
  createRequestAction
} from '../helpers/action-helper';
import {setRequestInProcess} from "./request";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {putItem, getItems, postItem} from "../api/requests/risks";
import {getRequestFromType} from "../api/requests/request-factory";
import {getRequestName} from "../helpers/action-request-helper";
import {openSnackbar} from "./snackbar";
import {push} from "react-router-redux";
import {reset} from "redux-form";

export const risksLoader = createLoaderAction(Types.RISKS);
export const risksRequest = createRequestAction(Types.RISKS);


const crudType = Types.RISKS;

export const deleteRisksAction = riskId => dispatch => async () =>{
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'deleteItem', '' + riskId)));

  try {
    await (getRequestFromType(crudType) as any).deleteItem(riskId);
    dispatch(createCrudAction(crudType).removeItem(riskId));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(crudType, 'deleteItem', '' + riskId)));
};

export const putItemAction = (id, model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'putItem')));

  let data;

  try {
    data = await putItem(id, model);

    dispatch(openSnackbar('Updated', 4000));
    dispatch(createCrudAction(crudType).replaceItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(reset(formName));
  dispatch(setRequestInProcess(false, getRequestName(crudType, 'putItem')));
  dispatch(push(backRoute));
};

export const postItemAction = (model, formName, backRoute) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, 'postItem')));

  let data;

  try {
    data = await postItem(model);

    dispatch(openSnackbar('Created', 4000));
    dispatch(createCrudAction(crudType).addItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(reset(formName));
  dispatch(setRequestInProcess(false, getRequestName(crudType, 'postItem')));
  dispatch(push(backRoute));
};