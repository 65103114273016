import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import {
  usersRequest,
  usersLoader,
  getUsersFiltered,
  archiveItem,
  unarchiveItem,
  getUser
} from "../../../../actions/users";
import selector from "../../../../selectors/users";
import { TablePagination } from "@material-ui/core";
import EnhancedTableHead from "../../../../components/table/EnhancedTableHead";
import { isChangesExist } from "../../../../helpers/props-checker";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import { IUsers } from "../../../../models/users.interface";
import debounce from "debounce";
import { postEmail } from "../../../../actions/common";
import EnhancedTableRow from "../../../../components/table/EnhancedTableRow";
import { FORM_NAME } from "./AdminUsersSearchForm";
import { formValueSelector } from "redux-form";

const selectorSearchForm = formValueSelector(FORM_NAME);

interface IFilterValues {
  name: string;
}

type IProps = {
  baseUrl: string;
  [key: string]: any;
} & IFilterValues;

class AdminUsersList extends React.Component<IProps, {}> {
  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  handleSendLink = e => {
    e.preventDefault();
    this.props.postEmail(e.target.dataset.email);
  };

  loadItems = (props: IProps) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.text,
      "",
      "",
      "Admin"
    );
  };

  onUnarchive = item => () => {
    this.props.unarchiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus
    );
  };

  onArchive = item => () => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        ["pageNumber", "itemsPerPage", "showStatus", "text"],
        nextProps,
        this.props
      )
    ) {
      if (isChangesExist(["text"], nextProps, this.props)) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  loadItem = props => {
    props.loadItem(props.text);
  };

  render() {
    if (!this.props.isLoaded) {
      return <div>Loading...</div>;
    }

    if (!this.props.data.length) {
      return <div>There are no items!</div>;
    }

    return (
      <Paper style={{ overflowX: "auto", width: "100%" }} className="mb-4">
        <Table>
          <EnhancedTableHead
            columns={["Name", "Email", "Reset password"]}
            status={this.props.showStatus}
          />
          <TableBody>
            {this.props.data.map((item: IUsers) => {
              return (
                <EnhancedTableRow
                  key={item.id}
                  item={item}
                  baseUrl={this.props.baseUrl}
                  showStatus={this.props.showStatus}
                  onArchive={this.onArchive(item)}
                  onUnarchive={this.onUnarchive(item)}
                  needActions
                >
                  {item.firstName + " " + item.lastName}
                  {item.email}
                  {!item.deletedAt && (
                    <a
                      href={item.email}
                      data-email={item.email}
                      onClick={this.handleSendLink}
                    >
                      Reset&nbsp;password
                    </a>
                  )}
                </EnhancedTableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={this.props.totalItems}
          rowsPerPage={this.props.itemsPerPage}
          page={this.props.pageNumber - 1}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps & { baseUrl: string }>(
  state => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    text: selectorSearchForm(state, "text") || ""
  }),
  {
    postEmail,
    loadItem: usersRequest.getItem,
    loadItems: getUsersFiltered,
    setItemsPerPage: usersLoader.setItemsPerPage,
    setPageNumber: usersLoader.setPageNumber,
    archiveItem: archiveItem("Admin"),
    unarchiveItem: unarchiveItem("Admin")
  }
)(AdminUsersList);
