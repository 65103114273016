export const STATUS_NEW = 4;
export const STATUS_IN_PROGRESS = 3;
export const STATUS_VALIDATION_PASSED = 1;
export const STATUS_RE_VALIDATE_REQUIRED = 2;
export const STATUS_VALIDATION_FAILED = 5;
export const STATUS_NO_BDS = 6;

export const getStatusDescription = (status) => {
  let dbsStatus;

  DBS_ALL_STATUSES.forEach(item => {
    dbsStatus = item.value === status ? item : dbsStatus;
  });

  return dbsStatus ? dbsStatus : null;
};

export const DBS_EDIT_STATUSES = [
  {
    value: STATUS_IN_PROGRESS,
    name: "In progress",
    color: "#6faae2"
  },
  {
    value: STATUS_VALIDATION_PASSED,
    name: "Validation passed",
    color: "#2b895c"
  },
  {
    value: STATUS_RE_VALIDATE_REQUIRED,
    name: "Re-validation required",
    color: "#8a3f43"
  },
  {
    value: STATUS_VALIDATION_FAILED,
    name: "Validation failed",
    color: "#886869"
  }
];

export const DBS_VISIBLE_STATUSES = DBS_EDIT_STATUSES.concat([
  {
    value: STATUS_NEW,
    name: "New",
    color: "#f8a451"
  }
]);

export const DBS_ALL_STATUSES = DBS_VISIBLE_STATUSES.concat([
  {
    value: STATUS_NO_BDS,
    name: "Validation required",
    color: "#cc8687"
  }
]);

