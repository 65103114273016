import {Observable} from 'rxjs';
import {ActionsObservable} from 'redux-observable';
import * as actionType from '../constants/action-type';
import {flatMap, map, switchMap} from 'rxjs/operators';
import {postDocument} from '../api/requests/document';
import {fromPromise} from "rxjs/internal-compatibility";
import {IActionPayload} from "./index";
import {startEndCatch} from "./pipes/errors";
import {createAction} from "redux-actions";
import {createLoaderAction} from "../helpers/action-helper";
import selector from "../selectors/document";
import {documentSearchRequest} from "../api/requests/document";
import {deleteItem} from "../api/requests/document";
import {createLoaderActionItem} from "../interfaces/reducer-item.interface";
import {of} from "rxjs/internal/observable/of";
import {setRequestInProcess} from '../actions';

export const postDocumentEpic = (action$: ActionsObservable<IActionPayload<File>>): Observable<any> => {
  return action$
    .ofType(actionType.DOCUMENT_POST)
    .pipe(
      switchMap((action: IActionPayload<File>)=> {
        return fromPromise(postDocument(action.payload))
          .pipe(
            map((data) => {
              return createAction(actionType.DOCUMENT_POST_COMPLETED, () => data)();
            }),
            ...startEndCatch('postDocumentEpic'),
          );
      }),
    );
};

export const documentsSearchApplyEpic = (action$: ActionsObservable<IActionPayload<any>>, state$): Observable<any> => {
  return action$
    .ofType(actionType.DOCUMENT_SEARCH_APPLY)
    .pipe(
      switchMap((action: IActionPayload<any>) => {

        const pageNumber = selector.getPageNumber(state$.value);
        const itemsPerPage = selector.getItemsPerPage(state$.value);

        return fromPromise(documentSearchRequest(
          action.payload,
          pageNumber,
          itemsPerPage
        ))
          .pipe(
            flatMap(({items, totalItems}) => {
              return [
                createLoaderAction(actionType.TEST_DOCUMENT).setPaginatioData(items, itemsPerPage, totalItems, pageNumber),
              ];
            }),
            ...startEndCatch('documentsSearchApplyEpic'),
          );
      }),
    );
};

export const archiveDocumentEpic = (action$: ActionsObservable<IActionPayload<{testId: number, id: any}>>): Observable<any> => {
  return action$
    .ofType(actionType.DOCUMENT_ARCHIVE)
    .pipe(
      switchMap((action: IActionPayload<{testId: number, id: any}>) => {
        return fromPromise(deleteItem(action.payload.id))
          .pipe(
            flatMap((data) => {
              return [
                createAction(actionType.DOCUMENT_SEARCH_APPLY, () => action.payload.testId)(),
              ];
            }),
            ...startEndCatch('archiveDocumentEpic'),
          );
      }),
    );
};
