export enum TE_STATUS {
  STATUS_DEFAULT = 0,
  STATUS_DRAFT = 1,
  STATUS_SUBMITTED = 2,
  STATUS_APPROVED = 3,
  STATUS_REJECTED = 4,
  STATUS_INVOICED = 5
}

export enum TE_STATUS_NAMES {
  STATUS_DEFAULT = "",
  STATUS_DRAFT = "Draft",
  STATUS_SUBMITTED = "Submitted",
  STATUS_APPROVED = "Approved",
  STATUS_REJECTED = "Rejected",
  STATUS_INVOICED = "Invoiced"
}

export const TE_STATUS_ARRAY = [
  {
    id: TE_STATUS.STATUS_DEFAULT,
    name: TE_STATUS_NAMES.STATUS_DEFAULT
  },
  {
    id: TE_STATUS.STATUS_DRAFT,
    name: TE_STATUS_NAMES.STATUS_DRAFT
  },
  {
    id: TE_STATUS.STATUS_SUBMITTED,
    name: TE_STATUS_NAMES.STATUS_SUBMITTED
  },
  {
    id: TE_STATUS.STATUS_APPROVED,
    name: TE_STATUS_NAMES.STATUS_APPROVED
  },
  {
    id: TE_STATUS.STATUS_REJECTED,
    name: TE_STATUS_NAMES.STATUS_REJECTED
  },
  {
    id: TE_STATUS.STATUS_INVOICED,
    name: TE_STATUS_NAMES.STATUS_INVOICED
  }
];

export const TIME_SHEETS_STATUSES = [
  {
    id: TE_STATUS.STATUS_APPROVED,
    name: TE_STATUS_NAMES.STATUS_APPROVED
  },
  {
    id: TE_STATUS.STATUS_REJECTED,
    name: TE_STATUS_NAMES.STATUS_REJECTED
  },
  {
    id: TE_STATUS.STATUS_INVOICED,
    name: TE_STATUS_NAMES.STATUS_INVOICED
  },
  {
    id: TE_STATUS.STATUS_SUBMITTED,
    name: TE_STATUS_NAMES.STATUS_SUBMITTED
  }
];

export const TIME_SHEETS_SEARCH_FORM = "TimeSheetsAndExpensesSearchForm";
export const TIME_SHEETS_SEARCH_FORM_BY_DATE =
  "TimeSheetsAndExpensesSearchFormByDate";
export const TIMESHEET_TIME_CATEGORIES_FORM_NAME = "TimeCategoriesSearchForm";
export const TIMESHEET_EXPENSES_TYPES_FORM_NAME = "ExpensesTypesSearchForm";
export const TIMESHEET_TIME_CATEGORIES_FORM_CREATE = "TimeCategoriesFormCreate";
export const TIMESHEET_EXPENSES_TYPES_FORM_CREATE = "ExpensesTypesFormCreate";

export const VALIDATION_ERROR_MESSAGES = {
  REQUIRED: "Required",
  REQUIRED_SECTION_COMPETED: "Input Time or/and Expenses amount",
  REQUIRED_RECEIPT:
    "The completed Expenses section must have at least one uploaded receipt.",
  UPLOAD_FILE: "Upload a file",
  LARGE_FILE_SIZE_MESSAGE:
    "One of the uploaded files is larger than the available size (5MB)",
  LARGE_FILE_SIZE: "Large file size"
};

export const PO_NUMBER_TYPE = {
  PURCHASE: "purchaseOrderNumber",
  EXPENSES: "expensesPurchaseOrderNumber"
};
