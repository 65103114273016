import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {getIsLoadedPermissions, getRoles, getUserGroup, getUserPermissions} from '../../selectors/auth';
import {Route} from 'react-router-dom'
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbar';
import {push} from "react-router-redux";
import {Permission} from "../../constants/permission";
import {difference} from 'lodash';
import * as Storage from "../../helpers/storage-helper";
import {REFRESH_TOKEN_KEY} from "../../constants/storage";

interface IStateProps {
  currentRoles: string[];
  isLoadedPermissions: boolean;
  userPermissions: Permission[];
  userGroup: string;
}

interface IDispatchProps {
  showNotice: (msg: string, time: number) => void;
  redirect: (url: string) => void;
}

interface IOwnProps {
  component: any;
  role?: string;
  roles?: string[];
  redirectPath?: string;
  permissions?: Permission[];
  group?: string;
  [key: string]: any;
}

class PrivateRoute extends React.Component<IOwnProps & IStateProps & IDispatchProps, {}> {
  renderRoute = (properties) => {
    const {component: Component, redirectPath} = this.props;

    return (
      this.isAllowed
        ? <Component {...properties} />
        : <Redirect to={{
          pathname: redirectPath,
          state: {from: properties.location}
        }}/>
    );
  };

  get isAllowed() {
    if (this.props.permissions && difference(this.props.permissions, this.props.userPermissions).length) {
      return false;
    }

    if (this.props.group && this.props.group !== this.props.userGroup) {
      return false;
    }

    if (this.props.roles || this.props.role) {
      const rolesMerged = [this.props.role, ...(this.props.roles || [])].filter(a => a);
      const isAllowed = rolesMerged.some((roleSeek: string) => this.props.currentRoles.indexOf(roleSeek) !== -1);

      return isAllowed;
    }

    return true;
  }

  render() {
    const {...rest} = this.props;

    if (!this.props.isLoadedPermissions) {
      return (
        <div className="page-content">
          <div className="container">
            Loading...
          </div>
        </div>
      );
    }


    /** Show information popup */
    if (!this.isAllowed) {
      const path = this.props.redirectPath ? this.props.redirectPath : '/';

      let noticeMessage:string = 'Sorry, you don\'t have permissions to do this action';

      if (!Storage.getValue(REFRESH_TOKEN_KEY)) {
        noticeMessage = 'Session expired';
      }

      this.props.showNotice(noticeMessage, 6000);
      this.props.redirect(path);

      return <Redirect to={{
        pathname: path,
      }}/>;
    }

    return (
      <Route {...rest} />
    );
  };
}

function mapStateToProps(state): IStateProps {
  return {
    currentRoles: getRoles(state),
    isLoadedPermissions: getIsLoadedPermissions(state),
    userPermissions: getUserPermissions(state),
    userGroup: getUserGroup(state),
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, {
  showNotice: openSnackbar,
  redirect: push,
})(PrivateRoute);
