import * as React from "react";
import { Modal } from "@material-ui/core";

class AreYouSureModal extends React.Component<any & any> {
  onClickHandler = () => {
    this.props.onConfirm(this.props.id);
    this.props.onClose();
  };
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.props.onClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>Are you sure?</h4>
          <hr />
          <div className="text-right">
            <button className="btn-cancel" onClick={this.props.onClose}>
              No
            </button>
            <button
              type="submit"
              className="btn-save"
              onClick={this.onClickHandler}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AreYouSureModal;
