import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import {
  usersRequest,
  usersLoader,
  getUsersFiltered,
  archiveItem,
  unarchiveItem,
  deleteItem,
  changeTesterRoleAction,
  resetAction2FA
} from "../../../actions/users";
import selector from "../../../selectors/users";
import { TableCell, TablePagination, TableRow } from "@material-ui/core";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import { isChangesExist } from "../../../helpers/props-checker";
import { IAnyProps } from "../../../interfaces/any-props.interface";
import { IUsers } from "../../../models/users.interface";
import debounce from "debounce";
import { Link } from "react-router-dom";
import { postEmail } from "../../../actions";
import TableCellArchive from "../../../components/table/TableCellArchive";
import { formValueSelector } from "redux-form";
import { FORM_NAME } from "./TestersSearchForm";
import { formatDateWithTime } from "../../../helpers/date-formatter";
import { getStatusDescription } from "../../../constants/dbs-certificate-statuses";
import TableCellDelete from "../../../components/table/TableCellDelete";
import { Switch } from "@material-ui/core";
import AssignCompanyManagerModal from "./AssignCompanyManagerModal";
import AreYouSureModal from "./AreYouSureModal";
import { ACTIVE } from "../../../constants/filter";
import { testingCompaniesRequest } from "../../../actions/testing-companies";
import Reset2FAConfirmModal from "./Reset2FAConfirmModal";
import { Group } from "../../../constants/group";
import { TableNoItems } from "../../../components/table/TableNoItems";

const selectorSearchForm = formValueSelector(FORM_NAME);

interface IFilterValues {
  name: string;
  email: string;
  phone: string;
  date: string;
  createdAt: string;
  deletedAt: string;
  userTestingCompanyFilter: string;
  role: string;
}

type IProps = {
  baseUrl: string;
  [key: string]: any;
} & IFilterValues;

class TestersList extends React.Component<IProps, {}> {
  state = {
    isArchive: false,
    assignCompanyManagerModalVisible: false,
    testerProfileId: "",
    userGroup: "",
    isConfirmModalOpen: false,
    userId: "",
    is2FAModalOpen: false
  };

  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  handleSendLink = e => {
    e.preventDefault();
    this.props.postEmail(e.target.dataset.email);
  };

  loadItems = (props: IProps) => {
    props.loadTestingCompanies(1, 100, ACTIVE);
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.name,
      props.email,
      props.phone,
      props.role,
      props.createdAt,
      props.deletedAt,
      props.userDbsFilter,
      props.userTestingCompanyFilter
    );
  };

  onUnarchive = item => () => {
    this.props.unarchiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userDbsFilter,
      this.props.userTestingCompanyFilter
    );
  };

  onArchive = item => () => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userDbsFilter,
      this.props.userTestingCompanyFilter
    );
    this.setOnArchive(true);
  };

  onDelete = item => () => {
    this.props.deleteItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userDbsFilter,
      this.props.userTestingCompanyFilter
    );
  };

  setOnArchive = value => {
    this.setState({ isArchive: value });
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        [
          "pageNumber",
          "itemsPerPage",
          "showStatus",
          "name",
          "email",
          "phone",
          "createdAt",
          "deletedAt",
          "userDbsFilter",
          "userTestingCompanyFilter",
          "role"
        ],
        nextProps,
        this.props
      )
    ) {
      if (
        isChangesExist(
          [
            "name",
            "email",
            "phone",
            "createdAt",
            "deletedAt",
            "userDbsFilter",
            "userTestingCompanyFilter",
            "role"
          ],
          nextProps,
          this.props
        )
      ) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  loadItem = props => {
    props.loadItem(
      props.name,
      props.email,
      props.phone,
      props.createdAt,
      props.deletedAt,
      props.userDbsFilter
    );
  };

  showModal = (testerProfileId, userGroup, isCompanyManager) => () => {
    if (isCompanyManager) {
      this.setState({ isConfirmModalOpen: true, testerProfileId, userGroup });
    } else {
      this.setState({
        assignCompanyManagerModalVisible: true,
        testerProfileId,
        userGroup
      });
    }
  };

  hideAssignCompanyManagerModal = () => {
    this.setState({
      assignCompanyManagerModalVisible: false,
      testerProfileId: "",
      userGroup: ""
    });
  };

  hideConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: false,
      testerProfileId: "",
      userGroup: ""
    });
  };

  showReset2FAModal = e => {
    e.preventDefault();
    this.setState({ is2FAModalOpen: true, userId: e.target.dataset.id });
  };

  hideReset2FAModal = () => {
    this.setState({ is2FAModalOpen: false });
  };

  onConfirm = () => {
    this.props.changeRole(
      this.state.testerProfileId,
      this.state.userGroup,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userDbsFilter,
      this.props.userTestingCompanyFilter
    );
  };

  render() {
    if (!this.props.isLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }

    if (!this.props.data.length) {
      return <TableNoItems hasWrapper />;
    }

    return (
      <div>
        {/* <UsersReassignModal isArchive={this.state.isArchive} setOnArchive={this.setOnArchive} onArchive={this.onArchive}/> */}
        <AssignCompanyManagerModal
          open={this.state.assignCompanyManagerModalVisible}
          onConfirm={this.props.changeRole}
          onClose={this.hideAssignCompanyManagerModal}
          testerProfileId={this.state.testerProfileId}
          userGroup={this.state.userGroup}
          pageNumber={this.props.pageNumber}
          itemsPerPage={this.props.itemsPerPage}
          showStatus={this.props.showStatus}
        />
        <AreYouSureModal
          open={this.state.isConfirmModalOpen}
          onClose={this.hideConfirmModal}
          onConfirm={this.onConfirm}
          id={this.state.testerProfileId}
        />
        <Reset2FAConfirmModal
          open={this.state.is2FAModalOpen}
          onClose={this.hideReset2FAModal}
          onReset={this.props.onReset2FA}
          id={this.state.userId}
        />
        <Paper style={{ overflowX: "auto", width: "100%" }}>
          <Table padding="dense">
            <EnhancedTableHead
              columns={[
                "DBS Status",
                "Name",
                "Email",
                "Telephone",
                "Date registered",
                "Reset 2FA",
                "Reset password",
                "Status",
                "Testing company",
                "Company manager",
                "De/Re activate",
                "Delete"
              ]}
            />
            <TableBody>
              {this.props.data.map((item: IUsers) => {
                const dbsStatus = getStatusDescription(item.dBSCheckMinStatus);
                const dbsCellStyle = dbsStatus
                  ? { color: dbsStatus.color }
                  : {};

                return (
                  <TableRow key={item.id}>
                    <TableCell style={dbsCellStyle}>
                      {!item.deletedAt && (
                        <Link
                          to={"/admin/testers/" + (item as any).testerProfileId}
                        >
                          {dbsStatus ? dbsStatus.name : ""}
                        </Link>
                      )}
                      {item.deletedAt && (dbsStatus ? dbsStatus.name : "")}
                    </TableCell>
                    <TableCell>
                      {!item.deletedAt && (
                        <Link
                          to={"/admin/testers/" + (item as any).testerProfileId}
                        >
                          {item.firstName + " " + item.lastName}
                        </Link>
                      )}
                      {item.deletedAt && item.firstName + " " + item.lastName}
                    </TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell className="text-nowrap">{item.phone}</TableCell>
                    <TableCell className="text-nowrap">
                      {formatDateWithTime(item.createdAt)}
                    </TableCell>
                    <TableCell>
                      {!item.deletedAt && (
                        <a
                          href={item.email}
                          data-id={item.id}
                          onClick={this.showReset2FAModal}
                        >
                          Reset 2FA
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {!item.deletedAt && (
                        <a
                          href={item.email}
                          data-email={item.email}
                          onClick={this.handleSendLink}
                        >
                          Reset&nbsp;password
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {!item.deletedAt ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell>{item.testingCompanyName || ""}</TableCell>
                    <TableCell>
                      {
                        <Switch
                          checked={item.groupName === Group.MANAGER}
                          disabled={!!item.deletedAt}
                          value="true"
                          onChange={this.showModal(
                            item.testerProfileId,
                            item.groupName,
                            item.groupName === Group.MANAGER
                          )}
                        />
                      }
                    </TableCell>
                    <TableCellArchive
                      onArchive={this.onArchive(item)}
                      onUnarchive={this.onUnarchive(item)}
                      item={item}
                    />
                    <TableCellDelete
                      onDelete={this.onDelete(item)}
                      item={item}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{ "aria-label": "Previous Page" }}
            nextIconButtonProps={{ "aria-label": "Next Page" }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps & { baseUrl: string }>(
  state => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    name: selectorSearchForm(state, "name") || "",
    email: selectorSearchForm(state, "email") || "",
    phone: selectorSearchForm(state, "phone") || "",
    createdAt: selectorSearchForm(state, "createdAt") || "",
    deletedAt: selectorSearchForm(state, "deletedAt") || "",
    userDbsFilter: selectorSearchForm(state, "userDbsFilter") || "",
    userTestingCompanyFilter:
      selectorSearchForm(state, "userTestingCompanyFilter") || "",
    role: selectorSearchForm(state, "role") || "TesterAndManager"
  }),
  {
    postEmail,
    loadItem: usersRequest.getItem,
    loadItems: getUsersFiltered,
    setItemsPerPage: usersLoader.setItemsPerPage,
    setPageNumber: usersLoader.setPageNumber,
    archiveItem: archiveItem("TesterAndManager"),
    unarchiveItem: unarchiveItem("TesterAndManager"),
    deleteItem: deleteItem("TesterAndManager"),
    changeRole: changeTesterRoleAction,
    loadTestingCompanies: testingCompaniesRequest.getItems,
    onReset2FA: resetAction2FA
  }
)(TestersList);
