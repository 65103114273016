import React, { useEffect } from "react";
import {
  Link,
  Route,
  Switch,
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import { getCreateLink } from "../../../helpers/router-helper";
import CreateConsultant from "./components/CreateConsultant";
import ConsultantSearchForm from "./components/ConsultantSearchForm";
import ConsultantsList from "./ConsultantsList";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { getAllProjects } from "../../../actions/project";

interface ConsultantsProps extends RouteComponentProps {
  getAllProjects: () => void;
}

const Consultants = (props: ConsultantsProps) => {
  const { getAllProjects } = props;
  const baseUrl = props.match.path;
  const goBackHandler = () => props.history.push(baseUrl);
  const createConsultant = () => <CreateConsultant goBack={goBackHandler} />;
  const updateConsultant = () => <CreateConsultant goBack={goBackHandler} />;

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <div className="pb-4">
      <div className="d-flex justify-content-end mb-4">
        <Link to={getCreateLink(baseUrl)}>
          <button className="btn-new">Add Consultant</button>
        </Link>
      </div>
      <ConsultantSearchForm />
      <ConsultantsList baseUrl={baseUrl} />
      <Switch>
        <Route path={getCreateLink(baseUrl)} render={createConsultant} />
        <Route path={baseUrl + "/:id"} render={updateConsultant} />
      </Switch>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAllProjects: getAllProjects
    },
    dispatch
  );
};

const connected = connect(null, mapDispatchToProps)(Consultants);
export default withRouter(connected);
