import * as React from "react";
import { Route, Switch } from "react-router-dom";
import ClientsList from "./IState";
import ClientDetails from "./ClientDetails";
import ClientsSearchForm from "./ClientsSearchForm";
import { withRouter } from "react-router-dom";
import ClientsUpdate from "./ClientsUpdate";
import ClientDetailsUpdate from "./ClientDetailsUpdate";

class Clients extends React.Component<any, {}> {
  baseUrl = this.props.match.path;

  clientsList = () => (
    <div className="pb-4">
      <ClientsSearchForm />
      <ClientsList baseUrl={this.baseUrl} />
    </div>
  );
  clientDetails = () => <ClientDetails backRoute={this.baseUrl} />;
  updateComponent = () => (
    <>
      {this.clientsList()}
      <ClientsUpdate backRoute={this.baseUrl} />
    </>
  );
  clientDetailsEdit = () => <ClientDetailsUpdate backRoute={this.baseUrl} />;

  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path={this.baseUrl + "/:id"}
            render={this.updateComponent}
          />
          <Route
            exact
            path={this.baseUrl + "/details/:id"}
            render={this.clientDetails}
          />
          <Route
            exact
            path={this.baseUrl + "/details/:id/edit"}
            render={this.clientDetailsEdit}
          />
          <Route path={this.baseUrl} render={this.clientsList} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(Clients);
