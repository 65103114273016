import * as React from "react";
import { ACTIVE, ARCHIVED, ALL } from "../../constants/filter";
import { getEditLink } from "../../helpers/router-helper";
import TableCell from "@material-ui/core/TableCell";
import { TableCellProps } from "@material-ui/core/TableCell/TableCell";
import CustomIconButton from "../CustomIconButton";

interface TableCellEditProps extends TableCellProps {
  status: string;
  item: { [key: string]: any };
  baseUrl: string;
}

class TableCellEdit extends React.Component<TableCellEditProps, {}> {
  render() {
    const { status, item, baseUrl, ...rest } = this.props;
    if (status === ACTIVE) {
      return (
        <TableCell {...rest}>
          <CustomIconButton
            to={getEditLink(baseUrl, item.id)}
            iconName="edit"
          />
        </TableCell>
      );
    } else if (status === ARCHIVED) {
      return null;
    } else if (status === ALL) {
      if (item.deletedAt) {
        return (
          <TableCell {...rest}>
            <div style={{ height: "48px" }} />
          </TableCell>
        );
      }

      return (
        <TableCell {...rest}>
          <CustomIconButton
            to={getEditLink(baseUrl, item.id)}
            iconName="edit"
          />
        </TableCell>
      );
    }

    return null;
  }
}

export default TableCellEdit;
