import * as React from "react";
import RiskChartTable from "./RiskChartTable";
import BubbleChart from "./BubbleChart";
import { Route, Switch, withRouter } from "react-router-dom";
import RiskCreate from "./RiskCreate";
import RiskUpdate from "./RiskUpdate";
import RiskChartHeader from "./RiskChartHeader";
import selector from "selectors/test";
import risksSelector from "selectors/risks";
import { testRequest } from "actions/test";
import { result } from "lodash";
import RiskChartGuidance from "./RiskChartGuidance";
import PermissionBlock from "components/auth/PermissionBlock";
import * as Roles from "constants/roles";
import { Grid } from "@material-ui/core";
import AdminBackToTest from "../test-details/button/AdminBackToTest";
import { connect } from "react-redux";
import { risksRequest } from "actions";
import { ConnectedProps } from "types";
import { RouteComponentProps } from "react-router";

class RiskChart extends React.Component<RiskChartProps & PropsFromRedux & RouteComponentProps<{test: string}>, RiskChartState> {
  baseUrl = this.props.match.url;

  componentDidMount() {
    const testId = this.props.match.params.test;

    this.props.loadTestById(testId);
    this.props.loadRisksItems(testId);
  }

  renderRiskCreate = () => (
    <RiskCreate
      backRoute={this.baseUrl}
      testId={this.props.match.params.test}
      {...this.props.test}
    />
  );
  renderRiskUpdate = () => (
    <RiskUpdate backRoute={this.baseUrl} {...this.props.test} />
  );

  render() {
    const { test, match, risks, canExportTable } = this.props;
    const isRiskChartRoute = match.url.endsWith("risk-chart");
    if (!test) {
      return <div>Loading ...</div>;
    }

    return (
      <>
        {isRiskChartRoute && <AdminBackToTest />}
        <div className="risk-chart-block">
          <Grid container>
            <Grid item xs={12}>
              <RiskChartHeader test={test} />
            </Grid>
            <Grid item xs={12}>
              <div className="table-responsive">
                <BubbleChart risks={risks} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <RiskChartTable test={test} canExportTable={canExportTable} />
            </Grid>
            {isRiskChartRoute && (
              <Grid item xs={12}>
                <PermissionBlock roles={[Roles.ROLE_TESTER]}>
                  <RiskChartGuidance />
                </PermissionBlock>
              </Grid>
            )}
          </Grid>
          <Switch>
            <Route
              path={this.baseUrl + "/new-risk"}
              render={this.renderRiskCreate}
            />
            <Route
              path={this.baseUrl + "/edit-risk/:id"}
              render={this.renderRiskUpdate}
            />
          </Switch>
        </div>
      </>
    );
  }
}

type RiskChartProps = {
  canExportTable?: boolean;
};

type RiskChartState = {};

const mapStateToProps = (state, props) => ({
  test: selector.getItemById(result(props, "match.params.test", false))(
    state
  ),
  testIsLoaded: selector.getIsLoaded(state),
  risks: risksSelector.getItems(state),
  isLoaded: risksSelector.getIsLoaded(state)
});

const mapDispatchToProps = {
  loadRisksItems: risksRequest.getItems,
  loadTestById: testRequest.getItem
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(RiskChart));
