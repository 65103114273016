export enum Permission {
  CAN_MANAGE_PROJECT = "CAN_MANAGE_PROJECT",
  CAN_VIEW_PROJECT = "CAN_VIEW_PROJECT",
  CAN_EDIT_PROJECT_DETAILS = "CAN_EDIT_PROJECT_DETAILS",
  CAN_ARCHIVE_REACTIVATE_PROJECT = "CAN_ARCHIVE_REACTIVATE_PROJECT",
  CAN_CREATE_TEST = "CAN_CREATE_TEST",
  CAN_EDIT_TEST = "CAN_EDIT_TEST",
  CAN_VIEW_TEST = "CAN_VIEW_TEST",
  CAN_BOOK_TESTER = "CAN_BOOK_TESTER",
  CAN_CANCEL_BOOKING = "CAN_CANCEL_BOOKING",
  CAN_ACCEPT_TESTERS_PROPOSAL = "CAN_ACCEPT_TESTERS_PROPOSAL",
  CAN_REJECT_TESTERS_PROPOSAL = "CAN_REJECT_TESTERS_PROPOSAL",
  CAN_VIEW_REPORT = "CAN_VIEW_REPORT",
  CAN_ACCEPT_REPORT = "CAN_ACCEPT_REPORT",
  CAN_VIEW_SUMMARY = "CAN_VIEW_SUMMARY",
  CAN_VIEW_RISKS = "CAN_VIEW_RISKS",
  CAN_VIEW_LOCATIONS = "CAN_VIEW_LOCATIONS",
  CAN_MANAGE_LOCATIONS = "CAN_MANAGE_LOCATIONS",
  CAN_EDIT_COMPANY_DETAILS = "CAN_EDIT_COMPANY_DETAILS",
  CAN_REGISTER = "CAN_REGISTER",
  CAN_MANAGE_USERS = "CAN_MANAGE_USERS",
  CAN_VIEW_COMPANY_DETAILS = "CAN_VIEW_COMPANY_DETAILS",
  CAN_MAKE_TESTS = "CAN_MAKE_TESTS",
  CAN_ADMINISTRATE_SITE = "CAN_ADMINISTRATE_SITE",
  CAN_MANAGE_ADMINS = "CAN_MANAGE_ADMINS",
  CAN_MANAGE_PLANNERS = "CAN_MANAGE_PLANNERS",
  CAN_MANAGE_VIEWERS = "CAN_MANAGE_VIEWERS",
  CAN_CREATE_EDIT_PROJECT = "CAN_CREATE_EDIT_PROJECT",
  CAN_CREATE_EDIT_VIEW_LOCATIONS = "CAN_CREATE_EDIT_VIEW_LOCATIONS",
  CAN_MANAGE_PLANNER_EXTRA_PERMISSIONS = "CAN_MANAGE_PLANNER_EXTRA_PERMISSIONS",
  CAN_MANGE_TIME_EXPENSES = "CAN_MANGE_TIME_EXPENSES",
}
