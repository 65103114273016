import * as React from 'react';
import {getAuth, getIsLoadedPermissions, getRoles, getUserGroup, getUserPermissions} from '../../selectors/auth';
import {connect} from 'react-redux';
import {Permission} from "../../constants/permission";
import {difference} from 'lodash';
import {ROLE} from "../../constants/roles";

interface IStateProps {
  userRoles: string[];
  isLoadedPermissions: boolean;
  userPermissions: Permission[];
  userGroup: string;
  isUserAuth: boolean;
}

interface IOwnProps {
  permissions?: Permission[];
  group?: string;
  roles?: ROLE[];
  authorized?: boolean;
  children: any;
}

class PermissionBlock extends React.Component<IOwnProps & IStateProps, {}> {
  render() {
    if (!this.props.isLoadedPermissions) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    if (this.props.permissions &&
      !this.props.permissions.some(
        (permissionSeek: string) =>
          this.props.userPermissions.indexOf(permissionSeek as Permission) !== -1
      )) {
      return null;
    }

    if (this.props.roles &&
      !this.props.roles.some((roleSeek: string) => this.props.userRoles.indexOf(roleSeek) !== -1)) {
      return null;
    }


    if (this.props.group && this.props.group !== this.props.userGroup) {
      return null;
    }

    if (typeof this.props.authorized !== 'undefined' && this.props.authorized !== this.props.isUserAuth) {
      return null;
    }

    return this.props.children;
  };
}

function mapStateToProps(state): IStateProps {
  return {
    userRoles: getRoles(state),
    isLoadedPermissions: getIsLoadedPermissions(state),
    isUserAuth: getAuth(state),
    userPermissions: getUserPermissions(state),
    userGroup: getUserGroup(state),
  };
}

export default connect<IStateProps, {}, IOwnProps>(mapStateToProps)(PermissionBlock) as React.ComponentClass<IOwnProps>;
