import * as React from "react";
import {
  composeValidators,
  fieldsNotEmpty
} from "../../../../helpers/validations/validation";
import { InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import ProjectForm from "./ProjectForm";
import { withRouter } from "react-router-dom";
import routerModal, { IBackRoute } from "../../../../components/routerModal";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import locationsSelector from "../../../../selectors/locations";
import { ACTIVE } from "../../../../constants/filter";
import { dataFetcher } from "../../../../components/dataFetcher";
import { locationsRequest } from "../../../../actions/locations";
import { projectRequest } from "../../../../actions/project";
import { ILocation } from "../../../../models/location.interface";
import timesheetSelector from "../../../../selectors/time-expenses";
import { getTimesheetManagersAction } from "../../../../actions/time-expenses";

export const FORM_NAME = "ProjectCreate";

class ProjectCreate extends React.Component<
  InjectedFormProps<{}, {}> & {
    postData: (...any) => void;
    backRoute: string;
    locations: ILocation[];
    managers: Record<string, any>[];
  } & WithStyles<any>
> {
  render() {
    const {
      handleSubmit,
      postData,
      backRoute,
      managers = [],
      change
    } = this.props;
    const allManagers = managers.map(manager => ({
      id: manager.id,
      name: `${manager.firstName} ${manager.lastName}`
    }));

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(values => {
          postData(values, FORM_NAME, backRoute);
        })}
      >
        <ProjectForm
          backRoute={backRoute}
          locations={this.props.locations}
          managers={allManagers}
          change={change}
          formName={FORM_NAME}
        />
      </form>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      "name",
      "purchaseOrderNumber",
      "expensesPurchaseOrderNumber",
      "locations"
    ])
  )
})(ProjectCreate);

const connected = connect(null, {
  postData: projectRequest.postItem
})(formConnected);

// const withStyle = withStyles(defaultStyleWidth(400))(connected as any);

const componentWithData = dataFetcher(connected, [
  {
    key: "locations",
    selector: state => locationsSelector.getItems(state),
    action: () => locationsRequest.getItems(1, 1000, ACTIVE),
    alwaysReceiveFreshData: true
  },
  {
    key: "managers",
    selector: (state, props) =>
      timesheetSelector.getTimesheetManagersList(state),
    action: () => getTimesheetManagersAction(),
    alwaysReceiveFreshData: true
  }
]);

export default routerModal(
  withRouter(componentWithData as any)
) as React.ComponentClass<IBackRoute>;
