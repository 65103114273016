import {IConverterFromSwagger, IConverterToSwagger} from "./converter.interface";
import {ITest, ITestSwagger} from "../../models/test.interface";
import {extractId} from "../../helpers/converter-helper";
import {IAnyProps} from "../../interfaces/any-props.interface";
import moment from "moment";
import {EMPTY_TESTER_ID} from "../../constants/favorite-testers";

export const convertorFromSwagger: IConverterFromSwagger<any, any> = (modelIn: ITestSwagger & IAnyProps) => {
  const model: Partial<ITest | IAnyProps> = {...modelIn};

  if (!!modelIn.location) {
    model.location = modelIn.location;
  }

  if (!!modelIn.project) {
    model.project = extractId(modelIn.project);
  }

  if (!!modelIn.planner) {
    model.planner = extractId(modelIn.planner);
  }

  if (!!modelIn.proposal && modelIn.proposal.dateStart) {
    model.dateStart = modelIn.proposal.dateStart;
  }

  if (!!modelIn.report) {
    model.report = extractId(modelIn.report);
  }

  if (!!modelIn.risks && modelIn.risks.length) {
    model.risks = modelIn.risks.map(extractId);
  }

  if (!!modelIn.dateFrom) {
    model.dateFrom = modelIn.dateFrom;
  }

  if (!!modelIn.dateTo) {
    model.dateTo = modelIn.dateTo;
  }

  return model;
};

export const convertorToSwagger: IConverterToSwagger<any, any> = (model: ITest) => {
  const newModel: Partial<ITestSwagger | IAnyProps> = {...model};

  if (model.location) {
    newModel.location = `/api/locations/${model.location}`;
  }

  if ([0, 1].indexOf(Number(model.remote)) !== -1) {
    newModel.remote = Boolean(model.remote);
    if (Number(model.remote) === 1) {
      delete newModel.location;
    }
  }

  if (model.radius) {
    newModel.radius = model.radius.replace(/\D+/g, "");
    if (model.radius === "Anywhere") {
      newModel.radius = null;
    }
  }

  if (model.experience && model.experience === "Any") {
    newModel.experience = null;
  }

  if (model.certification && model.certification === "All") {
    newModel.certification = null;
  }

  if (model.certificateType) {
    newModel.certificateType = String(model.certificateType) === "Any"
        ? null
        : `/api/certificate_types/${model.certificateType}`;
  }

  if (model.publicLiability && model.publicLiability === 0) {
    newModel.publicLiability = null;
  }

  if (model.indemnityInsurance && model.indemnityInsurance === 0) {
    newModel.indemnityInsurance = null;
  }

  if (model.project) {
    newModel.project = `/api/projects/${model.project}`;
  }

  if (model.specialisms) {
    newModel.specialisms = [`/api/specialisms/${model.specialisms}`];
  }

  if (model.tester) {
    newModel.tester = `/api/tester_profiles/${model.tester}`;
  }

  if (model.preFillTester) {
    newModel.preFillTester = Number(model.preFillTester) === EMPTY_TESTER_ID
      ? null
      : `/api/tester_profiles/${model.preFillTester}`;
  }

  return newModel;
};

export const converterFromSwaggerCalendar = (model) => {
  const newModel = convertorFromSwagger(model);

  if (newModel.dateFrom) {
    newModel.start = moment(newModel.dateFrom);
  }

  if (newModel.dateTo) {
    newModel.end = moment(newModel.dateTo);
  }

  if (newModel.name) {
    newModel.title = newModel.name;
  }

  if (newModel.description) {
    newModel.desc = newModel.description;
  }

  return newModel;
};