import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Paper from "@material-ui/core/es/Paper/Paper";
import {getSpecialismsWithoutReportTemplatesAction} from "../../actions/specialisms-without-report-templates";


class ReportTemplatesAlert extends React.Component {
  componentDidMount() {
    this.props.getSpecialismsWithoutReportTemplatesAction();
  }

  render() {
    const {specialismsWithoutReportTemplates} = this.props;

    if (specialismsWithoutReportTemplates.length === 0) {
      return null;
    }

    return (
      <Paper className="p-3 mb-4 text-center" style={{backgroundColor: '#ffdddd'}}>
        No templates for {specialismsWithoutReportTemplates.map((item, index) => {
        if (index + 1 === specialismsWithoutReportTemplates.length) {
          return <b key={index}>{item}</b>;
        }

        return <b key={index}>{item}, </b>
      })}.
        Please finish the configuration <Link to="/admin/settings/report-templates">here</Link>.
      </Paper>
    );
  }
}

export default connect(
  state => ({
    specialismsWithoutReportTemplates: state.specialismsWithoutReportTemplates,
  }),

  {
    getSpecialismsWithoutReportTemplatesAction,
  }
)(ReportTemplatesAlert);
