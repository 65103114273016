import React, { useState, useEffect } from "react";
import SelectAllApproved from "./SelectAllApproved";
import SearchForm from "./SearchForm";
import selector from "../../../selectors/time-expenses";
import { connect } from "react-redux";
import UpdateSelectedWeeksStatusForm from "./forms/UpdateSelectedWeeksStatusForm";
import { TE_STATUS } from "../../../constants/timesheet-expenses";
import { bindActionCreators, Dispatch } from "redux";
import { getAllProjects } from "../../../actions/project";
import { getConsultantsRequestsAction } from "../../../actions/consultants";
import { FilterParamsType } from "../../../types/consultant";
import { ACTIVE } from "../../../constants/filter";
import TimeSheetWeeksList from "./TimeSheetWeeksList";

type AdminTimeSheetsComponentProps = {
  selectedProjectWeeks: number[];
  getAllProjects: () => void;
  getAllConsultants: (params: FilterParamsType) => void;
};

const AdminTimeSheetsComponent = (props: AdminTimeSheetsComponentProps) => {
  const { selectedProjectWeeks, getAllProjects, getAllConsultants } = props;
  const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);
  useEffect(() => {
    getAllProjects();
    getAllConsultants({ itemsPerPage: 999, show: ACTIVE });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-end pb-4">
        <button
          className="btn-accept"
          disabled={!selectedProjectWeeks.length}
          onClick={() => setIsOpenedModal(true)}
        >
          Generate invoice
        </button>
      </div>
      <SearchForm />
      <SelectAllApproved />
      <TimeSheetWeeksList asAdmin />
      {isOpenedModal && (
        <UpdateSelectedWeeksStatusForm
          closeModal={() => setIsOpenedModal(false)}
          confirmLabel="Yes"
          description="The invoices will be created for all selected weeks and projects:"
          status={TE_STATUS.STATUS_INVOICED}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    selectedProjectWeeks: selector.getSelectedProjectWeeks(state)
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAllProjects: getAllProjects,
      getAllConsultants: getConsultantsRequestsAction
    },
    dispatch
  );
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTimeSheetsComponent);

export default connected;
