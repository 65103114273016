import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import { Button } from "@material-ui/core";
import { dataFetcher } from "../../../../components/dataFetcher";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import { IReportDocument } from "../../../../models/report-documents.interface";
import {
  getReportDocumentStatusName,
  reportDocumentCanBeDeleted,
  reportDocumentIsUploadedOrNeedClarifications,
  reportDocumentShouldBeReUploaded,
  ReportDocumentStatus,
  ReportDocumentStatusNames
} from "../../../../constants/report-document-status";
import { API_DOCUMENTS_ENDPOINT_URL } from "../../../../constants/urls";
import { downloadDocumentFile } from "../../../../api/requests/document-download";
import { reportDocumentCreateModalOpen } from "../../../../actions/modals";
import { extractId } from "../../../../helpers/converter-helper";
import {
  removeReportDocument,
  setReportDocumentStatus
} from "../../../../actions/report-documents";
import {
  testerCanUploadDocuments,
  TestStatus
} from "../../../../constants/test-status";
import { formatDateWithTime } from "../../../../helpers/date-formatter";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import { ConfirmModal } from "../../../../components/ConfirmModal";

type IProps = RouteComponentProps<any> &
  IAnyProps & {
    reportDocument: IReportDocument;
    testStatus: TestStatus;
    report: any;
    reportDocumentCreateModal: (...any) => void;
    submitDocumentAction: (idReportDocument, reportDocument, status) => void;
    downloadDocumentFile: (id: number) => void;
  };

class CompleteReportRow extends React.Component<IProps, {}> {
  baseUrl = this.props.match.url;

  state = {
    openModal: false
  };

  toggleModalState = (state: boolean) => this.setState({ openModal: state });

  downloadFile = event => {
    const reportDocumentId = event.currentTarget.dataset.reportdocumentid;

    if (reportDocumentId) {
      this.props.downloadDocumentFile(reportDocumentId);
    }
  };
  uploadReportDocument = (report: IReportDocument) => () => {
    this.props.reportDocumentCreateModal(report, report.name);
  };
  submitReportDocument = (item: IReportDocument) => () => {
    this.props.submitDocumentAction(
      item.id,
      item,
      ReportDocumentStatus.STATUS_SUBMITTED
    );
  };
  deleteReportDocument = (report: IReportDocument) => {
    this.toggleModalState(false);
    this.props.removeReportDocument({ report });
  };
  renderDownloadFileCell = (report: IReportDocument) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        data-reportdocumentid={report.document
          .toString()
          .replace(API_DOCUMENTS_ENDPOINT_URL, "")}
        onClick={this.downloadFile}
      >
        Download
      </Button>
    );
  };
  renderReUploadReportDocumentCell = (
    report: IReportDocument,
    status: ReportDocumentStatus
  ) => {
    if (!reportDocumentShouldBeReUploaded(status)) {
      return null;
    }
    return (
      <Button
        type="submit"
        variant="contained"
        component="span"
        size="small"
        onClick={this.uploadReportDocument(report)}
      >
        UPLOAD
      </Button>
    );
  };

  renderDeleteReportDocumentCell = (report: IReportDocument) => {
    if (!reportDocumentCanBeDeleted(report.status)) {
      return null;
    }

    return (
      <IconButton
        onClick={() => {
          this.toggleModalState(true);
        }}
      >
        <Icon>delete</Icon>
      </IconButton>
    );
  };

  render() {
    const { reportDocument } = this.props;
    const {
      id,
      name,
      status,
      report: reportId,
      documentUploadedAt
    } = reportDocument;
    const rowStatusLabel = getReportDocumentStatusName(
      status as ReportDocumentStatus
    );

    return (
      <>
        <TableRow key={id}>
          <TableCell>{name}</TableCell>
          <TableCell>
            {rowStatusLabel !== ReportDocumentStatusNames.STATUS_REUPLOADED
              ? rowStatusLabel
              : ReportDocumentStatusNames.STATUS_UPLOADED}
          </TableCell>
          <TableCell>
            {formatDateWithTime(documentUploadedAt as string)}
          </TableCell>
          <TableCell>
            {this.renderReUploadReportDocumentCell(reportDocument, status)}
          </TableCell>
          <TableCell>
            <div>{this.renderDownloadFileCell(reportDocument)}</div>
          </TableCell>
          <TableCell align="right">
            {this.renderDeleteReportDocumentCell(reportDocument)}
          </TableCell>
        </TableRow>
        {this.state.openModal && (
          <ConfirmModal
            isOpened={this.state.openModal}
            onClose={() => this.toggleModalState(false)}
            onConfirm={() => this.deleteReportDocument(reportDocument)}
            confirmDescription="Are you sure you want to delete this report?"
            titleLabel="Delete report confirmation"
          />
        )}
      </>
    );
  }
}

const fetched = dataFetcher(CompleteReportRow);

const routered = withRouter(fetched as any);

const connected = connect<IAnyProps, IAnyProps, IAnyProps>(
  (state: any, ownProps: IAnyProps) => ({}),
  {
    reportDocumentCreateModal: reportDocumentCreateModalOpen,
    submitDocumentAction: setReportDocumentStatus,
    downloadDocumentFile,
    removeReportDocument
  }
)(routered);

export default connected as any;
