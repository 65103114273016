import React from "react";
import { AddTimeLogBtn } from "../AddTimeLogButton";
import { DayTimeExpensesLog } from "./DayTimeExpensesLog";
import { getCreateTotalTimeLogLink } from "../../../../helpers/router-helper";

type TotalCellProps = {
  isActiveWeek: boolean;
  totalDays?: number;
  totalExpensesAmount?: number;
  weekId: string;
};

const TotalCell = (props: TotalCellProps) => {
  const { isActiveWeek, totalDays, totalExpensesAmount, weekId } = props;
  const weeklyTimeLogRoute = getCreateTotalTimeLogLink();

  const dayTimeExpensesLog = !!totalDays || !!totalExpensesAmount;
  if (!dayTimeExpensesLog && isActiveWeek) {
    return (
      <div className="timesheet-day-cell-wrap">
        <AddTimeLogBtn to={`${weeklyTimeLogRoute}/${weekId}`} />
      </div>
    );
  }

  if (dayTimeExpensesLog) {
    return (
      <div className="timesheet-day-cell-wrap">
        <DayTimeExpensesLog days={totalDays} expenses={totalExpensesAmount} />
      </div>
    );
  }

  return null;
};

export default TotalCell;
