import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ITest } from "../../../models/test.interface";
import { result, find } from "lodash";
import { getLocationString } from "../../../helpers/location-helper";
import { ILocation } from "../../../models/location.interface";
import { APPROACHES_LIST } from "../../../constants/approach";
import { Recurring, RECURRING_LIST } from "../../../constants/recurring";
import { WEEKEND_LIST } from "../../../constants/weekend";
import { REMINDERS_LIST } from "../../../constants/reminders";
import { Grid, IconButton, MenuItem } from "@material-ui/core";
import { formatDateWithSlashesDdMmYyyy } from "../../../helpers/date-formatter";
import Iframe from "../../common/Iframe";
import CertificateModal from "./modal/CertificateModal";
import { getUrlSecureDocument } from "../../../api/requests/document-download";
import { connect } from "react-redux";
import { openSnackbar } from "../../../actions";
import {
  STATUS_RE_VALIDATE_REQUIRED,
  STATUS_VALIDATION_PASSED
} from "../../../constants/dbs-certificate-statuses";
import { extractSpecialisms } from "../../../helpers/specialims-extractor";
import { BASE_URL } from "../../../constants/urls";
import moment from "moment";
import TooltipIcon from "../../../../node_modules/@material-ui/core/Tooltip/Tooltip";
import { Zoom } from "@material-ui/core";
import Icon from "@material-ui/core/Icon/Icon";
import TextField from "@material-ui/core/TextField";
import {
  savePONumberAction,
  updateServiceDatesAction
} from "../../../actions/test";
import { getUserGroup } from "../../../selectors/auth";
import { Group } from "constants/group";
import { YES_NO_OPTIONS_LIST } from "../../../constants/yesNoOptions";
import { IState } from "../../../interfaces/state.interface";
import { assignConsultants, getInputConsultants } from "../../../actions";
import selector from "../../../selectors/users";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import { InjectedFormProps, reduxForm } from "redux-form";
import { composeValidators } from "../../../helpers/validations/validation";
import { IUsers } from "../../../models/users.interface";
import { Dispatch } from "redux";
import { TestStatus } from "../../../constants/test-status";
import UpdateTestDatesForm from "./UpdateTestDatesForm";
import { UpdateServiceDateActionType } from "../../../types/test-info";

export interface ICreated {
  test: ITest;
  locationTest: ILocation;
  baseUrl: string;
  getUrlSecureDocument: (id: number) => any;
  savePONumber: (PONumber: string, testId?: number) => any;
  loadTest: (testId?: number) => any;
  openSnackbar: (
    message: string,
    time?: number
  ) => {
    type: string;
    payload: any;
  };
  group?: string;
  inputConsultants?: IUsers[];
}

interface IComponentState {
  isCertificateModalOpen: boolean;
  modalImg: null | any;
  modalTitle: string;
  isPOEditable: boolean;
  isDateEditable: boolean;
  isAssignConsultant: boolean;
  assignConsultant: string;
  users: {
    items: {
      items: IUsers;
    };
  };
  getInputConsultantsTesters: () => (dispatch: Dispatch) => Promise<void>;
  updateServiceDate: (
    data: UpdateServiceDateActionType
  ) => (dispatch: Dispatch) => Promise<void>;
  assignInputConsultant: (data: {
    test_id?: number;
    consultant_input_id: number;
  }) => (dispatch: Dispatch) => Promise<void>;
}

interface IRenderOptions {
  firstName: string;
  lastName: string;
  id: number;
}

const FORM_NAME = "TestInfo";

class TestInfo extends React.Component<
  RouteComponentProps<{ path: string }> &
    ICreated &
    IComponentState &
    IState &
    IRenderOptions &
    InjectedFormProps<{ inputConsultant: number }>
> {
  baseUrl = this.props.match.path;

  state = {
    isCertificateModalOpen: false,
    modalImg: null,
    modalTitle: "",
    isPOEditable: false,
    isDateEditable: false,
    isAssignConsultant: true,
    assignConsultant: "None",
    PONumber: this.props.test.purchaseOrderNumber || ""
  };

  componentDidMount() {
    const { currentUser } = this.props;

    if (currentUser?.isConsultancyCompanyManager) {
      this.props.getInputConsultantsTesters();
    }
  }

  isDbsChecked = () => {
    if (this.props.test && this.props.test.tester) {
      return (
        this.props.test.tester.dBSCheckMinStatus ===
          STATUS_RE_VALIDATE_REQUIRED ||
        this.props.test.tester.dBSCheckMinStatus === STATUS_VALIDATION_PASSED
      );
    }

    return false;
  };

  closeCertificateModal = () => {
    this.setState({ isCertificateModalOpen: false, modalImg: null });
  };

  renderSpecialisms = () =>
    extractSpecialisms(this.props.test.specialisms).map((specialism, index) => {
      return (
        <div key={index}>
          <strong className="mt-2 d-block">{specialism.l1}</strong>
          {specialism.l2.map(l2 => (
            <div key={l2}>{l2}</div>
          ))}
        </div>
      );
    });

  renderLocationRadius = value => {
    return `Within ${value} miles`;
  };

  getRequiredByValue = () => {
    if (this.props.test.daysForResponse) {
      if (this.props.test.daysForResponse === -1) {
        return "Any";
      }
      return this.props.test.daysForResponse > 1
        ? `${this.props.test.daysForResponse} days`
        : "1 day";
    }
    return "No required by value";
  };

  validateDates = () => {
    if (this.props.test && this.props.test.dateTo) {
      const dateTo = moment(this.props.test.dateTo).startOf("day");
      const today = moment().startOf("day");
      return dateTo.isSameOrAfter(today);
    }

    return false;
  };

  checkForDateErrors = () => {
    if (!this.validateDates()) {
      return { color: "red" };
    }
    return {};
  };
  handleEditPOClick = () => {
    this.setState({ isPOEditable: true });
  };

  handleEditAssign = () => {
    this.setState({ isAssignConsultant: !this.state.isAssignConsultant });
  };
  handleCancelPOClick = () => {
    this.setState({
      isPOEditable: false,
      PONumber: this.props.test.purchaseOrderNumber || ""
    });
  };
  handlePOChange = e => {
    this.setState({ PONumber: e.target.value });
  };
  savePOHandler = () => {
    const value = this.state.PONumber;
    const { openSnackbar } = this.props;
    if (value.length > 150) {
      openSnackbar("Purchase number length should be less than 150 symbols");
      return;
    }
    this.props.savePONumber(value, this.props.test.id);
    this.setState({ isPOEditable: false });
  };

  openDatesEditForm = () => {
    this.setState({ isDateEditable: true });
  };
  cancelDatesEditForm = () => {
    this.setState({
      isDateEditable: false
    });
  };
  updateDatesEditForm = (values: { dateFrom: string; dateTo: string }) => {
    this.props.updateServiceDate({
      ...values,
      testId: this.props.test.id as number,
      callBack: () => this.setState({ isDateEditable: false })
    });
  };

  canEditPONumber = () => {
    const { group } = this.props;
    return group === Group.OWNER || group === Group.PLANNER;
  };
  canEditDates = () => {
    const { group } = this.props;
    return (
      [Group.OWNER, Group.PLANNER].includes(group as Group) &&
      this.props.test.status !== TestStatus.STATUS_COMPLETED
    );
  };

  renderOption = (value: IRenderOptions): JSX.Element => (
    <MenuItem
      key={value.id}
      value={value.id}
      defaultValue={this.props.test?.consultantInput?.user?.id}
    >
      {value.firstName} {value.lastName}
    </MenuItem>
  );

  render() {
    const {
      currentUser,
      test,
      inputConsultants = [],
      assignInputConsultant
    } = this.props;
    const isCompanyInputManager = currentUser?.isConsultancyCompanyManager;

    const isEditable =
      isCompanyInputManager &&
      test.status !== TestStatus.STATUS_REPORT_ACCEPTED &&
      test.status !== TestStatus.STATUS_COMPLETED;

    const noneUser = {
      firstName: "None",
      lastName: "",
      id: 0
    };

    const inputTesters = [
      noneUser,
      ...Array.from(inputConsultants)?.filter(
        user => user.groupName === Group.AVORD_CONSULTANT_INPUT
      )
    ];

    const inputTesterFullName = this.props.test?.consultantInput
      ? `${this.props.test?.consultantInput?.user?.firstName} ${this.props.test?.consultantInput?.user?.lastName}`
      : "None";

    const selectedInputTester =
      this.state.assignConsultant !== "None"
        ? this.state.assignConsultant
        : inputTesterFullName;

    return (
      <Grid container>
        <CertificateModal
          open={this.state.isCertificateModalOpen}
          onClose={this.closeCertificateModal}
          src={this.state.modalImg}
          title={this.state.modalTitle}
        />
        <div className="test-details-table">
          <table>
            <tbody>
              <tr>
                <th colSpan={2}>
                  <h4 className="test-details-header mt-0">Common Info</h4>
                </th>
              </tr>
              <tr>
                <td>Project Name</td>
                <td>{test.projectName}</td>
              </tr>
              <tr>
                <td>Title</td>
                <td>{test.hash + "-" + test.name}</td>
              </tr>
              {this.canEditPONumber() && (
                <>
                  <tr>
                    <th colSpan={2}>
                      <h4 className="test-details-header mt-0">
                        Procurement details
                      </h4>
                    </th>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      Purchase order number
                    </td>
                    {this.state.isPOEditable ? (
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            placeholder="Input PO number"
                            value={this.state.PONumber}
                            onChange={this.handlePOChange}
                            style={{ width: "100%" }}
                          />
                          <IconButton
                            style={{ marginLeft: "20px" }}
                            onClick={this.savePOHandler}
                          >
                            <Icon>save</Icon>
                          </IconButton>
                          <IconButton onClick={this.handleCancelPOClick}>
                            <Icon>cancel</Icon>
                          </IconButton>
                        </div>
                      </td>
                    ) : (
                      <td>
                        <div className="d-inline-flex">
                          <div className="d-flex align-items-center word-break-all">
                            {test.purchaseOrderNumber || "-"}
                          </div>
                          <button
                            className="btn-edit"
                            style={{ marginLeft: "20px" }}
                            onClick={this.handleEditPOClick}
                          >
                            Edit
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                </>
              )}

              <tr>
                <th colSpan={2} className="pt-4">
                  <h4 className="test-details-header">
                    Where will the service take place
                  </h4>
                </th>
              </tr>
              {!test.remote ? (
                <tr>
                  <td>Location</td>
                  <td>
                    {test.location
                      ? getLocationString(test.location as ILocation)
                      : ""}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>Remote</td>
                  <td>{test.remote ? "Yes" : "No"}</td>
                </tr>
              )}
              <tr>
                <th colSpan={2} className="pt-4">
                  <h4 className="test-details-header">
                    How do you want the service to be continued
                  </h4>
                </th>
              </tr>
              <tr>
                <td>Type</td>
                <td>{this.renderSpecialisms()}</td>
              </tr>
              <tr>
                <td>Approach</td>
                <td>
                  {result(
                    find(APPROACHES_LIST, { key: test.approach }),
                    "value"
                  )}
                </td>
              </tr>
              <tr>
                <td>CVSS Scoring</td>
                <td>
                  {result(
                    find(YES_NO_OPTIONS_LIST, { key: test.vulnerabilities }),
                    "value"
                  )}
                </td>
              </tr>
              {test.plannerName && (
                <React.Fragment>
                  <tr>
                    <th colSpan={2} className="pt-4">
                      <h4 className="test-details-header">
                        Who should the Consultant/Tester report to
                      </h4>
                    </th>
                  </tr>
                  <tr>
                    <td>Company</td>
                    <td>{test.plannerCompanyName}</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{test.plannerName}</td>
                  </tr>
                </React.Fragment>
              )}
              <tr>
                <th colSpan={2} className="pt-4">
                  <h4 className="test-details-header">Details</h4>
                </th>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="test-info-header mb-2">
                    Needs/requirements
                  </div>
                  <Iframe srcDoc={test.description} />
                </td>
              </tr>
              <tr>
                <th colSpan={2} className="pt-4">
                  <h4 className="test-details-header">Proposed date range</h4>
                </th>
              </tr>

              {test.dateFrom && test.dateTo && (
                <>
                  <tr>
                    <td>Service date</td>
                    {!this.canEditDates() && (
                      <td style={this.checkForDateErrors()}>
                        {formatDateWithSlashesDdMmYyyy(test.dateFrom)}
                        &nbsp;-&nbsp;
                        {formatDateWithSlashesDdMmYyyy(test.dateTo)}
                      </td>
                    )}
                    {this.canEditDates() && this.state.isDateEditable && (
                      <td></td>
                    )}
                    {this.canEditDates() && !this.state.isDateEditable && (
                      <td style={this.checkForDateErrors()}>
                        {formatDateWithSlashesDdMmYyyy(test.dateFrom)}
                        &nbsp;-&nbsp;
                        {formatDateWithSlashesDdMmYyyy(test.dateTo)}
                        <button
                          className="btn-edit"
                          style={{ marginLeft: "20px" }}
                          onClick={this.openDatesEditForm}
                        >
                          Edit
                        </button>
                      </td>
                    )}
                  </tr>
                  {this.canEditDates() && this.state.isDateEditable && (
                    <tr>
                      <td colSpan={2} style={{ paddingRight: 0 }}>
                        <UpdateTestDatesForm
                          initialDateFrom={this.props.test.dateFrom}
                          initialDateTo={this.props.test.dateTo}
                          status={this.props.test.status}
                          onCancel={this.cancelDatesEditForm}
                          onSave={this.updateDatesEditForm}
                        />
                      </td>
                    </tr>
                  )}
                </>
              )}
              <tr>
                <td>Recurring</td>
                <td>
                  {result(
                    find(RECURRING_LIST, { key: test.recurringType }),
                    "value"
                  )}
                </td>
              </tr>
              {test.recurringType &&
                test.recurringType !== Recurring.RECURRING_NO && (
                  <React.Fragment>
                    <tr>
                      <td>Include weekends</td>
                      <td>
                        {result(
                          find(WEEKEND_LIST, { key: test.includeWeekendsType }),
                          "value"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>For how many years</td>
                      <td>{test.years}</td>
                    </tr>
                  </React.Fragment>
                )}
              <tr>
                <td>Send reminders</td>
                <td>
                  {result(
                    find(REMINDERS_LIST, { key: test.remindersType }),
                    "value"
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "bottom" }}>
                  Response required by
                </td>
                <td className="d-flex align-items-center">
                  {this.getRequiredByValue()}
                  <TooltipIcon
                    placement={"top-end"}
                    TransitionComponent={Zoom}
                    disableFocusListener
                    disableTouchListener
                    title="Note: The response time relates to the initial contact by the tester / consultant"
                  >
                    <Icon className="ml-2">info</Icon>
                  </TooltipIcon>
                </td>
              </tr>
              <tr>
                <th colSpan={2} className="pt-4">
                  <h4 className="test-details-header">Chosen Preferences</h4>
                </th>
              </tr>
              {test.preFillTester && (
                <tr>
                  <td className="pt-3">Consultancy</td>
                  <td>
                    <img
                      src={
                        !!test.preFillTester.profileImagePath
                          ? BASE_URL + test.preFillTester.profileImagePath
                          : "/images/user-large.svg"
                      }
                      alt={
                        test.preFillTester.testerFirstName +
                        " " +
                        test.preFillTester.testerLastName
                      }
                      className="rounded-profile-select img-fluid"
                    />
                    <span className={"span-in-select"}>
                      {test.preFillTester.testerFirstName +
                        " " +
                        test.preFillTester.testerLastName}
                    </span>
                  </td>
                </tr>
              )}

              {!test.preFillTester && (
                <>
                  <tr>
                    <td>Located</td>
                    <td>
                      {test.radius
                        ? this.renderLocationRadius(test.radius)
                        : "Anywhere"}
                    </td>
                  </tr>
                  <tr>
                    <td>Certificate</td>
                    <td>
                      {test.certificateType
                        ? test.certificateType.shortName +
                          " - " +
                          test.certificateType.fullName
                        : "All"}
                    </td>
                  </tr>
                  <tr>
                    <td>Experience</td>
                    <td>
                      {test.experience ? test.experience + " year(s)" : "Any"}
                    </td>
                  </tr>
                  {test.isGovernmentCleared && (
                    <tr>
                      <td>Government Cleared</td>
                      <td>Yes</td>
                    </tr>
                  )}
                  {test.isCheckRegisteredCompany && (
                    <tr>
                      <td>CHECK registered company</td>
                      <td>Yes</td>
                    </tr>
                  )}
                  {test.isCrestRegisteredCompany && (
                    <tr>
                      <td>CREST registered company</td>
                      <td>Yes</td>
                    </tr>
                  )}
                  {this.isDbsChecked() && (
                    <tr>
                      <td>DBS checked</td>
                      <td>Yes</td>
                    </tr>
                  )}
                </>
              )}
              {test.isRetest && (
                <tr>
                  <td>Retest</td>
                  <td className="d-flex align-items-center">
                    Yes
                    <TooltipIcon
                      placement={"top-end"}
                      TransitionComponent={Zoom}
                      disableFocusListener
                      disableTouchListener
                      title="The organization should take steps to remediate any exploitable vulnerability
                            within a reasonable period of time after the original test.
                            When the organization has completed these steps, the tester should perform a retest to
                            validate that the newly implemented controls mitigate the original risks"
                    >
                      <Icon className="ml-1">info</Icon>
                    </TooltipIcon>
                  </td>
                </tr>
              )}
              {(isCompanyInputManager || test?.consultantInput) && (
                <>
                  <tr>
                    <th colSpan={2} className="pt-4">
                      <h4 className="test-details-header">
                        AVORD Consultant Input
                      </h4>
                    </th>
                  </tr>
                  <>
                    <tr>
                      <td className="align-middle">Name</td>
                      {this.state.isAssignConsultant ? (
                        <td>
                          <div className="d-inline-flex">
                            <div className="d-flex align-items-center word-break-all">
                              {selectedInputTester || "None"}
                            </div>
                            {isEditable && (
                              <button
                                className="btn-edit"
                                style={{
                                  marginLeft: "20px",
                                  marginTop: "-5px"
                                }}
                                onClick={this.handleEditAssign}
                              >
                                Edit
                              </button>
                            )}
                          </div>
                        </td>
                      ) : (
                        <td>
                          <div className="d-flex align-items-center">
                            <FieldGeneric
                              name="inputConsultant"
                              label="Consultant"
                              component={ReduxSelectField}
                              margin="normal"
                            >
                              {inputTesters?.map(this.renderOption)}
                            </FieldGeneric>
                            <div className="ml-5 d-flex align-items-center">
                              <form
                                onSubmit={this.props.handleSubmit(
                                  async ({ inputConsultant }) => {
                                    const values = {
                                      test_id: test.id,
                                      consultant_input_id: inputConsultant
                                    };
                                    assignInputConsultant(values);
                                    const selectInputTester = inputTesters?.find(
                                      tester => tester.id === inputConsultant
                                    );

                                    this.setState({
                                      assignConsultant: `${selectInputTester?.firstName} ${selectInputTester?.lastName}`
                                    });
                                    this.handleEditAssign();
                                  }
                                )}
                              >
                                <button
                                  className="btn-search mt-4"
                                  type="submit"
                                >
                                  <span className="ml-3 mr-3">Assign</span>
                                </button>
                              </form>
                              <IconButton
                                style={{ marginTop: "20px" }}
                                onClick={this.handleEditAssign}
                              >
                                <Icon>cancel</Icon>
                              </IconButton>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  </>
                </>
              )}
            </tbody>
          </table>
        </div>
      </Grid>
    );
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(values => {
    if (values.inputConsultant === undefined) {
      return {
        inputConsultant: "Required"
      };
    }
    return {};
  })
})(TestInfo);

const routeredComponent = withRouter(formConnected) as any;

const mapStateToProps = (state: IState & IComponentState, props) => {
  return {
    currentUser: state.auth,
    inputConsultants: state.users.items.items[0],
    group: getUserGroup(state),
    data: selector.getItems(state),
    initialValues: {
      inputConsultant: props.test?.consultantInput?.user?.id
    }
  };
};

const mapDispatchToProps = dispatch => ({
  getUrlSecureDocument,
  assignInputConsultant: model => dispatch(assignConsultants(model)),
  getInputConsultantsTesters: () => dispatch(getInputConsultants()),
  openSnackbar: message => dispatch(openSnackbar(message, 4000)),
  savePONumber: (PONumber, testId) =>
    dispatch(savePONumberAction(PONumber, testId)),
  updateServiceDate: ({
    dateFrom,
    dateTo,
    testId,
    callBack
  }: UpdateServiceDateActionType) =>
    dispatch(updateServiceDatesAction({ dateFrom, dateTo, testId, callBack }))
});

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(routeredComponent);

export default connectedComponent as any;
