import React, { ComponentClass } from "react";
import {
  composeValidators,
  fieldsNotEmpty
} from "../../../../helpers/validations/validation";
import { InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { locationsRequest } from "../../../../actions/locations";
import { projectRequest } from "../../../../actions/project";
import selector from "../../../../selectors/project";
import ProjectForm from "./ProjectForm";
import routerModal, { IBackRoute } from "../../../../components/routerModal";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import locationsSelector from "../../../../selectors/locations";
import { ACTIVE } from "../../../../constants/filter";
import { dataFetcher } from "../../../../components/dataFetcher";
import { ILocation } from "../../../../models/location.interface";
import { MAX_PAGINATION_RESULTS } from "../../../../constants/loader";
import { result } from "lodash";
import { withRouter } from "react-router-dom";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import timesheetSelector from "../../../../selectors/time-expenses";
import { getTimesheetManagersAction } from "../../../../actions/time-expenses";

const FORM_NAME = "ProjectUpdate";

class ProjectUpdate extends React.Component<
  InjectedFormProps<{}, {}> & {
    putData: (...any) => void;
    backRoute: string;
    locations: ILocation[];
    managers: any;
  } & WithStyles<any>,
  any
> {
  render() {
    const { handleSubmit, putData, backRoute, managers, change } = this.props;
    const allManagers = managers.map(manager => ({
      id: manager.id,
      name: `${manager.firstName} ${manager.lastName}`
    }));
    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(values => {
          putData(values, FORM_NAME, backRoute);
        })}
      >
        <ProjectForm
          backRoute={backRoute}
          locations={this.props.locations}
          managers={allManagers}
          change={change}
          formName={FORM_NAME}
        />
      </form>
    );
  }
}

const componentWithData = withRouter(
  dataFetcher(ProjectUpdate, [
    {
      key: "locations",
      selector: (state, props) => locationsSelector.getItems(state),
      action: () => locationsRequest.getItems(1, MAX_PAGINATION_RESULTS, ACTIVE)
    },
    {
      key: "project",
      selector: (state, props) =>
        projectRequest.getItem(result(props, "match.params.id", false)),
      action: props =>
        selector.getItemById(result(props, "match.params.id", false))
    },
    {
      key: "managers",
      selector: (state, props) =>
        timesheetSelector.getTimesheetManagersList(state),
      action: () => getTimesheetManagersAction(),
      alwaysReceiveFreshData: true
    }
  ]) as any
);

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      "name",
      "purchaseOrderNumber",
      "expensesPurchaseOrderNumber",
      "locations"
    ])
  )
})(componentWithData as any) as any;

const mapStateToProps = (state, props: any) => {
  const project: IAnyProps = selector.getItemById(
    result(props, "match.params.id", false)
  )(state);
  if (project) {
    return {
      initialValues: {
        id: project.id,
        name: project.name,
        purchaseOrderNumber: project.purchaseOrderNumber || "",
        expensesPurchaseOrderNumber: project.expensesPurchaseOrderNumber || "",
        manager: project.managerInfo?.user_id,
        locations: project.locations.map(location => location.id)
      }
    };
  }

  return {};
};

const connected = connect(mapStateToProps, {
  putData: projectRequest.putItem
})(formConnected);

export default routerModal(connected) as React.ComponentClass<IBackRoute>;
