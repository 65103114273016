import * as React from "react";
import {composeValidators, fieldIsEmail, fieldsNotEmpty} from "../../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {usersRequest} from "../../../../actions/users";
import AdminUsersForm from "./AdminUsersForm";
import {withRouter} from "react-router-dom";
import routerModal from "../../../../components/routerModal";
import {WithStyles} from "@material-ui/core/styles/withStyles";
import {Group, GroupTypes} from "../../../../constants/group";
import {dataFetcher} from "../../../../components/dataFetcher";
import groupsSelector from "../../../../selectors/groups";
import {groupsRequest} from "../../../../actions/groups";
import {ACTIVE} from "../../../../constants/filter";
import {find} from "lodash";

const FORM_NAME = "AdminUsersCreate";

class AdminUsersCreate extends React.Component<InjectedFormProps<{}, {}> & {
  postData: (...any) => void,
  postSubuserData: (...any) => void,
  backRoute: string,
  group: Group,
  groupName: string,
} & WithStyles<any>> {
  render() {
    const {handleSubmit, postData, backRoute} = this.props;

    return (
      <div className="styled-modal md">
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((values) => {
            postData(values, FORM_NAME, backRoute);
          })}
        >
          <AdminUsersForm backRoute={backRoute}/>
        </form>
      </div>
    );
  };
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(["firstName", "lastName", "email",]),
    fieldIsEmail(["email",]),
  )
})(AdminUsersCreate);

const connected = connect(
  (state: any, props: any) => {
      const group = find(props.values.groups, {name: Group.ADMIN});

      return group ? {initialValues: {group: group['@id']}} : {};
  },
  {
    postData: usersRequest.postItem,
  }
)(formConnected);

const componentWithData = dataFetcher(
    connected,
    [
        {
            key: 'groups',
            selector: (state) => groupsSelector.getItems(state),
            action: (props) => groupsRequest.getItems(1, 1000, ACTIVE, '', {type: GroupTypes.ADMIN}),
            alwaysReceiveFreshData: false,
        },
    ]
);

export default routerModal(withRouter(componentWithData as any)) as any;
