import React, { useState } from "react";
import SearchByMonthForm from "./SearchByMonthForm";
import { InfoMessage } from "./InfoMessage";
import { ConsultantProfileType } from "types/timesheets-and-expenses";
import { getConsultantProfile } from "selectors/auth";
import { connect } from "react-redux";
import selector from "selectors/time-expenses";
import UpdateSelectedWeeksStatusForm from "./forms/UpdateSelectedWeeksStatusForm";
import { TE_STATUS } from "constants/timesheet-expenses";
import { TableNoItems } from "components/table/TableNoItems";
import TimeSheetWeeksList from "./TimeSheetWeeksList";

const infoMessage =
  "Please ensure all field are completed and submitted by Monday 12:00 PM to ensure prompt payment";

type ConsultantTimeSheetsComponentProps = {
  // weeksItems: ReducedWeekType[];
  consultantProfile: ConsultantProfileType;
  selectedProjectWeeks: number[];
};

const ConsultantTimeSheetsComponent = (
  props: ConsultantTimeSheetsComponentProps
) => {
  const { consultantProfile, selectedProjectWeeks } = props;
  const [isOpenedConfirmModal, setIsOpenedConfirmModal] = useState<boolean>(
    false
  );
  const {
    id: consultantId,
    name: consultantName,
    projects
  } = consultantProfile;

  if (!projects?.length) {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
          <h1 className="main-content-header mb-0">Time & Expenses</h1>
        </div>
        <TableNoItems hasWrapper />
      </>
    );
  }

  const projectsList = Array.isArray(projects)
    ? projects.map(item => ({
        id: item.project_id,
        name: item.project_name,
        consultantId: consultantId as number,
        consultantName
      }))
    : [];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
        <h1 className="main-content-header mb-0">Time & Expenses</h1>
        <div>
          <button
            className="btn-accept"
            disabled={!selectedProjectWeeks.length}
            onClick={() => setIsOpenedConfirmModal(true)}
          >
            Submit selected
          </button>
        </div>
      </div>
      <SearchByMonthForm />
      <InfoMessage message={infoMessage} />
      <TimeSheetWeeksList projectsList={projectsList} asConsultant />
      {isOpenedConfirmModal && (
        <UpdateSelectedWeeksStatusForm
          closeModal={() => setIsOpenedConfirmModal(false)}
          description="You are going to submit selected weeks. Please leave a comment if necessary."
          status={TE_STATUS.STATUS_SUBMITTED}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    consultantProfile: getConsultantProfile(state),
    selectedProjectWeeks: selector.getSelectedProjectWeeks(state)
  };
}

const connected = connect(mapStateToProps)(ConsultantTimeSheetsComponent);

export default connected;
