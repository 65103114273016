import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Header from "../guest/Header";
import Footer from "../guest/Footer";
import Dashboard from "./dashboard/Dashboard";

const Consultant = () => (
  <div>
    <Header />
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="content">
            <Switch>
              <Redirect from="/consultant/" exact to="/consultant/dashboard" />
              <Route path="/consultant/dashboard" component={Dashboard} />
            </Switch>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Consultant;
