import React, { useEffect } from "react";
import debounce from "debounce";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import { TableNoItems } from "../../../components/table/TableNoItems";
import { connect } from "react-redux";
import { formatDateWithTime } from "../../../helpers/date-formatter";
import { bindActionCreators } from "redux";
import {
  archiveConsultantAction,
  consultantsLoader,
  deleteConsultantAction,
  getConsultantsRequestsAction,
  unArchiveConsultantAction
} from "../../../actions/consultants";
import selector from "../../../selectors/consultants";
import { formValueSelector } from "redux-form";
import { getFilterPreviewValue } from "../../../helpers/component-prev-values";
import {
  FilterParamsType,
  ResponseDataType,
  SearchFormType
} from "../../../types/consultant";
import TableCellArchive from "../../../components/table/TableCellArchive";
import TableCellDelete from "../../../components/table/TableCellDelete";
import TableCellEdit from "../../../components/table/TableCellEdit";
import { TableCellResetPassword } from "../../../components/table/TableCellResetPassword";
import { postEmail, resetAction2FA } from "../../../actions";
import { ALL, ARCHIVED, FILTER_TYPE } from "../../../constants/filter";
import { CONSULTANT_SEARCH_FORM } from "../../../constants/consultant";
import { ProjectsCell } from "./components/ProjectsCell";

type ConsultantListProps = {
  data: Array<ResponseDataType>;
  isLoaded: boolean;
  pageNumber: number;
  itemsPerPage: number;
  totalItems: number;
  searchFormFilters: SearchFormType;
  setItemsPerPage: (value: number) => void;
  setPageNumber: (value: number) => void;
  loadItems: (params: FilterParamsType) => void;
  baseUrl: string;
  postEmail: (email: string, formName: string) => void;
  deleteConsultant: (id: number, name: string) => void;
  archiveConsultant: (id: ResponseDataType, filter?: FILTER_TYPE) => void;
  unArchiveConsultant: (id: ResponseDataType, filter?: FILTER_TYPE) => void;
  resetAction2FA: (id: number) => void;
};

const searchFormSelector = formValueSelector(CONSULTANT_SEARCH_FORM);
const getShouldUpdateValues = (
  prevSearchValues: FilterParamsType,
  searchValues: FilterParamsType
) => {
  const shouldReloadList =
    JSON.stringify(prevSearchValues) !== JSON.stringify(searchValues);

  return {
    shouldReloadList,
    isTextFieldUpdated:
      prevSearchValues?.nameEmailPhone !== searchValues?.nameEmailPhone
  };
};

const ConsultantsList = (props: ConsultantListProps) => {
  const {
    data,
    isLoaded,
    pageNumber,
    itemsPerPage,
    totalItems,
    searchFormFilters,
    setItemsPerPage,
    setPageNumber,
    loadItems,
    baseUrl,
    postEmail,
    deleteConsultant,
    archiveConsultant,
    unArchiveConsultant,
    resetAction2FA
  } = props;

  const filterInitialValues = {
    consultantProject: [],
    pageNumber: 1,
    itemsPerPage: 25
  };

  const searchValues = { ...searchFormFilters, pageNumber, itemsPerPage };
  const prevSearchValues = getFilterPreviewValue(
    searchValues,
    filterInitialValues
  );
  const isInactiveFilter = searchFormFilters?.show === ARCHIVED;
  const shouldRerenderList = getShouldUpdateValues(
    prevSearchValues,
    searchValues
  );

  useEffect(() => {
    loadItems(searchValues);
  }, []);

  useEffect(() => {
    const { shouldReloadList, isTextFieldUpdated } = shouldRerenderList;
    const debounceLoadItems = debounce(loadItems, 500);

    if (isTextFieldUpdated) {
      debounceLoadItems(searchValues);
    } else if (shouldReloadList) {
      loadItems(searchValues);
    }
  }, [shouldRerenderList]);

  const handleChangeRowsPerPage = event => {
    setItemsPerPage(event.target.value);
  };

  const handleChangePage = (_, page: number) => {
    setPageNumber(page + 1);
  };

  if (!isLoaded) {
    return <TableNoItems hasWrapper asLoading />;
  }

  if (!data.length) {
    return <TableNoItems hasWrapper />;
  }

  const centeredCellStyles = {
    style: { whiteSpace: "pre-wrap" },
    padding: "checkbox",
    align: "center"
  } as const;

  return (
    <>
      <Paper style={{ overflowX: "auto", width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ whiteSpace: "pre-wrap" }}>Name</TableCell>
              <TableCell {...centeredCellStyles}>Email</TableCell>
              <TableCell {...centeredCellStyles}> Projects</TableCell>
              <TableCell {...centeredCellStyles}>Telephone</TableCell>
              <TableCell {...centeredCellStyles}>Created At</TableCell>
              <TableCell {...centeredCellStyles}>Reset password</TableCell>
              <TableCell {...centeredCellStyles}>Reset 2FA</TableCell>
              {!isInactiveFilter && (
                <TableCell {...centeredCellStyles} width={80}>
                  Edit
                </TableCell>
              )}
              <TableCell {...centeredCellStyles}>Status</TableCell>
              <TableCell {...centeredCellStyles} width={100}>
                De/Re
                <br />
                activate
              </TableCell>
              <TableCell {...centeredCellStyles} width={80}>
                Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: ResponseDataType) => {
              const selectedProjects = item.consultantProjects.map(
                ({ project_name, company_name }) =>
                  `${company_name} - ${project_name}`
              );
              const consultantName = `${item.firstName} ${item.lastName}`;
              const editCell =
                item.deletedAt && searchFormFilters?.show === ALL ? (
                  <TableCell />
                ) : (
                  <TableCellEdit
                    status={!item.deletedAt ? "Active" : "Inactive"}
                    item={item}
                    baseUrl={baseUrl}
                    padding="checkbox"
                    align="center"
                  />
                );
              return (
                <TableRow key={item.id}>
                  <TableCell>{consultantName}</TableCell>
                  <TableCell padding="checkbox" align="center">
                    {item.email}
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    align="center"
                    style={{ maxWidth: 300 }}
                  >
                    <ProjectsCell projects={selectedProjects} />
                  </TableCell>
                  <TableCell padding="checkbox" align="center">
                    {item.phone}
                  </TableCell>
                  <TableCell padding="checkbox" align="center">
                    {item?.createdAt && formatDateWithTime(item.createdAt)}
                  </TableCell>
                  <TableCellResetPassword
                    onReset={() => postEmail(item.email, "")}
                    modalMessage="You want to reset password?"
                    width={150}
                    padding="checkbox"
                    align="center"
                  />
                  <TableCellResetPassword
                    onReset={() => resetAction2FA(item.id)}
                    modalMessage="You want to reset 2FA?"
                    width={120}
                    padding="checkbox"
                    align="center"
                  >
                    Reset 2FA
                  </TableCellResetPassword>
                  {editCell}
                  <TableCell padding="checkbox" align="center">
                    {!item.deletedAt ? "Active" : "Inactive"}
                  </TableCell>
                  <TableCellArchive
                    onArchive={() =>
                      archiveConsultant(item, searchFormFilters?.show)
                    }
                    onUnarchive={() =>
                      unArchiveConsultant(item, searchFormFilters?.show)
                    }
                    item={item}
                    padding="checkbox"
                    align="center"
                  />
                  <TableCellDelete
                    onDelete={() => deleteConsultant(item.id, consultantName)}
                    item={item}
                    padding="checkbox"
                    align="center"
                  />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={totalItems}
          rowsPerPage={itemsPerPage}
          page={pageNumber - 1}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

function mapStateToProps(state) {
  return {
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
    searchFormFilters: searchFormSelector(
      state,
      "nameEmailPhone",
      "consultantProject",
      "dateFrom",
      "dateTo",
      "show"
    )
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      postEmail,
      loadItems: getConsultantsRequestsAction,
      setItemsPerPage: consultantsLoader.setItemsPerPage,
      setPageNumber: consultantsLoader.setPageNumber,
      deleteConsultant: deleteConsultantAction,
      archiveConsultant: archiveConsultantAction,
      unArchiveConsultant: unArchiveConsultantAction,
      resetAction2FA: resetAction2FA
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantsList);
