import * as React from "react";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import { projectRequest, projectLoader } from "../../../../actions/project";
import selector from "../../../../selectors/project";
import { TablePagination } from "@material-ui/core";
import TableCellEdit from "../../../../components/table/TableCellEdit";
import { isChangesExist } from "../../../../helpers/props-checker";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import { IProject } from "../../../../models/project.interface";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import debounce from "debounce";
import PermissionBlock from "../../../../components/auth/PermissionBlock";
import { Permission } from "../../../../constants/permission";
import { getUserPermissions } from "../../../../selectors/auth";
import TableHead from "@material-ui/core/TableHead";

class ProjectList extends React.Component<
  {
    baseUrl: string;
    data: IProject[];
    [key: string]: any;
  },
  {}
> {
  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  loadItems = props => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.filterText
    );
  };

  onArchive = item => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        ["pageNumber", "itemsPerPage", "showStatus", "filterText"],
        nextProps,
        this.props
      )
    ) {
      if (isChangesExist(["filterText"], nextProps, this.props)) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  render() {
    if (!this.props.isLoaded) {
      return <div className="styled-block table-no-items">Loading...</div>;
    }

    if (!this.props.data.length) {
      return (
        <div className="styled-block table-no-items">There are no items!</div>
      );
    }

    const canEdit =
      this.props.userPermissions.indexOf(
        Permission.CAN_MANAGE_PROJECT as Permission
      ) !== -1;

    return (
      <div className="styled-block">
        <h4 className="block-header mb-4">Projects</h4>
        <div className="table-responsive styled-table">
          <Table>
            <PermissionBlock permissions={[Permission.CAN_VIEW_PROJECT]}>
              <TableHead>
                <TableRow>
                  <TableCell>Project</TableCell>
                  <TableCell>Locations</TableCell>
                  <TableCell align="center">Manager</TableCell>
                  <TableCell align="center">
                    Purchase
                    <br />
                    Order Number
                  </TableCell>
                  <TableCell align="center">
                    Expenses Purchase
                    <br />
                    Order Number
                  </TableCell>
                  <PermissionBlock
                    permissions={[
                      Permission.CAN_MANAGE_PROJECT,
                      Permission.CAN_CREATE_EDIT_PROJECT
                    ]}
                  >
                    <TableCell>Edit</TableCell>
                  </PermissionBlock>
                </TableRow>
              </TableHead>
            </PermissionBlock>
            <TableBody>
              {this.props.data.map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell
                      style={{ wordBreak: "break-word", minWidth: 150 }}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell className="align-top" style={{ minWidth: 180 }}>
                      {item.locations.map((location, index) => (
                        <div key={index} className="project-location-item">
                          {location.name}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ wordBreak: "break-word", minWidth: 150 }}
                    >
                      {item.managerInfo ? item.managerInfo.name : ""}
                    </TableCell>
                    <TableCell
                      className="w-25"
                      align="center"
                      style={{ wordBreak: "break-word", minWidth: 150 }}
                    >
                      {item.purchaseOrderNumber ?? ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ wordBreak: "break-word", minWidth: 150 }}
                    >
                      {item.expensesPurchaseOrderNumber ?? ""}
                    </TableCell>
                    <PermissionBlock
                      permissions={[
                        Permission.CAN_MANAGE_PROJECT,
                        Permission.CAN_CREATE_EDIT_PROJECT
                      ]}
                    >
                      <TableCellEdit
                        status={this.props.showStatus}
                        item={item}
                        baseUrl={this.props.baseUrl}
                      />
                    </PermissionBlock>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps>(
  state => {
    return {
      data: selector.getItems(state),
      isLoaded: selector.getIsLoaded(state),
      itemsPerPage: selector.getItemsPerPage(state),
      pageNumber: selector.getPageNumber(state),
      showStatus: selector.getFilterActivityStatus(state),
      totalItems: selector.getTotalItems(state),
      filterText: selector.getFilterTextFields(state),
      userPermissions: getUserPermissions(state)
    };
  },
  {
    loadItems: projectRequest.getItems,
    setItemsPerPage: projectLoader.setItemsPerPage,
    setPageNumber: projectLoader.setPageNumber,
    archiveItem: projectRequest.archiveItem
  }
)(ProjectList);
