import * as Types from '../constants/action-type';
import {createLoaderAction} from '../helpers/action-helper';
import {DOCUMENT_SEARCH_APPLY} from "../constants/action-type";
import {createAction} from "redux-actions";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {postDocument, getSoWDocumentIdRequest} from "../api/requests/document";
import {setRequestInProcess} from "./request";
import {openSnackbar} from "./snackbar";
import {MAX_FILE_SIZE} from "../constants/document";

export const documentSearchAction = createAction(DOCUMENT_SEARCH_APPLY);

export const testerDocumentArchive = (testId: number, id: string) => {
  return {
    type: Types.DOCUMENT_ARCHIVE,
    payload: {
      testId,
      id,
    },
  };
};

export const documentsLoader = createLoaderAction(Types.DOCUMENT);

export const postDocumentItem = (file: File) => async dispatch => {
  if (file.size > MAX_FILE_SIZE) {
    dispatch(openSnackbar('File is too big', 4000));
    return;
  }

  dispatch(setRequestInProcess(true, 'postDocument'));

  let data;

  try {
    data = await postDocument(file);

    return data;
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'postDocument'));
};

export const getSoWDocumentIdAction = (testId) => async dispatch => {
  dispatch(setRequestInProcess(true, 'getDocumentId'));

  try {
    const data = await getSoWDocumentIdRequest(testId);

    return data.body;
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
  dispatch(setRequestInProcess(true, 'getDocumentId'));
}