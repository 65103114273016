import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./Header";
import { getAuth, getRoles } from "../../selectors/auth";
import {
  contactsModalOpen,
  loginModalOpen,
  logOut,
  resetPassword,
  showDrawer
} from "../../actions";
import { getIsDrawerOpen } from "../../selectors/drawer";
import selector from "../../selectors/carousel";
import { carouselRequest } from "../../actions/carousel";
import {
  ROLE,
  ROLE_ADMIN,
  ROLE_OWNER,
  ROLE_PLANNER, ROLE_PROJECT_CONSULTANT,
  ROLE_TESTER,
  ROLE_VIEWER
} from "../../constants/roles";
import Footer from "./Footer";

interface IHomePage {
  auth: boolean;
  loginModalOpen: () => { type: string };
  roles: ROLE[];
}

const Home = (props: IHomePage) => {
  const { auth, loginModalOpen, roles } = props;

  const getLinkByRole = (): string => {
    switch (true) {
      case roles.includes(ROLE_ADMIN):
        return "/admin";
      case roles.includes(ROLE_TESTER):
        return "/tester";
      case roles.includes(ROLE_OWNER) || roles.includes(ROLE_PLANNER):
        return "/client/dashboard";
      case roles.includes(ROLE_VIEWER):
        return "/client/tests-and-projects/tests";
      case roles.includes(ROLE_PROJECT_CONSULTANT):
        return "/consultant";
      default:
        return "/";
    }
  };

  return (
    <div className="home-page">
      <Header />

      <div className="hero-block">
        <div className="hero-block-content">
          <h1>People, Process & Technology solutions</h1>
          {auth ? (
            <div className="homepage-main-btns-group">
              <Link to={getLinkByRole()} className="log-in-button">
                Control Panel
              </Link>
            </div>
          ) : (
            <div className="homepage-main-btns-group">
              <Link to="#" className="log-in-button" onClick={loginModalOpen}>
                Login
              </Link>
            </div>
          )}
        </div>
      </div>

      <Footer hasDigiCert />
    </div>
  );
};

const mapStateToProps = state => ({
  backgroundImages: selector.getItems(state),
  isLoaded: selector.getIsLoaded(state),
  isDrawerOpen: getIsDrawerOpen(state),
  auth: getAuth(state),
  roles: getRoles(state)
});

const mapDispatchToProps = {
  contactsModalOpen,
  loginModalOpen,
  showDrawer,
  resetPassword,
  logOut,
  loadItems: carouselRequest.getItems
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
