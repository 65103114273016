import * as Types from '../constants/action-type';
import {
  createFilterAction,
  createLoaderAction,
  createRequestAction
} from '../helpers/action-helper';
import {getItems} from "../api/requests/projects";
import * as FilterActions from "../constants/filter";
import {setRequestInProcess} from "./request";
import {handleHttpErrors} from "../helpers/errors/http-error-handler";

export const projectFilter = createFilterAction(Types.PROJECT);
export const projectLoader = createLoaderAction(Types.PROJECT);
export const projectRequest = createRequestAction(Types.PROJECT);


export const getAllProjects = () => async dispatch => {
  dispatch(setRequestInProcess(true, 'getAllProjects'));

  dispatch(projectLoader.setIsNotLoaded());

  try {
   const {items, totalItems} = await getItems(1, 999, FilterActions.DEFAULT_FILTER);
    dispatch(projectLoader.setPaginatioData(items, totalItems, totalItems, 1));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, 'getAllProjects'));
};
