import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  composeValidators,
  fieldIsEmail,
  fieldsNotEmpty
} from "../../../../helpers/validations/validation";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";
import routerModal from "../../../../components/routerModal";
import ConsultantForm from "./ConsultantForm";
import { getBackLink } from "../../../../helpers/router-helper";
import { projectsRepeaterValidation } from "../../../../helpers/validations/validation";
import { bindActionCreators } from "redux";
import {
  createNewConsultantAction,
  getConsultantsRequestsAction,
  updateConsultantAction
} from "../../../../actions/consultants";
import {
  FilterParamsType,
  FormValuesType,
  GoBackHandlerType,
  SearchFormType
} from "../../../../types/consultant";
import { TableNoItems } from "../../../../components/table/TableNoItems";
import { getConsultantData } from "../../../../api/requests/consultants";
import {
  CONSULTANT_FORM_NAME,
  CONSULTANT_SEARCH_FORM
} from "../../../../constants/consultant";
import selector from "../../../../selectors/consultants";

interface ConsultantCreateProps extends RouteComponentProps<{ id?: string }> {
  handleSubmit: () => void;
  createNewConsultant: (
    values: FormValuesType,
    goBackHandler: GoBackHandlerType
  ) => void;
  updateConsultant: (
    values: FormValuesType,
    goBackHandler: GoBackHandlerType
  ) => void;
  itemsPerPage: number;
  pageNumber: number;
  searchFormFilters: SearchFormType;
  loadItems: (params: FilterParamsType) => void;
}
const searchFormSelector = formValueSelector(CONSULTANT_SEARCH_FORM);

const ConsultantCreate: React.FC<ConsultantCreateProps &
  InjectedFormProps<FormValuesType>> = ({
  handleSubmit,
  match,
  history,
  valid,
  submitting,
  change,
  createNewConsultant,
  updateConsultant,
  itemsPerPage,
  pageNumber,
  searchFormFilters,
  loadItems
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const backLink = getBackLink(match.path);
  const goBackHandler: GoBackHandlerType = () => {
    loadItems({ itemsPerPage, pageNumber, ...searchFormFilters });
    history.push(backLink);
  };
  const consultantId = match.params?.id;
  const isDisabledSubmit = !valid || submitting;
  const formSubmitHandler = consultantId
    ? updateConsultant
    : createNewConsultant;

  useEffect(() => {
    if (consultantId) {
      getConsultantData(consultantId)
        .then(data => {
          data &&
            Object.entries(data).forEach(([key, value]) => {
              change(key, value);
            });
          setIsLoaded(true);
        })
        .catch(error => {
          console.warn(error);
          goBackHandler();
        });
    } else {
      setIsLoaded(true);
    }
  }, [consultantId]);

  return (
    <div className="">
      {isLoaded ? (
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(values => {
            formSubmitHandler(values as FormValuesType, goBackHandler);
          })}
        >
          <ConsultantForm
            goBack={goBackHandler}
            isDisabled={isDisabledSubmit}
          />
        </form>
      ) : (
        <TableNoItems asLoading />
      )}
    </div>
  );
};

const formConnected = reduxForm<FormValuesType, ConsultantCreateProps>({
  form: CONSULTANT_FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      "firstName",
      "lastName",
      "email",
      "phone",
      "projects",
      "consultantRate"
    ]),
    fieldIsEmail(["email"]),
    projectsRepeaterValidation()
  )
})(ConsultantCreate);

function mapStateToProps(state) {
  return {
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    searchFormFilters: searchFormSelector(
      state,
      "nameEmailPhone",
      "consultantProject",
      "dateFrom",
      "dateTo",
      "show"
    )
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createNewConsultant: createNewConsultantAction,
      updateConsultant: updateConsultantAction,
      loadItems: getConsultantsRequestsAction
    },
    dispatch
  );
};
const connected = connect(mapStateToProps, mapDispatchToProps)(formConnected);

export default routerModal(withRouter(connected));
