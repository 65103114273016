import {getApi, getApiJSONLD} from '../swagger/api-factory';
import {convertorFromSwagger} from "../convertors/default-convertor";
import {converterFromSwagger} from "../convertors/test-documents";
import {extractJSONLD, fetchRequestBody} from '../../helpers/request-helper';

export const postDocument = (file: File) => {
  return getApi()
    .then(api => {
      return api.postDocumentCollection({file});
    })
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const getItem = (id) => {
  return getApi()
    .then(api => api.getDocumentItem({ id }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const documentSearchRequest = (
  id: string | number,
  pageNumber: number,
  itemsPerPage: number,
) => {
  return getApiJSONLD()
    .then(api => api.api_tests_test_documents_get_subresource({
      id,
      $queryParameters: {
        page: pageNumber,
        items: itemsPerPage,
      },
    }))
    .then(extractJSONLD(converterFromSwagger));
};

export const deleteItem = (id: any) => {
  return getApi()
    .then(api => api.deleteDocumentItem({ id, }))
    .then(fetchRequestBody);
};

export const getSoWDocumentIdRequest = (id: any) => {
    return getApi().then(api => api.getDocumentId({id}));
}
