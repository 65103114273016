import React from "react";
import { Field, FieldArray } from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import { Icon, IconButton, Zoom } from "@material-ui/core";
import { handleSpaceKeyPress } from "../../../../helpers/validations/emptyTestFieldValidation";
import {
  handleKeyPressOnNumberField,
  handleKeyPressOnPhoneField
} from "../../../../helpers/validations/numberFieldValidator";
import { AddRepeaterFieldButton } from "./AddRepeaterFieldButton";
import ProjectsRepeater from "./ProjectsRepeater";
import Tooltip from "@material-ui/core/Tooltip";

type ConsultantFormProps = {
  goBack: () => void;
  isDisabled: boolean;
};

const ConsultantForm = (props: ConsultantFormProps) => {
  const { goBack, isDisabled } = props;
  return (
    <>
      <div className="row">
        <div className="col-10">
          <h4>Manage Consultant</h4>
        </div>
        <div className="col-2">
          <IconButton
            className="float-right"
            style={{ cursor: "pointer", margin: "-15px -15px 0 0" }}
            onClick={goBack}
          >
            <Icon>close</Icon>
          </IconButton>
        </div>
      </div>
      <hr style={{ margin: 0 }} />
      <div className="row">
        <div className="col-12">
          <Field
            name="firstName"
            label="First name *"
            component={ReduxTextField}
            margin="dense"
          />
          <Field
            name="lastName"
            label="Last Name *"
            component={ReduxTextField}
            margin="dense"
          />
          <Field
            name="email"
            label="Email *"
            component={ReduxTextField}
            margin="dense"
            onKeyPress={handleSpaceKeyPress}
          />
          <Field
            name="phone"
            label="Telephone *"
            type={`tel`}
            component={ReduxTextField}
            margin="dense"
            onKeyPress={handleKeyPressOnPhoneField}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <Field
            name="consultantRate"
            label="£ Consultant Day Rate *"
            component={ReduxTextField}
            margin="dense"
            normalize={(value: string) => (value ? +value : "")}
            onKeyPress={handleKeyPressOnNumberField}
            inputProps={{ min: 0 }}
            type="number"
          />
        </div>
        <div className="col-12">
          <h6 className="mt-3 mb-0 d-flex">
            Projects
            <span className="ml-2">
              <Tooltip
                placement="top-start"
                TransitionComponent={Zoom}
                disableFocusListener
                disableTouchListener
                title="If project is not listed, contact company manager."
              >
                <Icon style={{ fontSize: 20 }}>info</Icon>
              </Tooltip>
            </span>
          </h6>
        </div>
        <div className="col-12 pt-2">
          <FieldArray
            name="projects"
            component={ProjectsRepeater}
            rerenderOnEveryChange
          />
          <FieldArray name="projects" component={AddRepeaterFieldButton} />
        </div>
        <div className="col-12">
          <hr />
        </div>
        <div className="col-12 justify-content-end d-flex pt-2">
          <button className="btn-cancel" type="button" onClick={goBack}>
            Cancel
          </button>
          <button className="btn-save" type="submit" disabled={isDisabled}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ConsultantForm;
