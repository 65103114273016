import * as React from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { History } from "history";
import userSelectors from "../../../selectors/users";
import { getUser } from "../../../actions/users";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { IUsers } from "../../../models/users.interface";
import { IState } from "../../../interfaces/state.interface";

type IProps = {
  getUser: (id: number) => void;
  user: IUsers;
  history: History;
  backRoute: string;
  currentUser: {
    companyDetails: {
      companyName: string;
      sector: string;
      invoiceDetails: {
        [key: string]: string;
      };
      owner: {
        phone: string;
        email: string;
      };
    };
  };
} & RouteComponentProps<{ id: string }>;

class ClientDetails extends React.Component<IProps> {
  componentDidMount() {
    this.props.getUser(+this.props.match.params.id);
  }

  backToClientsList = (): void => {
    const { history, backRoute } = this.props;

    history.push(backRoute);
  };

  render() {
    const { currentUser, match } = this.props;
    if (!currentUser.companyDetails) {
      return (
        <div className="mt-4">
          <div className="crud-sections-header">
            <h4>Company details</h4>
            <p>Loading...</p>
          </div>
        </div>
      );
    }

    return (
      <div className="mt-4">
        <div className="crud-sections-header justify-content-between">
          <h3>Company Details</h3>
          <div>
            <Link
              to={`/admin/clients/details/${match.params.id}/edit`}
              className="btn-edit mr-2"
            >
              Edit
            </Link>
            <Button
              variant="contained"
              color="default"
              onClick={this.backToClientsList}
            >
              Back to clients list
            </Button>
          </div>
        </div>
        <hr />
        <h5 className="mt-4">Owner's Account</h5>
        <hr />
        <div className="row">
          <div className="col-3">Email</div>
          <div className="col-3">{currentUser.companyDetails.owner?.email}</div>
        </div>
        <div className="row">
          <div className="col-3">Telephone</div>
          <div className="col-3">{currentUser.companyDetails.owner?.phone}</div>
        </div>
        <h5 className="mt-4">Company</h5>
        <hr />
        <div className="row">
          <div className="col-3">Company name</div>
          <div className="col-3">{currentUser.companyDetails.companyName}</div>
        </div>
        <div className="row">
          <div className="col-3">Sector</div>
          <div className="col-3">{currentUser.companyDetails.sector}</div>
        </div>

        <h5 className="mt-4">Invoice Contact</h5>
        <hr />
        <div className="row">
          <div className="col-3">Name</div>
          <div className="col-3">
            {currentUser.companyDetails?.invoiceDetails?.name}
          </div>
        </div>
        <div className="row">
          <div className="col-3">Email</div>
          <div className="col-3">
            {currentUser.companyDetails?.invoiceDetails?.email}
          </div>
        </div>
        <div className="row">
          <div className="col-3">Telephone</div>
          <div className="col-3">
            {currentUser.companyDetails?.invoiceDetails?.telephone}
          </div>
        </div>

        <h5 className="mt-4">Invoice Address</h5>
        <hr />
        <div className="row">
          <div className="col-3">Address line 1</div>
          <div className="col-3">
            {currentUser.companyDetails?.invoiceDetails?.addressLine1}
          </div>
        </div>
        <div className="row">
          <div className="col-3">Address line 2</div>
          <div className="col-3">
            {currentUser.companyDetails?.invoiceDetails?.addressLine2}
          </div>
        </div>
        <div className="row">
          <div className="col-3">Address line 3</div>
          <div className="col-3">
            {currentUser.companyDetails?.invoiceDetails?.addressLine3}
          </div>
        </div>
        <div className="row">
          <div className="col-3">Town/City</div>
          <div className="col-3">
            {currentUser.companyDetails?.invoiceDetails?.town}
          </div>
        </div>
        <div className="row">
          <div className="col-3">State/Provice</div>
          <div className="col-3">
            {currentUser.companyDetails?.invoiceDetails?.state}
          </div>
        </div>
        <div className="row">
          <div className="col-3">Post/zip code</div>
          <div className="col-3">
            {currentUser.companyDetails?.invoiceDetails?.postCode}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState, ownProps: IProps) {
  return {
    currentUser: state.currentUser,
    user: userSelectors.getItemById(ownProps.match.params.id)(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUser: bindActionCreators(getUser, dispatch),
  };
}

const connected = connect(mapStateToProps, mapDispatchToProps)(ClientDetails);

const routered = withRouter(connected) as React.ComponentClass<{
  backRoute: string;
}>;

export default routered;
