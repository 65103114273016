import React, { useContext } from "react";
import classnames from "classnames";
import Paper from "@material-ui/core/Paper/Paper";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { TextLinkCell } from "./TableCells/TextLinkCell";
import {
  getConsultantWeekIsActive,
  getTotalDaysAndExpenses,
  getWeekTitle
} from "helpers/timesheets-expenses";
import { DayCell } from "./TableCells/DayCell";
import TotalCell from "./TableCells/TotalCell";
import StatusCell from "./TableCells/StatusCell";
import { NotesCell } from "./TableCells/NotesCell";
import {
  TimeExpensesWeekType,
  TimeSheetExpensesContextValueType,
  TimesheetPoNumberRoute,
  TimeSheetWeekProps
} from "types/timesheets-and-expenses";
import { TimeSheetExpensesContext } from "../context/timeSheetsContext";
import { getWeekDates } from "helpers/date-formatter";
import { TE_STATUS } from "constants/timesheet-expenses";
import WeekHeadingCheckbox from "./TableCells/WeekHeadingCheckbox";
import WeekProjectCheckbox from "./TableCells/WeekProjectCheckbox";
import PoNumberCell from "./TableCells/PoNumberCell";

const TIME_SHEETS_COMPANY_BASE_URL = "/admin/clients/details";
const TIME_SHEETS_CLIENT_BASE_URL = "/admin/clients";
const TIME_SHEETS_PROJECT_BASE_URL = "/client/tests-and-projects/projects";
const TIME_SHEETS_CONSULTANT_BASE_URL = "/admin/consultants";

export const TimeSheetWeek = (props: TimeSheetWeekProps) => {
  const {
    selectedProjectWeeks,
    week: { yearWeek, projectsWeeks },
  } = props;

  const year = yearWeek.substring(0, 4);
  const weekNumber = yearWeek.substring(4);
  const weekTitle = getWeekTitle(+year, +weekNumber);
  const weekDaysHead = getWeekDates(+year, +weekNumber, "ddd DD/MMM");
  const { isAdmin, isConsultant, isOwner, userType, isPlanner } = useContext<
    TimeSheetExpensesContextValueType
  >(TimeSheetExpensesContext);
  const isActiveWeek = getConsultantWeekIsActive(yearWeek);
  const isConsultantActiveWeek: boolean = isConsultant && isActiveWeek;
  const isClientActiveWeek: boolean = (isOwner || isPlanner) && getConsultantWeekIsActive(yearWeek, 2);

  const isAdminActiveWeek: boolean =
    isAdmin &&
    Array.isArray(projectsWeeks) &&
    !!projectsWeeks.filter(week => week.status === TE_STATUS.STATUS_APPROVED)
      .length;
  const shouldRenderCheckbox =
    isConsultantActiveWeek || isClientActiveWeek || isAdminActiveWeek;

  return (
    <div className="mb-4 pb-4">
      <h4 className="timesheet-week-heading">{weekTitle}</h4>

      <Paper style={{ overflowX: "auto", width: "100%" }}>
        <Table className="timesheet-table">
          <TableHead>
            <TableRow>
              {shouldRenderCheckbox && (
                <WeekHeadingCheckbox
                  projectsWeeks={projectsWeeks}
                  userType={userType}
                />
              )}
              {isAdmin && <TableCell>Client</TableCell>}
              <TableCell>Project</TableCell>
              <TableCell>Manager</TableCell>
              {!isConsultant && <TableCell>Consultant</TableCell>}
              {weekDaysHead.map((date: string, index: number) => {
                const dayClassNames = classnames("timesheet-cell-week-day", {
                  weekend: index > 4
                });
                return (
                  <TableCell className={dayClassNames} key={date}>
                    {date}
                  </TableCell>
                );
              })}
              <TableCell
                className="timesheet-cell-total"
                style={{ minWidth: 75 }}
              >
                Total
              </TableCell>
              <TableCell align="center" style={{ minWidth: 120 }}>
                <Tooltip
                  title={
                    <span style={{ fontSize: 14 }}>Purchase Order Number</span>
                  }
                  placement={"top-start"}
                >
                  <span>PO Number</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" style={{ minWidth: 120 }}>
                <Tooltip
                  title={
                    <span style={{ fontSize: 14 }}>
                      Expenses Purchase Order Number
                    </span>
                  }
                  placement={"top-start"}
                >
                  <span>Expenses PO</span>
                </Tooltip>
              </TableCell>
              <TableCell align="center" width={85}>
                Status
              </TableCell>
              <TableCell className="timesheet-cell-notes">Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectsWeeks.map((projectWeek: TimeExpensesWeekType) => {
              const {
                totalDays,
                totalExpensesAmount
              } = getTotalDaysAndExpenses(projectWeek.days);
              const isEmptyTimeAndExpenses = !totalDays && !totalExpensesAmount;
              const isSelectedRow =
                !!projectWeek?.id &&
                selectedProjectWeeks.includes(projectWeek.id);
              return (
                <TableRow
                  key={projectWeek.yearWeek}
                  className={isSelectedRow ? "timesheet-row-selected" : ""}
                >
                  {shouldRenderCheckbox && (
                    <WeekProjectCheckbox
                      projectWeekId={projectWeek?.id}
                      status={projectWeek?.status}
                      totalDays={totalDays}
                      totalExpensesAmount={totalExpensesAmount}
                      userType={userType}
                    />
                  )}
                  {isAdmin && (
                    <TableCell>
                      <TextLinkCell
                        id={projectWeek.companyOwnerId as number}
                        name={projectWeek.companyName}
                        asLink
                        baseUrl={TIME_SHEETS_COMPANY_BASE_URL}
                      />
                    </TableCell>
                  )}

                  <TableCell>
                    <TextLinkCell
                      id={projectWeek.project}
                      name={projectWeek.projectName}
                      asLink={isOwner}
                      baseUrl={TIME_SHEETS_PROJECT_BASE_URL}
                    />
                  </TableCell>
                  <TableCell>
                    <TextLinkCell
                      id={projectWeek.managerInfo?.user_id || 0}
                      name={projectWeek.managerInfo?.name}
                      asLink={isAdmin}
                      baseUrl={TIME_SHEETS_CLIENT_BASE_URL}
                    />
                  </TableCell>
                  {!isConsultant && (
                    <TableCell>
                      <TextLinkCell
                        id={projectWeek.consultantUserId}
                        name={projectWeek.consultantName}
                        asLink={isAdmin}
                        baseUrl={TIME_SHEETS_CONSULTANT_BASE_URL}
                      />
                    </TableCell>
                  )}
                  {projectWeek.days.map(weekday => {
                    return (
                      <TableCell
                        className="timesheet-cell-week-day"
                        key={weekday.date}
                      >
                        <DayCell
                          isActiveWeek={isConsultantActiveWeek}
                          {...weekday}
                          status={projectWeek?.status}
                        />
                      </TableCell>
                    );
                  })}
                  <TableCell className="timesheet-cell-total">
                    <TotalCell
                      isActiveWeek={isConsultantActiveWeek}
                      totalExpensesAmount={totalExpensesAmount}
                      totalDays={totalDays}
                      weekId={`${projectWeek.yearWeek}`}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="timesheet-cell-po-number"
                  >
                    <PoNumberCell
                      statusId={projectWeek.status}
                      weekId={projectWeek.id as number}
                      type={TimesheetPoNumberRoute.PO_NUMBER}
                      poNumberValue={projectWeek.purchaseOrderNumber}
                      hasTotalDays={!!totalDays}
                      hasTotalExpenses={!!totalExpensesAmount}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="timesheet-cell-po-number"
                  >
                    <PoNumberCell
                      statusId={projectWeek.status}
                      weekId={projectWeek.id as number}
                      type={TimesheetPoNumberRoute.EXPENSES_PO_NUMBER}
                      poNumberValue={projectWeek.expensesPurchaseOrderNumber}
                      hasTotalDays={!!totalDays}
                      hasTotalExpenses={!!totalExpensesAmount}
                    />
                  </TableCell>
                  <TableCell align="center" width={70}>
                    <StatusCell
                      statusId={projectWeek.status}
                      weekId={projectWeek?.id}
                      weekNumber={weekNumber}
                      consultantName={projectWeek.consultantName}
                      isEmptyTimeAndExpenses={isEmptyTimeAndExpenses}
                      invoiceNumber={projectWeek.invoiceNumber}
                      expensesInvoiceNumber={projectWeek.expensesInvoiceNumber}
                    />
                  </TableCell>
                  <TableCell className="timesheet-cell-notes">
                    <NotesCell notes={[...projectWeek.notesThread].reverse()} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};
