import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import { History } from "history";
import { Button } from "@material-ui/core";
import { AdminUrls } from "../../../../constants/admin-urls";
import PermissionBlock from "../../../../components/auth/PermissionBlock";
import { adminValidateAction } from "../../../../actions";
import AdminValidationButton from "./AdminValidationButton";
import { ROLE_ADMIN } from "../../../../constants/roles";

interface IAdminBackToTestProps {
  history: History;
  showValidateButton?: boolean;
  testId: number;
}

const AdminBackToTest = ({
  history,
  showValidateButton,
  testId,
}: IAdminBackToTestProps) => {
  const backToTests = (): void => {
    history.push(AdminUrls.TESTS);
  };

  return (
    <PermissionBlock roles={[ROLE_ADMIN]}>
      {showValidateButton && <AdminValidationButton testId={testId} />}
      <Button
        className="float-right mb-2"
        variant="contained"
        color="default"
        onClick={backToTests}
      >
        Back to tests
      </Button>
    </PermissionBlock>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      adminValidate: adminValidateAction,
    },
    dispatch
  );
};

export default connect(
  undefined,
  mapDispatchToProps
)(withRouter(AdminBackToTest as any)) as any;