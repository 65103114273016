import React from "react";
import { getCreateLink } from "../../../../helpers/router-helper";
import { Link, withRouter } from "react-router-dom";
import LocationsList from "./LocationsList";
import { Route, Switch } from "react-router-dom";
import LocationsCreate from "./LocationsCreate";
import LocationsUpdate from "./LocationsUpdate";
import LocationsSearchForm from "./LocationsSearchForm";
import { getUserGroup, getUserPermissions } from "selectors/auth";
import { connect } from "react-redux";
import { Permission } from "constants/permission";
import { Group } from "constants/group";

class Locations extends React.Component {
  render() {
    const baseUrl = this.props.match.path;
    const { currentUserPermissions, group } = this.props;

    return (
      <div>
        {(group === Group.OWNER ||
          currentUserPermissions.includes(
            Permission.CAN_CREATE_EDIT_VIEW_LOCATIONS
          )) && (
          <div className="crud-sections-header">
            <Link to={getCreateLink(baseUrl)} className="btn-new">
              New Location
            </Link>
          </div>
        )}
        <Switch>
          <Route
            path={getCreateLink(baseUrl)}
            render={() => <LocationsCreate backRoute={baseUrl} />}
          />
          <Route
            path={baseUrl + "/:id"}
            render={() => <LocationsUpdate backRoute={baseUrl} />}
          />
        </Switch>
        <LocationsSearchForm />
        <LocationsList baseUrl={baseUrl} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUserPermissions: getUserPermissions(state),
    group: getUserGroup(state),
  };
}

export default connect(mapStateToProps, {})(withRouter(Locations));
