import moment from "moment";
import { getYearAndWeekNumber } from "./timesheets-expenses";
import { convertMonthNameToNumber } from "../components/forms/YearMonthPicker/helpers";

export function formatDateWithSlashesDdMmYyyy(date: string) {
  return moment(date).format("DD/MM/YYYY");
}

export function formatDateWithTime(date: string) {
  return moment(new Date(date)).format("DD/MM/YYYY h:mm a");
}

export function getWeekDates(
  year: number,
  weekNumber: number,
  format = "YYYY-MM-DD"
): string[] {
  const startDate = moment()
    .year(year)
    .week(weekNumber)
    .startOf("isoWeek");
  const weekDates: string[] = [];

  for (let i = 0; i < 7; i++) {
    const currentDate = startDate.clone().add(i, "days");
    weekDates.push(currentDate.format(format));
  }

  return weekDates;
}

export function getWeekStartAndEndDates(
  year: number,
  weekNumber: number,
  format = "MMMM D, YYYY"
): { startDate: string; endDate: string } {
  const startDate = moment()
    .year(year)
    .week(weekNumber)
    .startOf("isoWeek");
  const endDate = moment()
    .year(year)
    .week(weekNumber)
    .endOf("isoWeek");

  return {
    startDate: startDate.format(format),
    endDate: endDate.format(format)
  };
}

export const getIsCurrentWeek = (year: number, weekNumber: number): boolean => {
  const currentWeek = moment().week();
  const currentYear = moment().year();

  return currentYear === year && currentWeek === weekNumber;
};

export const getIsActiveDate = (date: string): boolean => {
  return moment(date).isSameOrBefore();
};

export const getTimeLogModalTitle = (date?: string): string => {
  if (!date && !moment(date).isValid()) {
    return "Time & Expenses";
  }

  const updatedFormat = moment(date).format("DD/MM/YYYY");
  return `Time & Expenses for ${updatedFormat}`;
};

export const getYearWeek = (date: string, format = "YYYY-MM-DD") =>
  moment(date, format).format("YYYYWW");
export const getCurrentYearWeek = () => moment().format("YYYYWW");
export const getYearWeekOfPreviousWeek = () =>
  moment()
    .subtract(1, "weeks")
    .format("YYYYWW");

export const getCurrentYearMonth = () => {
  return moment().format("YYYY-MM");
};

export const getIsWeekOfActiveSearchMonth = ({
  yearWeek,
  searchYearMonth
}: {
  yearWeek: string;
  searchYearMonth: string;
}): boolean => {
  const { year, weekNumber } = getYearAndWeekNumber(yearWeek);
  const [filterMonthName, filterYear] = searchYearMonth.split(" ");
  const filterMonth = convertMonthNameToNumber(filterMonthName);
  const startDate = moment(`${filterYear}-${filterMonth}-01`);
  const endDate = moment(startDate).endOf("month");
  const weeks: number[] = [];
  let currentDate = startDate.clone().startOf("isoWeek");

  while (currentDate.isSameOrBefore(endDate)) {
    const weekNumber = currentDate.isoWeek();
    weeks.push(weekNumber);
    currentDate.add(1, "week");
  }

  return year === +filterYear && weeks.includes(weekNumber);
};

export const getDeletedAtDate = () =>
  moment().format("YYYY-MM-DDTH:mm:s+00:00");

export const reformatTimesheetNoteDate = (date: string) => {
  const parsedDate = moment(date, "YYYY/MM/DD HH:mm");
  return parsedDate.format("DD/MM/YYYY h:mm a");
};
