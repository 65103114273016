import React, { useState, useEffect } from "react";
import { FieldGeneric } from "../../../../components/forms/FieldGeneric";
import ReduxDatePicker from "../../../../components/forms/ReduxDatePicker";
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import { Field, formValueSelector, reduxForm } from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import { connect } from "react-redux";
import ReduxRadioButton from "../../../../components/forms/ReduxRadioButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as FilterTypes from "../../../../constants/filter";
import Radio from "@material-ui/core/Radio";
import { ACTIVE } from "../../../../constants/filter";
import { CONSULTANT_SEARCH_FORM } from "../../../../constants/consultant";
import Paper from "@material-ui/core/Paper/Paper";
import ReduxSelectSearchField from "../../../../components/forms/ReduxSelectSearchField";
import {
  isOptionSelected,
  joinSelectedOptionsLabels
} from "../../../../helpers/timesheets-expenses";
import { SelectOptionType } from "../../../../types";
import { selectOptionsSort } from "../../../../helpers/consultant-create-form";
import { getAllAssignedProjects } from "../../../../api/requests/consultants";

const searchFormSelector = formValueSelector(CONSULTANT_SEARCH_FORM);

type ConsultantSearchFormProps = {
  projectsSelected?: Array<number>;
  reset: () => void;
};

const ConsultantSearchForm = (props: ConsultantSearchFormProps) => {
  const { projectsSelected = [], reset } = props;
  const [assignedProjects, setAssignedProjects] = useState<SelectOptionType[]>(
    []
  );
  useEffect(() => {
    getAllAssignedProjects().then(
      ({ items }: { items: SelectOptionType[] }) => {
        const projectsOptions = items.sort(selectOptionsSort);
        setAssignedProjects(projectsOptions);
      }
    );
  }, []);
  return (
    <form>
      <Paper className="table-responsive styled-table styled-block">
        <div className="row">
          <div className="col-lg-3">
            <Field
              name="nameEmailPhone"
              label="Search by Name/Email/Telephone"
              component={ReduxTextField}
              margin="normal"
            />
          </div>
          <div className="col-lg-3">
            <FieldGeneric
              name="consultantProject"
              label="Projects"
              fullWidth
              multiple
              component={ReduxSelectSearchField}
              items={assignedProjects}
              renderItem={option => (
                <MenuItem key={option.id} value={option.id}>
                  <Checkbox
                    checked={isOptionSelected(projectsSelected, option.id)}
                  />
                  <ListItemText primary={option.name} />
                </MenuItem>
              )}
              renderValue={() =>
                joinSelectedOptionsLabels({
                  selectedOptionsIds: projectsSelected,
                  selectOptions: assignedProjects
                })
              }
            />
          </div>
          <div className="col-lg-3">
            <FieldGeneric
              name="dateFrom"
              label="Created From DD/MM/YY"
              InputLabelProps={{
                shrink: true
              }}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-lg-3">
            <FieldGeneric
              name="dateTo"
              label="Created To DD/MM/YY"
              InputLabelProps={{ shrink: true }}
              addEndOfDay={true}
              component={ReduxDatePicker}
            />
          </div>
          <div className="col-lg-12">
            <div className="d-flex justify-content-end align-items-start pt-2">
              <FieldGeneric
                name="show"
                margin="normal"
                fullWidth
                inline={true}
                component={ReduxRadioButton}
              >
                <FormControlLabel
                  value={FilterTypes.ACTIVE}
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value={FilterTypes.ARCHIVED}
                  control={<Radio />}
                  label="Inactive"
                />
                <FormControlLabel
                  value={FilterTypes.ALL}
                  control={<Radio />}
                  label="Both"
                />
              </FieldGeneric>
              <button
                className="btn-refresh ml-0"
                style={{ marginTop: 10 }}
                type="button"
                onClick={reset}
              >
                Reset Filters
              </button>
            </div>
          </div>
        </div>
      </Paper>
    </form>
  );
};

function mapStateToProps(state) {
  return {
    projectsSelected: searchFormSelector(state, "consultantProject")
  };
}

const connectForm = reduxForm({
  form: CONSULTANT_SEARCH_FORM,
  initialValues: {
    consultantProject: [],
    show: ACTIVE
  }
})(ConsultantSearchForm);

export default connect(mapStateToProps)(connectForm);
