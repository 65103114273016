import * as React from 'react';
import {Field} from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import Grid from "@material-ui/core/Grid/Grid";
import {Button, Icon, IconButton} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ButtonProps} from "@material-ui/core/Button/Button";
import {IBackRoute} from "../../../../components/routerModal";
import {handleSpaceKeyPress} from "../../../../helpers/validations/emptyTestFieldValidation";

const AdminUsersForm: React.SFC<IBackRoute> = ({backRoute}) => {
  return (
    <Grid container spacing={40}>
      <Grid item xs={12}>
        <IconButton className="float-right"
               style={{ cursor: 'pointer', margin: '-15px -15px 0 0'}}
               component={Link as React.ReactType<ButtonProps>}
                    {...{to: backRoute}}>
          <Icon>close</Icon>
        </IconButton>
        <h3>
          User Details
        </h3>
        <hr/>
        <Field
          name="firstName"
          label="First name *"
          component={ReduxTextField}
          margin="normal"
          autoFocus
        />
        <Field
          name="lastName"
          label="Last Name *"
          component={ReduxTextField}
          margin="normal"
        />
        <Field
          name="email"
          label="Email *"
          component={ReduxTextField}
          margin="normal"
          onKeyPress={handleSpaceKeyPress}
        />
        <br/>
        <br/>
        <div className="d-flex justify-content-between mt-4">
          <Button variant="contained"
                  color="default"
                  component={Link as React.ReactType<ButtonProps>}
                  {...{to: backRoute}}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  )
};


export default AdminUsersForm;
