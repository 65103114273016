import * as React from 'react';
import PrivateRoute from './PrivateRoute';
import { ROLE_GUEST } from '../../constants/roles';

const GuestRoute: React.SFC<any> = (props) => {
  return (
    <PrivateRoute 
      role={ROLE_GUEST}
      redirectPath="/"
      {...props} />
  );
};

export default GuestRoute;
