import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import {
  testerBookingsLoader,
  getTestsRequestsAction
} from "../../../actions/tester-bookings";
import selector from "../../../selectors/tester-bookings";
import { TablePagination, Tooltip } from "@material-ui/core";
import { isChangesExist } from "../../../helpers/props-checker";
import { IAnyProps } from "../../../interfaces/any-props.interface";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import debounce from "debounce";
import { ITest } from "../../../models/test.interface";
import { getTestStatusName } from "../../../constants/test-status";
import { formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import { IGlobalState } from "../../../reducers";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import { formatDateWithTime } from "../../../helpers/date-formatter";
import { TESTS_SEARCH_FORM } from "./TestsSearchForm";
import { getCurrency } from "../../../helpers/currency";
import TesterTableCell from "../../common/TesterTableCell";
import "../styled.css";
import { TableNoItems } from "../../../components/table/TableNoItems";

interface IOwnProps {
  data: ITest[];
  setItemsPerPage: (page: string) => void;
  setPageNumber: (page: number) => void;
  isLoaded: boolean;
  totalItems: number;
  searchValues?: {
    status: string[];
    dateFrom: string;
    dateTo: string;
  };
}

interface IFilterValues {
  itemsPerPage: number;
  pageNumber: number;
  dateFrom?: string;
  dateTo?: string;
  questionAsked?: boolean | string;
  statuses?: string;
  name?: string;
  plannerName?: string;
  clientCompany?: number;
  testerName?: string;
  testingCompany?: number;
}

// TODO: IAnyProps should be remove in future

type IProps = IOwnProps & IFilterValues & IAnyProps;

const selectorSearchForm = formValueSelector(TESTS_SEARCH_FORM);

class TestsList extends React.Component<IProps> {
  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  loadItems = (props: IProps): void => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      "",
      "",
      props.dateFrom,
      props.dateTo,
      "",
      props.statuses,
      props.name,
      props.plannerName,
      props.clientCompany,
      props.testerName,
      props.testingCompany
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps: IProps) {
    if (
      isChangesExist(
        [
          "pageNumber",
          "itemsPerPage",
          "",
          "",
          "dateFrom",
          "dateTo",
          "",
          "statuses",
          "name",
          "plannerName",
          "clientCompany",
          "testerName",
          "testingCompany"
        ],
        nextProps,
        this.props
      )
    ) {
      if (
        isChangesExist(
          [
            "statuses",
            "name",
            "plannerName",
            "testerName",
            "dateFrom",
            "dateTo",
            "pageNumber",
            "itemsPerPage",
            "testingCompany",
            "clientCompany"
          ],
          nextProps,
          this.props
        )
      ) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }
  }

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement>,
    page: number
  ): void => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.props.setItemsPerPage(event.target.value);
  };

  getReportAction = (item: ITest) => {
    return (
      <Link to={`/client/reports/${item.id}/details/`}>
        <button type="submit" className="btn-view">
          View&nbsp;Details
        </button>
      </Link>
    );
  };

  columnsNames = () => {
    return [
      "Start",
      "PO Number",
      "Cost",
      "Client Company",
      "Planner",
      "Consultant/Tester",
      "Status",
      "Action"
    ];
  };

  render() {
    const { data, isLoaded } = this.props;
    if (!isLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }

    if (!data.length) {
      return <TableNoItems hasWrapper />;
    }

    return (
      <Paper className="paperWrap">
        <Table padding="dense">
          <EnhancedTableHead columns={this.columnsNames()} />
          <TableBody>
            {data.map((item: ITest) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <div>
                      <Link to={`/client/reports/${item.id}/details/`}>
                        {item.hash + " - " + item.name}
                      </Link>
                    </div>
                    {item.dateFrom && formatDateWithTime(item.dateFrom)}
                  </TableCell>
                  <TableCell>{item.purchaseOrderNumber}</TableCell>
                  <TableCell>
                    {item.proposal &&
                      item.proposal.currency &&
                      `${item.proposal.currency.symbol}${getCurrency(
                        item.proposal.fullTotalCostForClient
                      )}`}
                  </TableCell>
                  <TableCell>
                    <Link to={`/admin/clients/details/${item.plannerId}`}>
                      {item && item.plannerCompanyName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      placement={"bottom-end"}
                      disableFocusListener
                      disableTouchListener
                      title={item.plannerEmail}
                    >
                      <div>{item.plannerName}</div>
                    </Tooltip>
                  </TableCell>
                  {item.tester ? (
                    <TesterTableCell
                      testerEmail={item.testerEmail}
                      tester={item.tester}
                    />
                  ) : (
                    <TableCell />
                  )}
                  <TableCell>
                    {item.status && getTestStatusName(item.status)}
                  </TableCell>
                  <TableCell>{this.getReportAction(item)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={this.props.totalItems}
          rowsPerPage={this.props.itemsPerPage}
          page={this.props.pageNumber - 1}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps>(
  (state: IGlobalState) => {
    return {
      data: selector.getItems(state),
      isLoaded: selector.getIsLoaded(state),
      itemsPerPage: selector.getItemsPerPage(state),
      pageNumber: selector.getPageNumber(state),
      showStatus: selector.getFilterActivityStatus(state),
      totalItems: selector.getTotalItems(state),
      filterText: selector.getFilterTextFields(state),
      name: selectorSearchForm(state, "name") || "",
      statuses: selectorSearchForm(state, "statuses") || "",
      plannerName: selectorSearchForm(state, "plannerName") || "",
      testerName: selectorSearchForm(state, "testerName") || "",
      testingCompany: selectorSearchForm(state, "testingCompany") || "",
      clientCompany: selectorSearchForm(state, "clientCompany") || "",
      dateFrom: selectorSearchForm(state, "dateFrom") || "",
      dateTo: selectorSearchForm(state, "dateTo") || ""
    };
  },
  {
    loadItems: getTestsRequestsAction,
    setItemsPerPage: testerBookingsLoader.setItemsPerPage,
    setPageNumber: testerBookingsLoader.setPageNumber
  }
)(TestsList);
