import { extractId } from "../../helpers/converter-helper";
import {
  getCurrentYearMonth,
  getWeekDates,
  getYearWeek
} from "../../helpers/date-formatter";
import {
  createDayObject,
  getConsultantWeekIsActive,
  getDateFromDayParam
} from "../../helpers/timesheets-expenses";
import {
  convertUpdateSelectedWeeksStatusToSwaggerParams,
  convertUpdateSelectedWeeksStatusToSwaggerResponse,
  DailyTimeExpensesLogRequest,
  DayByIdResponseType,
  EditDailyTimeExpensesLogRequestType,
  GetTimeLogCategoriesModel,
  SelectedDayDocument,
  SelectedDayDocuments,
  TimeLogCategoryType,
  TimeSheetsFilterParamsType,
  WeekDayType,
  WeeklyTimeExpensesLogRequestType
} from "../../types/timesheets-and-expenses";
import {
  convertMonthNameToNumber,
  getYearMonth
} from "../../components/forms/YearMonthPicker/helpers";

const getDaysNumber = (days: number[] | null | undefined): number | null => {
  return Array.isArray(days) && days.length ? days[0] : null;
};
export const convertorDefaultFromSwagger = model => model;

export const convertorFromSwagger = model => {
  const [year, week] = model?.yearWeek.split("-");
  const project = extractId(model.project);
  const consultant = extractId(model.consultant);
  const weekDates = getWeekDates(+year, +week);
  const isActiveWeek = getConsultantWeekIsActive(`${year}${week}`);
  const hasLoggedDays = !!model.days.length;

  const weekDays = model.days.map(weekDay => {
    const id = extractId(weekDay["@id"]);
    delete weekDay["@id"];
    delete weekDay["@type"];
    delete weekDay["status"];
    const [year, month, dayDate] = weekDay.day.split("-");
    const days = getDaysNumber(weekDay?.days);
    return {
      ...weekDay,
      id,
      days,
      project: extractId(model.project),
      consultant: extractId(model.consultant),
      date: [year, month, dayDate].join("-"),
      day: [year, month, dayDate, project, consultant].join("-")
    };
  });

  const weekAllDays: WeekDayType[] = weekDates.map((weekDate: string) => {
    const weekDaysByDate = weekDays.find(
      (weekDay: WeekDayType) => weekDay?.date === weekDate
    );
    return weekDaysByDate
      ? weekDaysByDate
      : createDayObject(weekDate, project, consultant);
  });

  delete model["@id"];
  delete model["@type"];

  return {
    ...model,
    weekNumber: Number(`${year}${week}`),
    project: extractId(model.project),
    consultant: extractId(model.consultant),
    days: weekAllDays,
    isActiveWeek,
    hasLoggedDays
  };
};

export const convertorDailyTimeLogFromSwagger = model => {
  if (Array.isArray(model)) {
    return [...model];
  }

  return { ...model };
};
type TimesheetDayParamsType = {
  project: string;
  date: string;
  days?: number[];
  timeCategory?: string;
  expensesAmount?: number;
  expensesType?: string;
  vat?: number;
  documents: string[];
  comment?: string;
};

const getTimesheetDayParams = params => {
  const { project, date, documents, ...rest } = params;
  const days = rest?.days ? [+rest.days] : [];
  const timeCategory = rest?.timeCategory
    ? `/api/time_categories/${rest.timeCategory}`
    : null;
  const expensesType = rest?.expensesType
    ? `/api/expenses_types/${rest.expensesType}`
    : null;
  const expensesAmount = rest?.expensesAmount ? +rest.expensesAmount : null;
  const vat = rest?.vat ? +rest.vat : null;
  const comment = rest?.comment ? rest.comment : "";

  return {
    project,
    date,
    days,
    timeCategory,
    expensesType,
    expensesAmount,
    vat,
    documents,
    comment
  };
};
export const convertorSearchFiltersToSwagger = (
  model: TimeSheetsFilterParamsType
) => {
  let params = {};
  const yearMonth = model.yearMonth ?? getYearMonth();
  const [month, year] = yearMonth.split(" ");

  if (model?.companies && !!model.companies.length) {
    params = { ...params, companies: model.companies.join(",") };
  }
  if (model?.projects && !!model.projects.length) {
    params = { ...params, projects: model.projects.join(",") };
  }
  if (model?.consultants && !!model.consultants.length) {
    params = { ...params, consultants: model.consultants.join(",") };
  }
  if (model?.statuses && !!model.statuses.length) {
    params = { ...params, statuses: model.statuses.join(",") };
  }

  return {
    yearMonth: `${year}-${convertMonthNameToNumber(month)}`,
    items: 999,
    ...params
  };
};
export const convertorCreateDailyTimeLogToSwagger = (
  model: DailyTimeExpensesLogRequest
) => {
  const timesheetDay = getTimesheetDayParams(model);
  return {
    timesheetDay
  };
};

export const convertorCreateWeeklyTimeLogToSwagger = (
  model: WeeklyTimeExpensesLogRequestType
) => {
  const { project, timeCategory, days, comment, date } = model;
  const timesheetDay = {
    project: `/api/projects/${project}`,
    timeCategory: `/api/time_categories/${timeCategory}`,
    days: Array(5).fill(+days),
    expensesAmount: null,
    expensesType: null,
    vat: null,
    documents: [],
    comment: comment ?? null,
    date
  };
  return {
    timesheetDay
  };
};
export const convertorCreateDailyTimeLogFromSwagger = model => {
  const { timesheetDocuments = [], days, comment = "", day } = model;
  const date = getDateFromDayParam(day);
  const yearWeek = getYearWeek(date);
  const project = extractId(model.project);
  const id = extractId(model["@id"]);
  const daysCount = getDaysNumber(days);
  let updatedModel: Record<string, unknown> = {
    id,
    timesheetDocuments,
    comment,
    day,
    project,
    yearWeek
  };
  if (daysCount) {
    updatedModel = { ...updatedModel, days: daysCount };
  }
  if (model?.expensesAmount) {
    updatedModel = { ...updatedModel, expensesAmount: model.expensesAmount };
  }
  if (model?.timeCategory) {
    updatedModel = {
      ...updatedModel,
      timeCategory: extractId(model.timeCategory)
    };
  }
  if (model?.expensesType) {
    updatedModel = {
      ...updatedModel,
      expensesType: extractId(model.expensesType)
    };
  }
  if (model?.vat) {
    updatedModel = { ...updatedModel, vat: model.vat };
  }

  return updatedModel;
};

export const convertorEditDailyTimeLogToSwagger = (
  model: EditDailyTimeExpensesLogRequestType
) => {
  const { id, ...rest } = model;
  const timesheetDay = getTimesheetDayParams(rest);
  return {
    id,
    timesheetDay
  };
};

export const convertorDayDataByIdFromSwagger = (model: DayByIdResponseType) => {
  const {
    day,
    days,
    timeCategory,
    expensesType,
    expensesAmount,
    vat,
    documents = [],
    comment,
    consultant,
    project
  } = model;
  const daysCount = getDaysNumber(days);
  const [dateYear, dateMonth, dateDay] = day.split("-");
  const consultantId = extractId(consultant);
  const projectId = extractId(project);
  const documentsList: SelectedDayDocuments[] = documents.map(
    (item: SelectedDayDocument) => ({
      document: item
    })
  );
  return {
    day: [dateYear, dateMonth, dateDay, projectId, consultantId].join("-"),
    days: daysCount ? `${daysCount}` : null,
    timeCategory: !!timeCategory ? extractId(timeCategory) : null,
    expensesType: !!expensesType ? extractId(expensesType) : null,
    expensesAmount,
    vat,
    documents: documentsList,
    comment,
    consultant: consultantId
  };
};

export const convertSubmitSelectedWeeksToSwagger = (
  model: convertUpdateSelectedWeeksStatusToSwaggerParams
): convertUpdateSelectedWeeksStatusToSwaggerResponse => {
  const timeExpensesWeeks = model.selectedIds.map(
    (id: number): string => `/api/time_expenses_weeks/${id}`
  );
  return {
    timeExpensesWeeks: {
      timeExpensesWeeks: timeExpensesWeeks,
      status: model.status,
      comment: model?.comment || ""
    }
  };
};

export const convertorCategoryFromSwagger = model => {
  const { id, name } = model;
  return { id, name };
};

export const convertGetTimeLogCategoriesToSwagger = (
  model: GetTimeLogCategoriesModel
) => {
  const convertedModel = {
    page: model.pageNumber,
    items: model.itemsPerPage,
    show: model.show
  };
  if (model?.name) {
    return { ...convertedModel, name: model.name };
  }
  return convertedModel;
};
export const convertGetTimeLogCategoriesFromSwagger = (
  model: TimeLogCategoryType
) => {
  const { id, name, deletedAt } = model;

  return { id, name, deletedAt };
};
