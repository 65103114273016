import { IAnyProps } from "../../../interfaces/any-props.interface";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as React from "react";
import { documentsLoader } from "../../../actions/document";
import selector from "../../../selectors/test-document";
import { connect } from "react-redux";
import { IDocument } from "../../../models/document.interface";
import { formatDateWithTime } from "../../../helpers/date-formatter";
import { isChangesExist } from "../../../helpers/props-checker";
import { documentSearchAction } from "../../../actions/document";
import { downloadDocumentFile } from "../../../api/requests/document-download";
import { testerDocumentArchive } from "../../../actions/document";
import * as Roles from "../../../constants/roles";
import { TestStatus } from "../../../constants/test-status";
import PermissionBlock from "../../../components/auth/PermissionBlock";
import TestStatusBlock from "../../../components/auth/TestStatusBlock";
import DocumentDeleteModal from "./DocumentDeleteModal";
import DocumentDownloadModal from "./DocumentDownloadModal";
import { MAX_PAGINATION_RESULTS } from "../../../constants/loader";

interface IProps {
  documentSearch: (id: string) => void;
  data: IDocument[];
  documentArchive: (testId: number, id: any) => void;
  canManage: boolean;
}

interface IState {
  isDeleteModalOpen: boolean;
  isDownloadModalOpen: boolean;
  document: IDocument | null;
}

class DocumentTable extends React.Component<
  IProps & RouteComponentProps<any> & IAnyProps,
  IState,
  {}
> {
  state = {
    isDeleteModalOpen: false,
    isDownloadModalOpen: false,
    document: null
  };

  componentDidMount(): void {
    this.props.setItemsPerPage(MAX_PAGINATION_RESULTS);
    this.props.documentSearch(this.props.match.params.test);
  }

  downloadFile = (document: IDocument | null) => (event: any) => {
    if (document) {
      this.props.downloadDocumentFile(document.id);
    }

    this.hideDownloadModal();
  };

  showDeleteModal = (item: IDocument) => () => {
    this.setState({ isDeleteModalOpen: true, document: item });
  };

  hideDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  showDownloadModal = (item: IDocument) => () => {
    this.setState({ isDownloadModalOpen: true, document: item });
  };

  hideDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  render() {
    if (!this.props.isLoaded) {
      return <div className="table-no-items">Loading...</div>;
    }

    if (!this.props.data.length) {
      return <div className="table-no-items">There are no items!</div>;
    }

    return (
      <div className="table-responsive">
        <table className="block-table">
          <tbody>
            {this.props.data.map((item: IDocument) => {
              return (
                <tr key={item.id}>
                  <td className="td-download">
                    <button
                      className="btn-download"
                      onClick={this.showDownloadModal(item)}
                    >
                      {item.name}
                    </button>
                  </td>
                  <td className="td-date">
                    {formatDateWithTime(item.dateUpload)}
                  </td>
                  <PermissionBlock roles={[Roles.ROLE_PLANNER]}>
                    <TestStatusBlock
                      currentStatus={this.props.test.status}
                      requiredStatuses={[
                        TestStatus.STATUS_DRAFT,
                        TestStatus.STATUS_SCHEDULED,
                        TestStatus.STATUS_BOOKING_REQUESTED,
                        TestStatus.STATUS_BOOKING_REQUESTED_EDITED
                      ]}
                    >
                      <td className="td-delete">
                        <button
                          className="btn-delete"
                          onClick={this.showDeleteModal(item)}
                        >
                          Delete
                        </button>
                      </td>
                    </TestStatusBlock>
                  </PermissionBlock>
                </tr>
              );
            })}
          </tbody>
        </table>

        <DocumentDownloadModal
          open={this.state.isDownloadModalOpen}
          onDownload={this.downloadFile}
          onClose={this.hideDownloadModal}
          item={this.state.document}
        />

        <DocumentDeleteModal
          open={this.state.isDeleteModalOpen}
          onDelete={this.handleArchive}
          onClose={this.hideDeleteModal}
          item={this.state.document}
        />
      </div>
    );
  }

  handleArchive = (document: IDocument | null) => () => {
    if (document) {
      this.props.documentArchive(this.props.match.params.test, document.id);
      this.hideDeleteModal();
    }
  };
}

const routeredComponent = withRouter(DocumentTable);

const mapStateToProps = state => ({
  data: selector.getItems(state),
  isLoaded: selector.getIsLoaded(state),
  itemsPerPage: selector.getItemsPerPage(state),
  pageNumber: selector.getPageNumber(state),
  totalItems: selector.getTotalItems(state)
});

const mapDispatchToProps = {
  downloadDocumentFile,
  documentArchive: testerDocumentArchive,
  documentSearch: documentSearchAction,
  setItemsPerPage: documentsLoader.setItemsPerPage,
  setPageNumber: documentsLoader.setPageNumber
};

export default connect<IAnyProps, IAnyProps, IAnyProps>(
  mapStateToProps,
  mapDispatchToProps
)(routeredComponent);
