import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import {
  usersLoader,
  getUsersFiltered,
  archiveItem,
  unarchiveItem,
  getUser,
  putUserAction,
  resetAction2FA,
  deleteItem
} from "../../../actions/users";
import selector from "../../../selectors/users";
import {
  Switch,
  TableCell,
  TablePagination,
  TableRow
} from "@material-ui/core";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import { isChangesExist } from "../../../helpers/props-checker";
import { IAnyProps } from "../../../interfaces/any-props.interface";
import { IUsers } from "../../../models/users.interface";
import debounce from "debounce";
import { Link } from "react-router-dom";
import { postEmail } from "../../../actions";
import TableCellArchive from "../../../components/table/TableCellArchive";
import { formValueSelector } from "redux-form";
import { CLIENT_SEARCH_FORM_NAME } from "./ClientsSearchForm";
import { formatDateWithSlashesDdMmYyyy } from "../../../helpers/date-formatter";
import Reset2FAConfirmModal from "../modals/Reset2FAConfirmModal";
import TableCellDelete from "../../../components/table/TableCellDelete";

import TableCellEdit from "../../../components/table/TableCellEdit";
import ClientAccountModal from "../../client/ClientAccountModal";
import { clientAccountModalOpen } from "../../../actions";
import { ARCHIVED } from "../../../constants/filter";
import { TableNoItems } from "../../../components/table/TableNoItems";
// import UsersReassignModal from "../modals/UsersReassignModal";
const selectorSearchForm = formValueSelector(CLIENT_SEARCH_FORM_NAME);

interface IFilterValues {
  name: string;
  email: string;
  phone: string;
  role: string;
  createdAt: string;
  deletedAt: string;
  userClientCompanyFilter: string;
}

interface IState {
  isConfirmModalOpen: boolean;
  userId: string | null;
}

type IProps = {
  baseUrl: string;
  [key: string]: any;
} & IFilterValues &
  IState;

class ClientsList extends React.Component<IProps, {}> {
  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  state = {
    isConfirmModalOpen: false,
    userId: null,
    isArchive: false
  };

  showConfirmModal = e => {
    e.preventDefault();
    this.setState({ isConfirmModalOpen: true, userId: e.target.dataset.id });
  };

  hideConfirmModal = () => {
    this.setState({ isConfirmModalOpen: false });
  };

  handleSendLink = e => {
    e.preventDefault();
    this.props.postEmail(e.target.dataset.email);
  };

  loadItems = (props: IProps) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.name,
      props.email,
      props.phone,
      props.role,
      props.createdAt,
      props.deletedAt,
      "",
      "",
      props.userClientCompanyFilter
    );
  };

  changeSubscriptionStatus = item => () => {
    item.isSubscriptionActive = !item.isSubscriptionActive;

    this.props.changeSubscriptionStatus(
      item,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userClientCompanyFilter
    );
  };

  onUnarchive = item => () => {
    this.props.unarchiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userClientCompanyFilter
    );
  };

  onArchive = item => () => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userClientCompanyFilter
    );
    this.setOnArchive(true);
  };

  onDelete = item => () => {
    this.props.deleteItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.name,
      this.props.email,
      this.props.phone,
      this.props.role,
      this.props.createdAt,
      this.props.deletedAt,
      this.props.userClientCompanyFilter
    );
  };

  setOnArchive = value => {
    this.setState({ isArchive: value });
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        [
          "pageNumber",
          "itemsPerPage",
          "showStatus",
          "name",
          "email",
          "phone",
          "role",
          "createdAt",
          "deletedAt",
          "userClientCompanyFilter"
        ],
        nextProps,
        this.props
      )
    ) {
      if (
        isChangesExist(
          [
            "name",
            "email",
            "phone",
            "role",
            "createdAt",
            "deletedAt",
            "userClientCompanyFilter"
          ],
          nextProps,
          this.props
        )
      ) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }

    if (!nextProps.data.length && nextProps.pageNumber > 1) {
      this.props.setPageNumber(1);
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  loadItem = props => {
    props.loadItem(
      props.name,
      props.email,
      props.role,
      props.phone,
      props.createdAt,
      props.deletedAt,
      props.userClientCompanyFilter
    );
  };

  chooseColumns = () => {
    if (this.props.showStatus === ARCHIVED) {
      return [
        "Name",
        "Role",
        "Email",
        "Telephone",
        "Date registered",
        "Reset password",
        "Reset 2FA",
        "Status",
        "Subscription",
        "De/Re activate",
        "Delete"
      ];
    }
    return [
      "Name",
      "Role",
      "Email",
      "Telephone",
      "Date registered",
      "Reset password",
      "Reset 2FA",
      "Status",
      "Edit",
      "Subscription",
      "De/Re activate",
      "Delete"
    ];
  };

  render() {
    if (!this.props.isLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }

    if (!this.props.data.length) {
      return <TableNoItems hasWrapper />;
    }

    const { baseUrl } = this.props;

    return (
      <div>
        {/* <UsersReassignModal isArchive={this.state.isArchive} setOnArchive={this.setOnArchive} onArchive={this.onArchive}/> */}
        <Paper style={{ overflowX: "auto", width: "100%" }}>
          <Table padding="dense">
            <EnhancedTableHead
              columns={this.chooseColumns()}
              // status={this.props.showStatus}
            />
            <TableBody>
              {this.props.data.map((item: IUsers) => {
                const roleCompanyOwner = "Common Company Owner";

                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      {!item.deletedAt && (
                        <Link to={"/admin/clients/details/" + item.id}>
                          {item.firstName + " " + item.lastName}
                        </Link>
                      )}
                      {item.deletedAt && item.firstName + " " + item.lastName}
                    </TableCell>
                    <TableCell>{item.groupName}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell className="text-nowrap">{item.phone}</TableCell>
                    <TableCell className="text-nowrap">
                      {formatDateWithSlashesDdMmYyyy(item.createdAt)}
                    </TableCell>
                    <TableCell>
                      {!item.deletedAt && (
                        <a
                          href={item.email}
                          data-email={item.email}
                          onClick={this.handleSendLink}
                        >
                          Reset&nbsp;password
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {!item.deletedAt && (
                        <a
                          href={item.email}
                          data-id={item.id}
                          onClick={this.showConfirmModal}
                        >
                          Reset 2FA
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {!item.deletedAt ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCellEdit
                      item={item}
                      baseUrl={baseUrl}
                      status={this.props.showStatus}
                    />
                    {item.groupName === roleCompanyOwner ? (
                      <TableCell>
                        <Switch
                          key={item.id}
                          checked={item.isSubscriptionActive}
                          onChange={this.changeSubscriptionStatus(item)}
                        />
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                    <TableCellArchive
                      onArchive={this.onArchive(item)}
                      onUnarchive={this.onUnarchive(item)}
                      item={item}
                    />
                    {!!item.deletedAt ? (
                      <TableCell>
                        <div style={{ height: "48px" }} />
                      </TableCell>
                    ) : (
                      <TableCellDelete
                        onDelete={this.onDelete(item)}
                        item={item}
                        isOwner
                      />
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{ "aria-label": "Previous Page" }}
            nextIconButtonProps={{ "aria-label": "Next Page" }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <Reset2FAConfirmModal
          open={this.state.isConfirmModalOpen}
          onClose={this.hideConfirmModal}
          onReset={this.props.onReset2FA}
          id={this.state.userId}
        />
      </div>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps & { baseUrl: string }>(
  state => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    name: selectorSearchForm(state, "name") || "",
    email: selectorSearchForm(state, "email") || "",
    phone: selectorSearchForm(state, "phone") || "",
    role:
      selectorSearchForm(state, "role") ||
      "Owner+Planner+Viewer+Employee+ExternalTester",
    userClientCompanyFilter:
      selectorSearchForm(state, "userClientCompanyFilter") || "",
    createdAt: selectorSearchForm(state, "createdAt") || "",
    deletedAt: selectorSearchForm(state, "deletedAt") || ""
  }),
  {
    postEmail,
    loadItem: getUser,
    loadItems: getUsersFiltered,
    setItemsPerPage: usersLoader.setItemsPerPage,
    setPageNumber: usersLoader.setPageNumber,
    archiveItem: archiveItem("Client"),
    unarchiveItem: unarchiveItem("Client"),
    changeSubscriptionStatus: putUserAction,
    onReset2FA: resetAction2FA,
    deleteItem: deleteItem("Client"),
    clientAccountModalOpen
  }
)(ClientsList);
