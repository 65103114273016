import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {connect} from "react-redux";
import {usersRequest, usersLoader, getUsersFiltered, archiveItem, unarchiveItem, unlinkTesterAction, getUser} from "../../../actions/users";
import selector from "../../../selectors/users";
import {TableCell, TablePagination, TableRow} from "@material-ui/core";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import {isChangesExist} from "../../../helpers/props-checker";
import {IAnyProps} from "../../../interfaces/any-props.interface";
import {IUsers} from "../../../models/users.interface";
import debounce from "debounce";
import {Link} from "react-router-dom";
import {postEmail} from "../../../actions";
import TableCellArchive from "../../../components/table/TableCellArchive";
import {formatDateWithTime} from "../../../helpers/date-formatter";
import {getStatusDescription} from "../../../constants/dbs-certificate-statuses";
import TableCellDelete from "../../../components/table/TableCellDelete";
import UsersReassignModal from "./UsersReassignModal";
import {Group} from "../../../constants/group";

interface IFilterValues {
  name: string;
  email: string;
  phone: string;
  date: string;
  createdAt: string;
  deletedAt: string;
  testingCompany: string;
  role: string;
}

type IProps = {
  baseUrl: string,
  [key: string]: any
} & IFilterValues;

class TestersList extends React.Component<IProps, {}> {
  debouncedLoad = debounce((nextProps) => {
    this.loadItems(nextProps);
  }, 500);

  handleSendLink = (e) => {
    e.preventDefault();
    this.props.postEmail(e.target.dataset.email);
  };

  loadItems = (props: IProps) => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.showStatus,
      props.filterText,
      "",
      "",
      "TestingCompanyTester",
    );
  };

  onUnarchive = (item) => () => {
    this.props.unarchiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  onArchive = (item) => () => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  onDelete = (item) => () => {
    this.props.unlinkTester(
      item.testerProfileId,
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText,
    );
  };

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  loadItem = (props) => {
    props.loadItem(
      props.name,
      props.email,
      props.phone,
      props.createdAt,
      props.deletedAt,
      props.userDbsFilter
    );
  };

  componentDidMount() {
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      isChangesExist(
        ["pageNumber", "itemsPerPage", "showStatus", "filterText"],
        nextProps,
        this.props
      )
    ) {
      if (isChangesExist(["filterText"], nextProps, this.props)) {
        this.debouncedLoad.clear();
        this.debouncedLoad(nextProps);
      } else {
        this.loadItems(nextProps);
      }
    }
  }

  render() {
    if (!this.props.isLoaded) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div>
          There are no items!
        </div>
      );
    }
    return (
      <div>
      <UsersReassignModal page={this.props.pageNumber} itemsPerPage={this.props.itemsPerPage} showStatus={this.props.showStatus} filterText={this.props.filterText} />
        <Paper style={{overflowX: "auto", width: "100%"}}>
          <Table padding="dense">
            <EnhancedTableHead
              columns={["Name", "Email", "Telephone", "Date registered", "Reset password", "Status", "De\/Re\nactivate", "Remove from\ncompany"]}
            />
            <TableBody>
              {
                this.props.data.map((item: any) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        {!item.deletedAt &&
                        <Link to={"/tester/admin/" + (item as any).testerProfileId}>
                          {item.firstName + " " + item.lastName}
                        </Link>

                        }
                        {
                          item.deletedAt && item.firstName + " " + item.lastName
                        }
                      </TableCell>
                      <TableCell>
                        {item.email}
                      </TableCell>
                      <TableCell className="text-nowrap">
                        {item.phone}
                      </TableCell>
                      <TableCell className="text-nowrap">
                        {formatDateWithTime(item.createdAt)}
                      </TableCell>
                      <TableCell>
                        {!item.deletedAt && item.groupName !== Group.MANAGER && <a
                          href={item.email}
                          data-email={item.email}
                          onClick={this.handleSendLink}>Reset&nbsp;password</a>
                        }
                      </TableCell>
                      <TableCell>
                        {!item.deletedAt ? "Active" : "Inactive"}
                      </TableCell>
                      {item.groupName === Group.MANAGER ? (
                        <>
                        <TableCell>
                          <div style={{height:"48px"}}/>
                        </TableCell>
                        <TableCell>
                          <div style={{height:"48px"}}/>
                        </TableCell>
                        </>
                      ):(
                        <>
                          <TableCellArchive
                            onArchive={this.onArchive(item)}
                            onUnarchive={this.onUnarchive(item)}
                            item={item}
                          />
                          {item.deletedAt ? (
                            <TableCell>
                              <div style={{height:"48px"}}/>
                            </TableCell>
                          ):(
                            <TableCellDelete
                                onDelete={this.onDelete(item)}
                                item={item}
                                iconName="link_off"
                            />
                          )}

                        </>
                      )}
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{"aria-label": "Previous Page"}}
            nextIconButtonProps={{"aria-label": "Next Page"}}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps & { baseUrl: string }>(
  state => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    showStatus: selector.getFilterActivityStatus(state),
    totalItems: selector.getTotalItems(state),
    filterText: selector.getFilterTextFields(state),
  }),
  {
    postEmail,
    loadItem: usersRequest.getItem,
    loadItems: getUsersFiltered,
    setItemsPerPage: usersLoader.setItemsPerPage,
    setPageNumber: usersLoader.setPageNumber,
    archiveItem: archiveItem("TesterAndManager"),
    unarchiveItem: unarchiveItem("TesterAndManager"),
    unlinkTester: unlinkTesterAction("TesterAndManager"),
  }
)(TestersList);
