import React, { useState } from "react";
import { FieldArrayFieldsProps } from "redux-form";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { archiveTimesheetExpensesDocumentAction } from "../../../../actions/time-expenses";
import { ConfirmModal } from "../../../ConfirmModal";

type RemoveRepeaterButtonProps = {
  fields: FieldArrayFieldsProps<{ document: FieldArrayFieldsProps<any> }>;
  index: number;
  id?: number;
  archiveTimesheetExpensesDocument: (id: number) => void;
};

const RemoveRepeaterButton = (props: RemoveRepeaterButtonProps) => {
  const { fields, index, id, archiveTimesheetExpensesDocument } = props;
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const removeRepeater = () => fields.remove(index);
  const archiveDocument = (id: number) => {
    archiveTimesheetExpensesDocument(id);
    removeRepeater();
    setIsOpened(false);
  };
  const clickHandler = () => {
    if (id) {
      setIsOpened(true);
    } else {
      removeRepeater();
    }
  };
  return (
    <>
      <button className="btn-remove" type="button" onClick={clickHandler} />
      {
        <ConfirmModal
          isOpened={isOpened}
          confirmDescription="The document will be archived and completely deleted after saving the changes as a draft."
          onConfirm={() => archiveDocument(id as number)}
          onClose={() => setIsOpened(false)}
          okLabel="Archive"
          cancelLabel="Cancel"
        />
      }
    </>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      archiveTimesheetExpensesDocument: archiveTimesheetExpensesDocumentAction
    },
    dispatch
  );
};
const connected = connect(null, mapDispatchToProps)(RemoveRepeaterButton);

export default connected;
