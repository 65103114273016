import { SelectOptionType } from "../types";

export const getRatePercent = (consultantRate: number, projectRate: number) => {
  if (!consultantRate || !projectRate) {
    return null;
  }
  return Math.ceil(((projectRate - consultantRate) / consultantRate) * 100);
};

export const selectOptionsSort = (a: SelectOptionType, b: SelectOptionType) => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};
