import * as React from 'react';
import {TestStatus} from "../../constants/test-status";

interface IOwnProps {
  currentStatus: TestStatus;
  requiredStatuses: TestStatus[];
}

class TestStatusBlock extends React.Component<IOwnProps, {}> {
  render() {
    if (this.props.requiredStatuses.indexOf(this.props.currentStatus) === -1) {
      return null;
    }

    return this.props.children;
  };
}

export default TestStatusBlock;