import React from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { NavLink, Redirect, Route, Switch, withRouter } from "react-router-dom";
import Testers from "./testers/Testers";
import Settings from "./settings/Settings";
import Payment from "./Payment";
import Clients from "./clients/Clients";
import Tests from "./tests/Tests";
import ReportTemplatesAlert from "./ReportTemplatesAlert";
import Invoices from "./invoces/Invoices";
import Header from "../guest/Header";
import Footer from "../../containers/guest/Footer";
import { AdminUrls } from "../../constants/admin-urls";
import Consultants from "./consultants/Consultants";
import TimeAndExpenses from "./time-and-expenses/TimeAndExpenses";

const getActiveTabIndex = activeTabRoute => {
  switch (activeTabRoute) {
    case AdminUrls.SETTINGS:
      return 0;
    case AdminUrls.INVOICES:
      return 1;
    case AdminUrls.TESTERS:
      return 2;
    case AdminUrls.CLIENTS:
      return 3;
    case AdminUrls.TESTS:
      return 4;
    case `${AdminUrls.CONSULTANTS}/create`:
    case `${AdminUrls.CONSULTANTS}/:id`:
    case AdminUrls.CONSULTANTS:
      return 5;
    case AdminUrls.TIME_AND_EXPENSES:
      return 6;
    default:
      return 0;
  }
};

class Admin extends React.Component {
  render() {
    const {
      location: { pathname: activeTabRoute }
    } = this.props;
    const activeTab = getActiveTabIndex(activeTabRoute);

    return (
      <div>
        <Header />
        <div className="page-content">
          <ReportTemplatesAlert />
          <AppBar position="static" color="default">
            <Tabs
              indicatorColor="primary"
              variant="scrollable"
              value={activeTab}
            >
              <Tab
                component={NavLink}
                activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                label="Settings"
                to={AdminUrls.SETTINGS}
              />
              <Tab
                component={NavLink}
                activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                label="Invoices"
                to={AdminUrls.INVOICES}
              />
              <Tab
                component={NavLink}
                activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                label="Testers"
                to={AdminUrls.TESTERS}
              />
              <Tab
                component={NavLink}
                activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                label="Clients"
                to={AdminUrls.CLIENTS}
              />
              <Tab
                component={NavLink}
                activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                label="Tests"
                to={AdminUrls.TESTS}
              />
              <Tab
                component={NavLink}
                activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                label="Consultants"
                to={AdminUrls.CONSULTANTS}
              />
              <Tab
                component={NavLink}
                activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
                label="Time & expenses"
                to={AdminUrls.TIME_AND_EXPENSES}
              />
            </Tabs>
          </AppBar>
          <div className="content">
            <Switch>
              <Redirect from="/admin" exact to="/admin/settings" />
              <Route path="/admin/tests" component={Tests} />
              <Route path="/admin/clients" component={Clients} />
              <Route path="/admin/testers" component={Testers} />
              <Route path="/admin/invoices" component={Invoices} />
              <Route path="/admin/payment-batches" component={Payment} />
              <Route path="/admin/settings" component={Settings} />
              <Route path={AdminUrls.CONSULTANTS} component={Consultants} />
              <Route
                path={AdminUrls.TIME_AND_EXPENSES}
                component={TimeAndExpenses}
              />
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Admin);
