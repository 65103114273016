import {
  ROLE,
  ROLE_ADMIN,
  ROLE_OWNER,
  ROLE_PLANNER,
  ROLE_SUPER_ADMIN,
  ROLE_TESTER,
  ROLE_VIEWER,
  ROLE_PROJECT_CONSULTANT
} from "../constants/roles";

const LOGIN_REDIRECT_ROUTES = {
  ROLE_SUPER_ADMIN: "/admin/settings/admin-users",
  ROLE_ADMIN: "/admin/settings/carousel",
  ROLE_TESTER: "/tester/account",
  ROLE_PLANNER: "/client/dashboard",
  ROLE_OWNER: "/client/admin/company-details/edit",
  ROLE_OWNER_XERO_CONTACT: "/client/dashboard",
  ROLE_VIEWER: "/client/tests-and-projects/tests",
  ROLE_PROJECT_CONSULTANT: "/consultant/dashboard"
};

export const checkUserRole = (userRoles: ROLE[] = []) => (role: ROLE) =>
  userRoles.some((userRole: ROLE): boolean => userRole === role);

const getLoginRedirectLink = (
  userRoles: ROLE[],
  isXeroContactExists: boolean
) => {
  const userHasRole = checkUserRole(userRoles);
  if (userHasRole(ROLE_SUPER_ADMIN)) {
    return LOGIN_REDIRECT_ROUTES.ROLE_SUPER_ADMIN;
  }
  if (userHasRole(ROLE_ADMIN)) {
    return LOGIN_REDIRECT_ROUTES.ROLE_ADMIN;
  }
  if (userHasRole(ROLE_TESTER)) {
    return LOGIN_REDIRECT_ROUTES.ROLE_TESTER;
  }
  if (userHasRole(ROLE_OWNER) && isXeroContactExists) {
    return LOGIN_REDIRECT_ROUTES.ROLE_OWNER_XERO_CONTACT;
  }
  if (userHasRole(ROLE_OWNER)) {
    return LOGIN_REDIRECT_ROUTES.ROLE_OWNER;
  }
  if (userHasRole(ROLE_PLANNER)) {
    return LOGIN_REDIRECT_ROUTES.ROLE_PLANNER;
  }
  if (userHasRole(ROLE_VIEWER)) {
    return LOGIN_REDIRECT_ROUTES.ROLE_VIEWER;
  }
  if (userHasRole(ROLE_PROJECT_CONSULTANT)) {
    return LOGIN_REDIRECT_ROUTES.ROLE_PROJECT_CONSULTANT;
  }
  return "/";
};

const getLoginRedirectMessage = (
  userRoles: ROLE[],
  isXeroContactExists: boolean
) => {
  const userHasRole = checkUserRole(userRoles);
  if (userHasRole(ROLE_OWNER) && !isXeroContactExists) {
    return "To unlock full AVORD platform functionality please complete the following form";
  }
  return "";
};

export const getLoginRedirectLinkAndMessage = (
  userRoles: ROLE[],
  isXeroContactExists: boolean
) => {
  const loginRedirectLink: string = getLoginRedirectLink(
    userRoles,
    isXeroContactExists
  );
  const loginRedirectMessage: string = getLoginRedirectMessage(
    userRoles,
    isXeroContactExists
  );

  return { loginRedirectLink, loginRedirectMessage };
};
