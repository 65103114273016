import {checkToken} from '../swagger/api-factory';
import axios from "axios";
import {
  API_DOCUMENTS_ENDPOINT_URL,
  BASE_URL,
  API_REPORTS_ENDPOINT_URL,
  API_REPORT_DOCUMENTS_ENDPOINT_URL, API_EXPORT_CSV_TESTS_ENDPOINT_URL
} from "../../constants/urls";
import * as Storage from "../../helpers/storage-helper";
import {TOKEN_KEY} from "../../constants/storage";
import {getSoWDocumentIdAction} from "../../actions/document";
import {setRequestInProcess} from "../../actions";
import {TestsFilterParamsForCSVType} from "../../types";

export const downloadDocumentFile = (id: number) => async dispatch => {
  await checkToken();

  return downloadDocumentRequest(BASE_URL + API_DOCUMENTS_ENDPOINT_URL + id, dispatch);
};

export const downloadReportFile = (id: number) => async dispatch => {
  await checkToken();

  return downloadDocumentRequest(BASE_URL + '/api/tests/' + id + API_REPORTS_ENDPOINT_URL, dispatch);
};

export const downloadTestsByDate = (rangeOfDate: { dateFrom?: string; dateTo?: string }) => async dispatch => {
  await checkToken();

  const specificURL = getSpecificPathForDashboardReports('/api/charts/tests/report', rangeOfDate);

  return downloadDocumentRequest(BASE_URL + specificURL, dispatch);
};

export const downloadCostsByDate = (rangeOfDate: { dateFrom?: string; dateTo?: string }) => async dispatch => {
  await checkToken();

  const specificURL = getSpecificPathForDashboardReports('/api/charts/projects-cost/report', rangeOfDate);

  return downloadDocumentRequest(BASE_URL + specificURL, dispatch);
};

export const downloadCriticalRiskByDate = (rangeOfDate: { dateFrom?: string; dateTo?: string }) => async dispatch => {
  await checkToken();

  const specificURL = getSpecificPathForDashboardReports('/api/risks/generate-report', rangeOfDate);

  return downloadDocumentRequest(BASE_URL + specificURL, dispatch);
};

export const downloadReportDocumentFile = (id: number) => async dispatch => {
  await checkToken();

  return downloadDocumentRequest(BASE_URL + '/api/report_documents/'  + id + API_REPORT_DOCUMENTS_ENDPOINT_URL, dispatch);
};

export const downloadInvoiceFile = (id: number) => async dispatch => {
  await checkToken();

  return downloadDocumentRequest(BASE_URL + '/api/invoices/' + id + '/download', dispatch);
};

export const downloadSecureDocument = (id: number) => async dispatch => {
  await checkToken();

  return downloadDocumentRequest(BASE_URL + '/api/secured_images/' + id, dispatch);
};

export const getUrlSecureDocument = (id: number) => async dispatch => {
  await checkToken();

  return downloadUrlDocumentRequest(BASE_URL + '/api/secured_images/' + id, dispatch);
};

export const downloadSoWDocument = (id) => async dispatch => {
  await checkToken();
  const documentId = await dispatch(getSoWDocumentIdAction(id));
  return downloadDocumentRequest(BASE_URL + API_DOCUMENTS_ENDPOINT_URL + documentId, dispatch);
}

export const downloadTestsCSVDocument = (filterParams: TestsFilterParamsForCSVType) => async dispatch => {

  const activeFiltersArray = Object.entries(filterParams).filter(([_, value]) => !!value);
  const activeFilters: TestsFilterParamsForCSVType = Object. fromEntries(activeFiltersArray);

  await checkToken();

  return downloadTestsCSVDocumentRequest(activeFilters, dispatch);
}

function downloadUrlDocumentRequest(downloadUrl, dispatch) {
  dispatch(setRequestInProcess(true, 'downloadDocument'));

  return axios({
    url: downloadUrl,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Authorization': 'Bearer ' + Storage.getValue(TOKEN_KEY)
    }
  }).then((response) => {
    const url = window.URL.createObjectURL(
        new Blob([response.data],
            {type: response.headers['content-type']})
    );

    dispatch(setRequestInProcess(false, 'downloadDocument'));
    return url;
  }).catch(() => {
    dispatch(setRequestInProcess(false, 'downloadDocument'));
  });
}

function downloadDocumentRequest(downloadUrl, dispatch) {
  dispatch(setRequestInProcess(true, 'downloadDocument'));

  return axios({
    url: downloadUrl,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Authorization': 'Bearer ' + Storage.getValue(TOKEN_KEY)
    }
  }).then((response) => {
    const url = window.URL.createObjectURL(
        new Blob([response.data],
            {type: response.headers['content-type']})
    );

    const link = document.createElement('a');
    link.href = url;

    const quote = `"`

    const oneStringFileName = response.headers["content-disposition"].replace('attachment; filename=', '')
    const multiStringFileName = response.headers["content-disposition"].replace('attachment; filename="', '').slice(0, -1)

    const filename = oneStringFileName.startsWith(quote) ? multiStringFileName : oneStringFileName

    link.setAttribute(
      'download',
        filename
    );

    document.body.appendChild(link);
    link.click();

    dispatch(setRequestInProcess(false, 'downloadDocument'));
  }).catch(() => {
    dispatch(setRequestInProcess(false, 'downloadDocument'));
  });
}

function getSpecificPathForDashboardReports(specificPath: string, rangeOfDate: { dateFrom?: string; dateTo?: string }) {
  const { dateFrom = '' , dateTo = '' } = rangeOfDate;

  if (!dateFrom && !dateTo) {
    return `${specificPath}`;
  }

  if (dateFrom && !dateTo) {
    return `${specificPath}?dateFrom=${dateFrom}`;
  }

  if (!dateFrom && dateTo) {
    return `${specificPath}?dateTo=${dateTo}`;
  }

  return `${specificPath}?dateFrom=${dateFrom}&dateTo=${dateTo}`;
}

function downloadTestsCSVDocumentRequest(filterParams: TestsFilterParamsForCSVType = {}, dispatch) {
  dispatch(setRequestInProcess(true, 'downloadDocument'));

  return axios({
    url:  BASE_URL + API_EXPORT_CSV_TESTS_ENDPOINT_URL,
    method: 'POST',
    responseType: 'blob',
    headers: {
      'Authorization': 'Bearer ' + Storage.getValue(TOKEN_KEY)
    },
    data: filterParams
  }).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data],
        {type: response.headers['content-type']})
    );

    const link = document.createElement('a');
    link.href = url;

    const quote = `"`

    const oneStringFileName = response.headers["content-disposition"].replace('attachment; filename=', '')
    const multiStringFileName = response.headers["content-disposition"].replace('attachment; filename="', '').slice(0, -1)

    const filename = oneStringFileName.startsWith(quote) ? multiStringFileName : oneStringFileName

    link.setAttribute(
      'download',
      filename
    );

    document.body.appendChild(link);
    link.click();

    dispatch(setRequestInProcess(false, 'downloadDocument'));
  }).catch(() => {
    dispatch(setRequestInProcess(false, 'downloadDocument'));
  });
}