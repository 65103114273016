/* tslint:disable:no-string-literal */
/* tslint:disable:jsdoc-format */

import * as request from "superagent";

/**
 * 
 * @class Api
 * @param {(string)} [domainOrOptions] - The project domain.
 */
export default class Api {

    domain = "";
    errorHandlers = [];
    authToken = '';
    commonHeaders = {};

    constructor(domain, authToken = '', headers = {}) {
        if (domain) {
            this.domain = domain;
        }

        this.authToken = authToken;
        this.commonHeaders = Object.assign(this.commonHeaders, headers);
    }

    getDomain() {
        return this.domain;
    }

    addErrorHandler(handler) {
        this.errorHandlers.push(handler);
    }

    request(method, url, body, headers, queryParameters, form, reject, resolve) {
        if (this.logger) {
            this.logger.log(`Call ${method} ${url}`);
        }

        let req = request(method, url).query(queryParameters);

        if (this.authToken) {
            req.set('Authorization', 'Bearer ' + this.authToken);
        }

        const resultHeaders = {
            ...headers,
            ...this.commonHeaders
        };

        Object.keys(resultHeaders).forEach(key => {
            req.set(key, resultHeaders[key]);
        });

        if (body) {
            req.send(body);
        }

        if (typeof(body) === 'object' && !(body.constructor.name === 'Buffer')) {
            req.set('Content-Type', 'application/json');
        }

        if (Object.keys(form).length > 0) {
            if (typeof form.file !== 'undefined') {
                var formData = new FormData();
                formData.append('file', form.file);
                req.attach('file', form.file);
            } else {
                req.type('form');
                req.send(form);
            }
        }

        req.end((error, response) => {
            if (error || !response.ok) {
                error.response = response;
                reject(error);
                this.errorHandlers.forEach(handler => handler(error));
            } else {
                resolve(response);
            }
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getAdditionalReportDocumentItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_report_documents/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a AdditionalReportDocument resource.
     * @method
     * @name Api#getAdditionalReportDocumentItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getAdditionalReportDocumentItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_report_documents/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteAdditionalReportDocumentItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_report_documents/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the AdditionalReportDocument resource.
     * @method
     * @name Api#deleteAdditionalReportDocumentItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteAdditionalReportDocumentItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_report_documents/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'report'?: ,
     *   'report'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getAdditionalReportDocumentCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_report_documents';
        if (parameters['report'] !== undefined) {
            queryParameters['report'] = parameters['report'];
        }

        if (parameters['report'] !== undefined) {
            queryParameters['report[]'] = parameters['report'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of AdditionalReportDocument resources.
     * @method
     * @name Api#getAdditionalReportDocumentCollection
     * @param {string} report - 
     * @param {string} report - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'report'?: ,
     *   'report'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getAdditionalReportDocumentCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_report_documents';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['report'] !== undefined) {
                queryParameters['report'] = parameters['report'];
            }

            if (parameters['report'] !== undefined) {
                queryParameters['report[]'] = parameters['report'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'additionalReportDocument'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postAdditionalReportDocumentCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_report_documents';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a AdditionalReportDocument resource.
     * @method
     * @name Api#postAdditionalReportDocumentCollection
     * @param {} additionalReportDocument - The new AdditionalReportDocument resource
     * Parameters
     * {
     *   'additionalReportDocument'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postAdditionalReportDocumentCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_report_documents';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['additionalReportDocument'] !== undefined) {
                body = parameters['additionalReportDocument'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'name'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCompaniesListForManagedServicesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/companies';
        if (parameters['name'] !== undefined) {
            queryParameters['name'] = parameters['name'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Company resources.
     * @method
     * @name Api#getCompaniesListForManagedServices
     * @param {string} name - 
     * Parameters
     * {
     *   'name'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCompaniesListForManagedServices(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/companies';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['name'] !== undefined) {
                queryParameters['name'] = parameters['name'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'name'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    validatedCompaniesCompanyCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/companies/on_admin_validation';
        if (parameters['name'] !== undefined) {
            queryParameters['name'] = parameters['name'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Company resources.
     * @method
     * @name Api#validatedCompaniesCompanyCollection
     * @param {string} name - 
     * Parameters
     * {
     *   'name'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    validatedCompaniesCompanyCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/companies/on_admin_validation';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['name'] !== undefined) {
                queryParameters['name'] = parameters['name'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'companyIds'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    updateCompaniesValidationCompanyCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/companies/update_validation';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Company resource.
     * @method
     * @name Api#updateCompaniesValidationCompanyCollection
     * @param {} companyIds - The new Company resource
     * Parameters
     * {
     *   'companyIds'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    updateCompaniesValidationCompanyCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/companies/update_validation';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['companyIds'] !== undefined) {
                body = parameters['companyIds'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCompanyItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/companies/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Company resource.
     * @method
     * @name Api#getCompanyItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCompanyItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/companies/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    loadAdditionalServicesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_services';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of AdditionalService resources.
     * @method
     * @name Api#loadAdditionalServices
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    loadAdditionalServices(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_services';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'additionalService'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postAdditionalServiceCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_services';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a AdditionalService resource.
     * @method
     * @name Api#postAdditionalServiceCollection
     * @param {} additionalService - The new AdditionalService resource
     * Parameters
     * {
     *   'additionalService'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postAdditionalServiceCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_services';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['additionalService'] !== undefined) {
                body = parameters['additionalService'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getAdditionalServiceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_services/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a AdditionalService resource.
     * @method
     * @name Api#getAdditionalServiceItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getAdditionalServiceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_services/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'additionalService'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putAdditionalServiceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_services/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the AdditionalService resource.
     * @method
     * @name Api#putAdditionalServiceItem
     * @param {string} id - 
     * @param {} additionalService - The updated AdditionalService resource
     * Parameters
     * {
     *   'id': ,
     *   'additionalService'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putAdditionalServiceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_services/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['additionalService'] !== undefined) {
                body = parameters['additionalService'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteAdditionalServiceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_services/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the AdditionalService resource.
     * @method
     * @name Api#deleteAdditionalServiceItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteAdditionalServiceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/additional_services/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'additionalServices'?: ,
     *   'testId'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putServicesManagedCompaniesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/additional_services';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * update services managed list
     * @method
     * @name Api#putServicesManagedCompanies
     * @param {} additionalServices - 
     * @param {} testId - 
     * Parameters
     * {
     *   'additionalServices'?: ,
     *   'testId'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putServicesManagedCompanies(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/additional_services';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['additionalServices'] !== undefined) {
                body = parameters['additionalServices'];
            }

            if (parameters['testId'] !== undefined) {
                body = parameters['testId'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getServicesManagedCompaniesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/additional_services/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * get services managed list
     * @method
     * @name Api#getServicesManagedCompanies
     * @param {integer} id - get additional services by test id
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getServicesManagedCompanies(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/additional_services/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putServicesManagedCompanies_1URL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/additional_services/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * update services managed list
     * @method
     * @name Api#putServicesManagedCompanies_1
     * @param {integer} id - get additional services by test id
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putServicesManagedCompanies_1(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/additional_services/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'consultant'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postAssignConsultantURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/test/assign-on-consultant';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Add Consultant
     * @method
     * @name Api#postAssignConsultant
     * @param {} consultant - 
     * Parameters
     * {
     *   'consultant'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postAssignConsultant(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/test/assign-on-consultant';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['consultant'] !== undefined) {
                body = parameters['consultant'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getDocumentIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/sow_document/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get document id
     * @method
     * @name Api#getDocumentId
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getDocumentId(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/sow_document/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApplicationSettingItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/application_settings/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a ApplicationSetting resource.
     * @method
     * @name Api#getApplicationSettingItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApplicationSettingItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/application_settings/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'applicationSetting'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApplicationSettingItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/application_settings/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the ApplicationSetting resource.
     * @method
     * @name Api#putApplicationSettingItem
     * @param {string} id - 
     * @param {} applicationSetting - The updated ApplicationSetting resource
     * Parameters
     * {
     *   'id': ,
     *   'applicationSetting'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApplicationSettingItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/application_settings/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['applicationSetting'] !== undefined) {
                body = parameters['applicationSetting'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'carousel'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveCarouselItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/carousel/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the Carousel resource.
     * @method
     * @name Api#unarchiveCarouselItem
     * @param {string} id - 
     * @param {} carousel - The updated Carousel resource
     * Parameters
     * {
     *   'id': ,
     *   'carousel'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveCarouselItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/carousel/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['carousel'] !== undefined) {
                body = parameters['carousel'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'purchaseOrderNumber': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    savePONumberURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/update-purchase-order-number';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name Api#savePONumber
     * @param {integer} id - test id
     * @param {} purchaseOrderNumber - update po number
     * Parameters
     * {
     *   'id': ,
     *   'purchaseOrderNumber': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    savePONumber(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/update-purchase-order-number';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['purchaseOrderNumber'] !== undefined) {
                body = parameters['purchaseOrderNumber'];
            }

            if (parameters['purchaseOrderNumber'] === undefined) {
                reject(new Error('Missing required  parameter: purchaseOrderNumber'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'dates': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    updateServiceDatesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/update-dates';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * 
     * @method
     * @name Api#updateServiceDates
     * @param {integer} id - test id
     * @param {} dates - update service dates
     * Parameters
     * {
     *   'id': ,
     *   'dates': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    updateServiceDates(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/update-dates';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['dates'] !== undefined) {
                body = parameters['dates'];
            }

            if (parameters['dates'] === undefined) {
                reject(new Error('Missing required  parameter: dates'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'carousel'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postCarouselCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/carousels';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Carousel resource.
     * @method
     * @name Api#postCarouselCollection
     * @param {} carousel - The new Carousel resource
     * Parameters
     * {
     *   'carousel'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postCarouselCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/carousels';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['carousel'] !== undefined) {
                body = parameters['carousel'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCarouselItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/carousels/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Carousel resource.
     * @method
     * @name Api#getCarouselItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCarouselItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/carousels/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'carousel'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putCarouselItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/carousels/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Carousel resource.
     * @method
     * @name Api#putCarouselItem
     * @param {string} id - 
     * @param {} carousel - The updated Carousel resource
     * Parameters
     * {
     *   'id': ,
     *   'carousel'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putCarouselItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/carousels/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['carousel'] !== undefined) {
                body = parameters['carousel'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteCarouselItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/carousels/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Carousel resource.
     * @method
     * @name Api#deleteCarouselItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteCarouselItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/carousels/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'certificate'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveCertificateItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the Certificate resource.
     * @method
     * @name Api#unarchiveCertificateItem
     * @param {string} id - 
     * @param {} certificate - The updated Certificate resource
     * Parameters
     * {
     *   'id': ,
     *   'certificate'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveCertificateItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['certificate'] !== undefined) {
                body = parameters['certificate'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'certificateType'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postCertificateTypeCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate_types';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a CertificateType resource.
     * @method
     * @name Api#postCertificateTypeCollection
     * @param {} certificateType - The new CertificateType resource
     * Parameters
     * {
     *   'certificateType'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postCertificateTypeCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate_types';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['certificateType'] !== undefined) {
                body = parameters['certificateType'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCertificateTypeItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate_types/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a CertificateType resource.
     * @method
     * @name Api#getCertificateTypeItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCertificateTypeItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate_types/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'certificateType'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putCertificateTypeItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate_types/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the CertificateType resource.
     * @method
     * @name Api#putCertificateTypeItem
     * @param {string} id - 
     * @param {} certificateType - The updated CertificateType resource
     * Parameters
     * {
     *   'id': ,
     *   'certificateType'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putCertificateTypeItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate_types/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['certificateType'] !== undefined) {
                body = parameters['certificateType'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteCertificateTypeItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate_types/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the CertificateType resource.
     * @method
     * @name Api#deleteCertificateTypeItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteCertificateTypeItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate_types/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'certificateType'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveCertificateTypeItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate_types/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the CertificateType resource.
     * @method
     * @name Api#unarchiveCertificateTypeItem
     * @param {string} id - 
     * @param {} certificateType - The updated CertificateType resource
     * Parameters
     * {
     *   'id': ,
     *   'certificateType'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveCertificateTypeItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificate_types/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['certificateType'] !== undefined) {
                body = parameters['certificateType'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'show'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCertificateCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificates';
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Certificate resources.
     * @method
     * @name Api#getCertificateCollection
     * @param {string} show - Active, archived or all sectors
     * Parameters
     * {
     *   'show'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCertificateCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificates';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'certificate'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postCertificateCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificates';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Certificate resource.
     * @method
     * @name Api#postCertificateCollection
     * @param {} certificate - The new Certificate resource
     * Parameters
     * {
     *   'certificate'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postCertificateCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificates';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['certificate'] !== undefined) {
                body = parameters['certificate'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCertificateItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificates/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Certificate resource.
     * @method
     * @name Api#getCertificateItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCertificateItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificates/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteCertificateItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificates/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Certificate resource.
     * @method
     * @name Api#deleteCertificateItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteCertificateItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/certificates/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getContentManagementItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/content_managements/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a ContentManagement resource.
     * @method
     * @name Api#getContentManagementItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getContentManagementItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/content_managements/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'contentManagement'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putContentManagementItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/content_managements/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the ContentManagement resource.
     * @method
     * @name Api#putContentManagementItem
     * @param {string} id - 
     * @param {} contentManagement - The updated ContentManagement resource
     * Parameters
     * {
     *   'id': ,
     *   'contentManagement'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putContentManagementItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/content_managements/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['contentManagement'] !== undefined) {
                body = parameters['contentManagement'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'country'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postCountryCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/countries';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Country resource.
     * @method
     * @name Api#postCountryCollection
     * @param {} country - The new Country resource
     * Parameters
     * {
     *   'country'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postCountryCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/countries';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['country'] !== undefined) {
                body = parameters['country'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCountryItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/countries/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Country resource.
     * @method
     * @name Api#getCountryItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCountryItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/countries/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'country'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putCountryItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/countries/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Country resource.
     * @method
     * @name Api#putCountryItem
     * @param {string} id - 
     * @param {} country - The updated Country resource
     * Parameters
     * {
     *   'id': ,
     *   'country'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putCountryItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/countries/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['country'] !== undefined) {
                body = parameters['country'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteCountryItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/countries/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Country resource.
     * @method
     * @name Api#deleteCountryItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteCountryItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/countries/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'country'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveCountryItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/countries/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the Country resource.
     * @method
     * @name Api#unarchiveCountryItem
     * @param {string} id - 
     * @param {} country - The updated Country resource
     * Parameters
     * {
     *   'id': ,
     *   'country'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveCountryItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/countries/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['country'] !== undefined) {
                body = parameters['country'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'show'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getDBSCheckCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks';
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of DBSCheck resources.
     * @method
     * @name Api#getDBSCheckCollection
     * @param {string} show - Active, archived or all sectors
     * Parameters
     * {
     *   'show'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getDBSCheckCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'dBsCheck'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postDBSCheckCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a DBSCheck resource.
     * @method
     * @name Api#postDBSCheckCollection
     * @param {} dBsCheck - The new DBSCheck resource
     * Parameters
     * {
     *   'dBsCheck'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postDBSCheckCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['dBsCheck'] !== undefined) {
                body = parameters['dBsCheck'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getDBSCheckItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a DBSCheck resource.
     * @method
     * @name Api#getDBSCheckItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getDBSCheckItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'dBsCheck'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putDBSCheckItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the DBSCheck resource.
     * @method
     * @name Api#putDBSCheckItem
     * @param {string} id - 
     * @param {} dBsCheck - The updated DBSCheck resource
     * Parameters
     * {
     *   'id': ,
     *   'dBsCheck'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putDBSCheckItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['dBsCheck'] !== undefined) {
                body = parameters['dBsCheck'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteDBSCheckItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the DBSCheck resource.
     * @method
     * @name Api#deleteDBSCheckItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteDBSCheckItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'dBsCheck'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveDBSCheckItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the DBSCheck resource.
     * @method
     * @name Api#unarchiveDBSCheckItem
     * @param {string} id - 
     * @param {} dBsCheck - The updated DBSCheck resource
     * Parameters
     * {
     *   'id': ,
     *   'dBsCheck'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveDBSCheckItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/d_b_s_checks/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['dBsCheck'] !== undefined) {
                body = parameters['dBsCheck'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'file': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postDocumentCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/documents/upload';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Upload document via input field.
     * @method
     * @name Api#postDocumentCollection
     * @param {file} file - 
     * Parameters
     * {
     *   'file': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postDocumentCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/documents/upload';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['file'] !== undefined) {
                form['file'] = parameters['file'];
            }

            if (parameters['file'] === undefined) {
                reject(new Error('Missing required  parameter: file'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getDocumentItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/documents/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Document resource.
     * @method
     * @name Api#getDocumentItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getDocumentItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/documents/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteDocumentItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/documents/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Document resource.
     * @method
     * @name Api#deleteDocumentItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteDocumentItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/documents/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'emailKey'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getEmailTemplateCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/email_templates';
        if (parameters['emailKey'] !== undefined) {
            queryParameters['emailKey'] = parameters['emailKey'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of EmailTemplate resources.
     * @method
     * @name Api#getEmailTemplateCollection
     * @param {string} emailKey - 
     * Parameters
     * {
     *   'emailKey'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getEmailTemplateCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/email_templates';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['emailKey'] !== undefined) {
                queryParameters['emailKey'] = parameters['emailKey'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getEmailTemplateItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/email_templates/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a EmailTemplate resource.
     * @method
     * @name Api#getEmailTemplateItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getEmailTemplateItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/email_templates/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'emailTemplate'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putEmailTemplateItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/email_templates/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the EmailTemplate resource.
     * @method
     * @name Api#putEmailTemplateItem
     * @param {string} id - 
     * @param {} emailTemplate - The updated EmailTemplate resource
     * Parameters
     * {
     *   'id': ,
     *   'emailTemplate'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putEmailTemplateItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/email_templates/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['emailTemplate'] !== undefined) {
                body = parameters['emailTemplate'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'search'?: ,
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getFaqCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs';
        if (parameters['search'] !== undefined) {
            queryParameters['search'] = parameters['search'];
        }

        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Faq resources.
     * @method
     * @name Api#getFaqCollection
     * @param {string} search - FullTextFilter on question, answer
     * @param {string} show - Active, archived or all sectors
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'search'?: ,
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getFaqCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['search'] !== undefined) {
                queryParameters['search'] = parameters['search'];
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'faq'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postFaqCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Faq resource.
     * @method
     * @name Api#postFaqCollection
     * @param {} faq - The new Faq resource
     * Parameters
     * {
     *   'faq'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postFaqCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['faq'] !== undefined) {
                body = parameters['faq'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getFaqItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Faq resource.
     * @method
     * @name Api#getFaqItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getFaqItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'faq'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putFaqItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Faq resource.
     * @method
     * @name Api#putFaqItem
     * @param {string} id - 
     * @param {} faq - The updated Faq resource
     * Parameters
     * {
     *   'id': ,
     *   'faq'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putFaqItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['faq'] !== undefined) {
                body = parameters['faq'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteFaqItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Faq resource.
     * @method
     * @name Api#deleteFaqItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteFaqItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'faq'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveFaqItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the Faq resource.
     * @method
     * @name Api#unarchiveFaqItem
     * @param {string} id - 
     * @param {} faq - The updated Faq resource
     * Parameters
     * {
     *   'id': ,
     *   'faq'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveFaqItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/faqs/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['faq'] !== undefined) {
                body = parameters['faq'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'parentUserGroups'?: ,
     *   'parentUserGroups'?: ,
     *   'type'?: ,
     *   'type'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getGroupCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/groups';
        if (parameters['parentUserGroups'] !== undefined) {
            queryParameters['parentUserGroups'] = parameters['parentUserGroups'];
        }

        if (parameters['parentUserGroups'] !== undefined) {
            queryParameters['parentUserGroups[]'] = parameters['parentUserGroups'];
        }

        if (parameters['type'] !== undefined) {
            queryParameters['type'] = parameters['type'];
        }

        if (parameters['type'] !== undefined) {
            queryParameters['type[]'] = parameters['type'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Group resources.
     * @method
     * @name Api#getGroupCollection
     * @param {string} parentUserGroups - 
     * @param {string} parentUserGroups - 
     * @param {integer} type - 
     * @param {integer} type - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'parentUserGroups'?: ,
     *   'parentUserGroups'?: ,
     *   'type'?: ,
     *   'type'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getGroupCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/groups';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['parentUserGroups'] !== undefined) {
                queryParameters['parentUserGroups'] = parameters['parentUserGroups'];
            }

            if (parameters['parentUserGroups'] !== undefined) {
                queryParameters['parentUserGroups[]'] = parameters['parentUserGroups'];
            }

            if (parameters['type'] !== undefined) {
                queryParameters['type'] = parameters['type'];
            }

            if (parameters['type'] !== undefined) {
                queryParameters['type[]'] = parameters['type'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getGroupItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/groups/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Group resource.
     * @method
     * @name Api#getGroupItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getGroupItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/groups/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getImageCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/images';
        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Image resources.
     * @method
     * @name Api#getImageCollection
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getImageCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/images';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getImageItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/images/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Image resource.
     * @method
     * @name Api#getImageItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getImageItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/images/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'image'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putImageItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/images/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Image resource.
     * @method
     * @name Api#putImageItem
     * @param {string} id - 
     * @param {} image - The updated Image resource
     * Parameters
     * {
     *   'id': ,
     *   'image'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putImageItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/images/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['image'] !== undefined) {
                body = parameters['image'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteImageItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/images/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Image resource.
     * @method
     * @name Api#deleteImageItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteImageItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/images/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'show'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getInsuranceCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances';
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Insurance resources.
     * @method
     * @name Api#getInsuranceCollection
     * @param {string} show - Active, archived or all sectors
     * Parameters
     * {
     *   'show'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getInsuranceCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'insurance'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postInsuranceCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Insurance resource.
     * @method
     * @name Api#postInsuranceCollection
     * @param {} insurance - The new Insurance resource
     * Parameters
     * {
     *   'insurance'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postInsuranceCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['insurance'] !== undefined) {
                body = parameters['insurance'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getInsuranceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Insurance resource.
     * @method
     * @name Api#getInsuranceItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getInsuranceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'insurance'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putInsuranceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Insurance resource.
     * @method
     * @name Api#putInsuranceItem
     * @param {string} id - 
     * @param {} insurance - The updated Insurance resource
     * Parameters
     * {
     *   'id': ,
     *   'insurance'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putInsuranceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['insurance'] !== undefined) {
                body = parameters['insurance'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteInsuranceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Insurance resource.
     * @method
     * @name Api#deleteInsuranceItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteInsuranceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'insurance'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveInsuranceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the Insurance resource.
     * @method
     * @name Api#unarchiveInsuranceItem
     * @param {string} id - 
     * @param {} insurance - The updated Insurance resource
     * Parameters
     * {
     *   'id': ,
     *   'insurance'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveInsuranceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/insurances/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['insurance'] !== undefined) {
                body = parameters['insurance'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'client'?: ,
     *   'xeroInvoiceNumber'?: ,
     *   'status'?: ,
     *   'status'?: ,
     *   'purpose'?: ,
     *   'purpose'?: ,
     *   'dateBefore'?: ,
     *   'dateStrictlyBefore'?: ,
     *   'dateAfter'?: ,
     *   'dateStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getInvoiceCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/invoices';
        if (parameters['client'] !== undefined) {
            queryParameters['client'] = parameters['client'];
        }

        if (parameters['xeroInvoiceNumber'] !== undefined) {
            queryParameters['xeroInvoiceNumber'] = parameters['xeroInvoiceNumber'];
        }

        if (parameters['status'] !== undefined) {
            queryParameters['status'] = parameters['status'];
        }

        if (parameters['status'] !== undefined) {
            queryParameters['status[]'] = parameters['status'];
        }

        if (parameters['purpose'] !== undefined) {
            queryParameters['purpose'] = parameters['purpose'];
        }

        if (parameters['purpose'] !== undefined) {
            queryParameters['purpose[]'] = parameters['purpose'];
        }

        if (parameters['dateBefore'] !== undefined) {
            queryParameters['date[before]'] = parameters['dateBefore'];
        }

        if (parameters['dateStrictlyBefore'] !== undefined) {
            queryParameters['date[strictly_before]'] = parameters['dateStrictlyBefore'];
        }

        if (parameters['dateAfter'] !== undefined) {
            queryParameters['date[after]'] = parameters['dateAfter'];
        }

        if (parameters['dateStrictlyAfter'] !== undefined) {
            queryParameters['date[strictly_after]'] = parameters['dateStrictlyAfter'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Invoice resources.
     * @method
     * @name Api#getInvoiceCollection
     * @param {string} client - Client
     * @param {string} xeroInvoiceNumber - 
     * @param {string} status - 
     * @param {string} status - 
     * @param {integer} purpose - 
     * @param {integer} purpose - 
     * @param {string} dateBefore - 
     * @param {string} dateStrictlyBefore - 
     * @param {string} dateAfter - 
     * @param {string} dateStrictlyAfter - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'client'?: ,
     *   'xeroInvoiceNumber'?: ,
     *   'status'?: ,
     *   'status'?: ,
     *   'purpose'?: ,
     *   'purpose'?: ,
     *   'dateBefore'?: ,
     *   'dateStrictlyBefore'?: ,
     *   'dateAfter'?: ,
     *   'dateStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getInvoiceCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/invoices';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['client'] !== undefined) {
                queryParameters['client'] = parameters['client'];
            }

            if (parameters['xeroInvoiceNumber'] !== undefined) {
                queryParameters['xeroInvoiceNumber'] = parameters['xeroInvoiceNumber'];
            }

            if (parameters['status'] !== undefined) {
                queryParameters['status'] = parameters['status'];
            }

            if (parameters['status'] !== undefined) {
                queryParameters['status[]'] = parameters['status'];
            }

            if (parameters['purpose'] !== undefined) {
                queryParameters['purpose'] = parameters['purpose'];
            }

            if (parameters['purpose'] !== undefined) {
                queryParameters['purpose[]'] = parameters['purpose'];
            }

            if (parameters['dateBefore'] !== undefined) {
                queryParameters['date[before]'] = parameters['dateBefore'];
            }

            if (parameters['dateStrictlyBefore'] !== undefined) {
                queryParameters['date[strictly_before]'] = parameters['dateStrictlyBefore'];
            }

            if (parameters['dateAfter'] !== undefined) {
                queryParameters['date[after]'] = parameters['dateAfter'];
            }

            if (parameters['dateStrictlyAfter'] !== undefined) {
                queryParameters['date[strictly_after]'] = parameters['dateStrictlyAfter'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getInvoiceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/invoices/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Invoice resource.
     * @method
     * @name Api#getInvoiceItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getInvoiceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/invoices/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    downloadInvoiceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/invoices/{id}/download';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Invoice resource.
     * @method
     * @name Api#downloadInvoiceItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    downloadInvoiceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/invoices/{id}/download';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'search'?: ,
     *   'projectId'?: ,
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getLocationCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations';
        if (parameters['search'] !== undefined) {
            queryParameters['search'] = parameters['search'];
        }

        if (parameters['projectId'] !== undefined) {
            queryParameters['project_id'] = parameters['projectId'];
        }

        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Location resources.
     * @method
     * @name Api#getLocationCollection
     * @param {string} search - FullTextFilter on name, address, addressLine2, addressLine3, town, state
     * @param {integer} projectId - Project ID
     * @param {string} show - Active, archived or all sectors
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'search'?: ,
     *   'projectId'?: ,
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getLocationCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['search'] !== undefined) {
                queryParameters['search'] = parameters['search'];
            }

            if (parameters['projectId'] !== undefined) {
                queryParameters['project_id'] = parameters['projectId'];
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'location'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postLocationCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Location resource.
     * @method
     * @name Api#postLocationCollection
     * @param {} location - The new Location resource
     * Parameters
     * {
     *   'location'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postLocationCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['location'] !== undefined) {
                body = parameters['location'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getLocationItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Location resource.
     * @method
     * @name Api#getLocationItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getLocationItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'location'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putLocationItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Location resource.
     * @method
     * @name Api#putLocationItem
     * @param {string} id - 
     * @param {} location - The updated Location resource
     * Parameters
     * {
     *   'id': ,
     *   'location'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putLocationItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['location'] !== undefined) {
                body = parameters['location'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteLocationItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Location resource.
     * @method
     * @name Api#deleteLocationItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteLocationItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'location'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveLocationItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the Location resource.
     * @method
     * @name Api#unarchiveLocationItem
     * @param {string} id - 
     * @param {} location - The updated Location resource
     * Parameters
     * {
     *   'id': ,
     *   'location'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveLocationItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/locations/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['location'] !== undefined) {
                body = parameters['location'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'orderCompanyName'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getManagedServiceCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/managed_services';
        if (parameters['orderCompanyName'] !== undefined) {
            queryParameters['order[company.name]'] = parameters['orderCompanyName'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of ManagedService resources.
     * @method
     * @name Api#getManagedServiceCollection
     * @param {string} orderCompanyName - 
     * Parameters
     * {
     *   'orderCompanyName'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getManagedServiceCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/managed_services';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['orderCompanyName'] !== undefined) {
                queryParameters['order[company.name]'] = parameters['orderCompanyName'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'managedService'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postManagedServiceCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/managed_services';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a ManagedService resource.
     * @method
     * @name Api#postManagedServiceCollection
     * @param {} managedService - The new ManagedService resource
     * Parameters
     * {
     *   'managedService'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postManagedServiceCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/managed_services';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['managedService'] !== undefined) {
                body = parameters['managedService'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getManagedServiceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/managed_services/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a ManagedService resource.
     * @method
     * @name Api#getManagedServiceItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getManagedServiceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/managed_services/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'managedService'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putManagedServiceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/managed_services/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the ManagedService resource.
     * @method
     * @name Api#putManagedServiceItem
     * @param {string} id - 
     * @param {} managedService - The updated ManagedService resource
     * Parameters
     * {
     *   'id': ,
     *   'managedService'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putManagedServiceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/managed_services/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['managedService'] !== undefined) {
                body = parameters['managedService'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteManagedServiceItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/managed_services/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the ManagedService resource.
     * @method
     * @name Api#deleteManagedServiceItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteManagedServiceItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/managed_services/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'managedServices'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putAdminManagedServicesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/managed_services';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Test resource.
     * @method
     * @name Api#putAdminManagedServices
     * @param {} managedServices - Update the maged services list
     * Parameters
     * {
     *   'managedServices'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putAdminManagedServices(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/managed_services';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['managedServices'] !== undefined) {
                body = parameters['managedServices'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'message': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiMessagesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/messages';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Message resource.
     * @method
     * @name Api#postApiMessages
     * @param {} message - 
     * Parameters
     * {
     *   'message': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiMessages(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/messages';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['message'] !== undefined) {
                body = parameters['message'];
            }

            if (parameters['message'] === undefined) {
                reject(new Error('Missing required  parameter: message'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    lastMessageMessageCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/messages/last-message';
        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Message resources.
     * @method
     * @name Api#lastMessageMessageCollection
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    lastMessageMessageCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/messages/last-message';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getMessageItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/messages/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Message resource.
     * @method
     * @name Api#getMessageItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getMessageItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/messages/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'message'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putMessageItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/messages/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Message resource.
     * @method
     * @name Api#putMessageItem
     * @param {string} id - 
     * @param {} message - The updated Message resource
     * Parameters
     * {
     *   'id': ,
     *   'message'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putMessageItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/messages/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['message'] !== undefined) {
                body = parameters['message'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'name'?: ,
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   'consultantsProjects'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getProjectCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects';
        if (parameters['name'] !== undefined) {
            queryParameters['name'] = parameters['name'];
        }

        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters['consultantsProjects'] !== undefined) {
            queryParameters['consultantsProjects'] = parameters['consultantsProjects'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Project resources.
     * @method
     * @name Api#getProjectCollection
     * @param {string} name - 
     * @param {string} show - Active, archived or all sectors
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * @param {string} consultantsProjects - The consultantsProjects param
     * Parameters
     * {
     *   'name'?: ,
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   'consultantsProjects'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getProjectCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['name'] !== undefined) {
                queryParameters['name'] = parameters['name'];
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters['consultantsProjects'] !== undefined) {
                queryParameters['consultantsProjects'] = parameters['consultantsProjects'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'project'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postProjectCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Project resource.
     * @method
     * @name Api#postProjectCollection
     * @param {} project - The new Project resource
     * Parameters
     * {
     *   'project'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postProjectCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['project'] !== undefined) {
                body = parameters['project'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getProjectItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Project resource.
     * @method
     * @name Api#getProjectItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getProjectItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'project'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putProjectItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Project resource.
     * @method
     * @name Api#putProjectItem
     * @param {string} id - 
     * @param {} project - The updated Project resource
     * Parameters
     * {
     *   'id': ,
     *   'project'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putProjectItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['project'] !== undefined) {
                body = parameters['project'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteProjectItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Project resource.
     * @method
     * @name Api#deleteProjectItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteProjectItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'project'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveProjectItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the Project resource.
     * @method
     * @name Api#unarchiveProjectItem
     * @param {string} id - 
     * @param {} project - The updated Project resource
     * Parameters
     * {
     *   'id': ,
     *   'project'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveProjectItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['project'] !== undefined) {
                body = parameters['project'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'proposal'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postProposalCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/proposals';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Proposal resource.
     * @method
     * @name Api#postProposalCollection
     * @param {} proposal - The new Proposal resource
     * Parameters
     * {
     *   'proposal'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postProposalCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/proposals';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['proposal'] !== undefined) {
                body = parameters['proposal'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getProposalItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/proposals/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Proposal resource.
     * @method
     * @name Api#getProposalItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getProposalItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/proposals/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'proposal'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putProposalItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/proposals/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Proposal resource.
     * @method
     * @name Api#putProposalItem
     * @param {string} id - 
     * @param {} proposal - The updated Proposal resource
     * Parameters
     * {
     *   'id': ,
     *   'proposal'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putProposalItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/proposals/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['proposal'] !== undefined) {
                body = parameters['proposal'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'settingKey'?: ,
     *   'settingKey'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApplicationSettingCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/application_settings';
        if (parameters['settingKey'] !== undefined) {
            queryParameters['settingKey'] = parameters['settingKey'];
        }

        if (parameters['settingKey'] !== undefined) {
            queryParameters['settingKey[]'] = parameters['settingKey'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of ApplicationSetting resources.
     * @method
     * @name Api#getApplicationSettingCollection
     * @param {string} settingKey - 
     * @param {string} settingKey - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'settingKey'?: ,
     *   'settingKey'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApplicationSettingCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/application_settings';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['settingKey'] !== undefined) {
                queryParameters['settingKey'] = parameters['settingKey'];
            }

            if (parameters['settingKey'] !== undefined) {
                queryParameters['settingKey[]'] = parameters['settingKey'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCarouselCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/carousels';
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Carousel resources.
     * @method
     * @name Api#getCarouselCollection
     * @param {string} show - Active, archived or all sectors
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCarouselCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/carousels';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'search'?: ,
     *   'shortName'?: ,
     *   'fullName'?: ,
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCertificateTypeCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/certificate_types';
        if (parameters['search'] !== undefined) {
            queryParameters['search'] = parameters['search'];
        }

        if (parameters['shortName'] !== undefined) {
            queryParameters['shortName'] = parameters['shortName'];
        }

        if (parameters['fullName'] !== undefined) {
            queryParameters['fullName'] = parameters['fullName'];
        }

        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of CertificateType resources.
     * @method
     * @name Api#getCertificateTypeCollection
     * @param {string} search - FullTextFilter on shortName, fullName
     * @param {string} shortName - 
     * @param {string} fullName - 
     * @param {string} show - Active, archived or all sectors
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'search'?: ,
     *   'shortName'?: ,
     *   'fullName'?: ,
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCertificateTypeCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/certificate_types';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['search'] !== undefined) {
                queryParameters['search'] = parameters['search'];
            }

            if (parameters['shortName'] !== undefined) {
                queryParameters['shortName'] = parameters['shortName'];
            }

            if (parameters['fullName'] !== undefined) {
                queryParameters['fullName'] = parameters['fullName'];
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'contentKey'?: ,
     *   'contentKey'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getContentManagementCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/content_managements';
        if (parameters['contentKey'] !== undefined) {
            queryParameters['contentKey'] = parameters['contentKey'];
        }

        if (parameters['contentKey'] !== undefined) {
            queryParameters['contentKey[]'] = parameters['contentKey'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of ContentManagement resources.
     * @method
     * @name Api#getContentManagementCollection
     * @param {string} contentKey - 
     * @param {string} contentKey - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'contentKey'?: ,
     *   'contentKey'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getContentManagementCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/content_managements';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['contentKey'] !== undefined) {
                queryParameters['contentKey'] = parameters['contentKey'];
            }

            if (parameters['contentKey'] !== undefined) {
                queryParameters['contentKey[]'] = parameters['contentKey'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCountryCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/countries';
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Country resources.
     * @method
     * @name Api#getCountryCollection
     * @param {string} show - Active, archived or all sectors
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCountryCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/countries';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'file': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiPublicImageURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/image';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Upload document via input field.
     * @method
     * @name Api#postApiPublicImage
     * @param {file} file - 
     * Parameters
     * {
     *   'file': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiPublicImage(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/image';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['file'] !== undefined) {
                form['file'] = parameters['file'];
            }

            if (parameters['file'] === undefined) {
                reject(new Error('Missing required  parameter: file'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'name'?: ,
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getSectorCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/sectors';
        if (parameters['name'] !== undefined) {
            queryParameters['name'] = parameters['name'];
        }

        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Sector resources.
     * @method
     * @name Api#getSectorCollection
     * @param {string} name - 
     * @param {string} show - Active, archived or all sectors
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'name'?: ,
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getSectorCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/sectors';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['name'] !== undefined) {
                queryParameters['name'] = parameters['name'];
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'isAmendment'?: ,
     *   'orderCreatedAt'?: ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'dateAnsweredBefore'?: ,
     *   'dateAnsweredStrictlyBefore'?: ,
     *   'dateAnsweredAfter'?: ,
     *   'dateAnsweredStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getQuestionCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/questions';
        if (parameters['isAmendment'] !== undefined) {
            queryParameters['isAmendment'] = parameters['isAmendment'];
        }

        if (parameters['orderCreatedAt'] !== undefined) {
            queryParameters['order[createdAt]'] = parameters['orderCreatedAt'];
        }

        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters['dateAnsweredBefore'] !== undefined) {
            queryParameters['dateAnswered[before]'] = parameters['dateAnsweredBefore'];
        }

        if (parameters['dateAnsweredStrictlyBefore'] !== undefined) {
            queryParameters['dateAnswered[strictly_before]'] = parameters['dateAnsweredStrictlyBefore'];
        }

        if (parameters['dateAnsweredAfter'] !== undefined) {
            queryParameters['dateAnswered[after]'] = parameters['dateAnsweredAfter'];
        }

        if (parameters['dateAnsweredStrictlyAfter'] !== undefined) {
            queryParameters['dateAnswered[strictly_after]'] = parameters['dateAnsweredStrictlyAfter'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Question resources.
     * @method
     * @name Api#getQuestionCollection
     * @param {boolean} isAmendment - 
     * @param {string} orderCreatedAt - 
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * @param {string} dateAnsweredBefore - 
     * @param {string} dateAnsweredStrictlyBefore - 
     * @param {string} dateAnsweredAfter - 
     * @param {string} dateAnsweredStrictlyAfter - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'isAmendment'?: ,
     *   'orderCreatedAt'?: ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'dateAnsweredBefore'?: ,
     *   'dateAnsweredStrictlyBefore'?: ,
     *   'dateAnsweredAfter'?: ,
     *   'dateAnsweredStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getQuestionCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/questions';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['isAmendment'] !== undefined) {
                queryParameters['isAmendment'] = parameters['isAmendment'];
            }

            if (parameters['orderCreatedAt'] !== undefined) {
                queryParameters['order[createdAt]'] = parameters['orderCreatedAt'];
            }

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters['dateAnsweredBefore'] !== undefined) {
                queryParameters['dateAnswered[before]'] = parameters['dateAnsweredBefore'];
            }

            if (parameters['dateAnsweredStrictlyBefore'] !== undefined) {
                queryParameters['dateAnswered[strictly_before]'] = parameters['dateAnsweredStrictlyBefore'];
            }

            if (parameters['dateAnsweredAfter'] !== undefined) {
                queryParameters['dateAnswered[after]'] = parameters['dateAnsweredAfter'];
            }

            if (parameters['dateAnsweredStrictlyAfter'] !== undefined) {
                queryParameters['dateAnswered[strictly_after]'] = parameters['dateAnsweredStrictlyAfter'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'question'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postQuestionCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/questions';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Question resource.
     * @method
     * @name Api#postQuestionCollection
     * @param {} question - The new Question resource
     * Parameters
     * {
     *   'question'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postQuestionCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/questions';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['question'] !== undefined) {
                body = parameters['question'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'isAmendment'?: ,
     *   'orderCreatedAt'?: ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'dateAnsweredBefore'?: ,
     *   'dateAnsweredStrictlyBefore'?: ,
     *   'dateAnsweredAfter'?: ,
     *   'dateAnsweredStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    lastQuestionQuestionCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/questions/last-question';
        if (parameters['isAmendment'] !== undefined) {
            queryParameters['isAmendment'] = parameters['isAmendment'];
        }

        if (parameters['orderCreatedAt'] !== undefined) {
            queryParameters['order[createdAt]'] = parameters['orderCreatedAt'];
        }

        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters['dateAnsweredBefore'] !== undefined) {
            queryParameters['dateAnswered[before]'] = parameters['dateAnsweredBefore'];
        }

        if (parameters['dateAnsweredStrictlyBefore'] !== undefined) {
            queryParameters['dateAnswered[strictly_before]'] = parameters['dateAnsweredStrictlyBefore'];
        }

        if (parameters['dateAnsweredAfter'] !== undefined) {
            queryParameters['dateAnswered[after]'] = parameters['dateAnsweredAfter'];
        }

        if (parameters['dateAnsweredStrictlyAfter'] !== undefined) {
            queryParameters['dateAnswered[strictly_after]'] = parameters['dateAnsweredStrictlyAfter'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Question resources.
     * @method
     * @name Api#lastQuestionQuestionCollection
     * @param {boolean} isAmendment - 
     * @param {string} orderCreatedAt - 
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * @param {string} dateAnsweredBefore - 
     * @param {string} dateAnsweredStrictlyBefore - 
     * @param {string} dateAnsweredAfter - 
     * @param {string} dateAnsweredStrictlyAfter - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'isAmendment'?: ,
     *   'orderCreatedAt'?: ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'dateAnsweredBefore'?: ,
     *   'dateAnsweredStrictlyBefore'?: ,
     *   'dateAnsweredAfter'?: ,
     *   'dateAnsweredStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    lastQuestionQuestionCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/questions/last-question';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['isAmendment'] !== undefined) {
                queryParameters['isAmendment'] = parameters['isAmendment'];
            }

            if (parameters['orderCreatedAt'] !== undefined) {
                queryParameters['order[createdAt]'] = parameters['orderCreatedAt'];
            }

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters['dateAnsweredBefore'] !== undefined) {
                queryParameters['dateAnswered[before]'] = parameters['dateAnsweredBefore'];
            }

            if (parameters['dateAnsweredStrictlyBefore'] !== undefined) {
                queryParameters['dateAnswered[strictly_before]'] = parameters['dateAnsweredStrictlyBefore'];
            }

            if (parameters['dateAnsweredAfter'] !== undefined) {
                queryParameters['dateAnswered[after]'] = parameters['dateAnsweredAfter'];
            }

            if (parameters['dateAnsweredStrictlyAfter'] !== undefined) {
                queryParameters['dateAnswered[strictly_after]'] = parameters['dateAnsweredStrictlyAfter'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getQuestionItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/questions/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Question resource.
     * @method
     * @name Api#getQuestionItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getQuestionItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/questions/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'question'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putQuestionItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/questions/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Question resource.
     * @method
     * @name Api#putQuestionItem
     * @param {string} id - 
     * @param {} question - The updated Question resource
     * Parameters
     * {
     *   'id': ,
     *   'question'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putQuestionItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/questions/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['question'] !== undefined) {
                body = parameters['question'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'report'?: ,
     *   'report'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getReportDocumentCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents';
        if (parameters['report'] !== undefined) {
            queryParameters['report'] = parameters['report'];
        }

        if (parameters['report'] !== undefined) {
            queryParameters['report[]'] = parameters['report'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of ReportDocument resources.
     * @method
     * @name Api#getReportDocumentCollection
     * @param {string} report - 
     * @param {string} report - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'report'?: ,
     *   'report'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getReportDocumentCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['report'] !== undefined) {
                queryParameters['report'] = parameters['report'];
            }

            if (parameters['report'] !== undefined) {
                queryParameters['report[]'] = parameters['report'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'reportDocument'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postReportDocumentCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a ReportDocument resource.
     * @method
     * @name Api#postReportDocumentCollection
     * @param {} reportDocument - The new ReportDocument resource
     * Parameters
     * {
     *   'reportDocument'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postReportDocumentCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['reportDocument'] !== undefined) {
                body = parameters['reportDocument'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'reportDocumentIds'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    updateStatusesReportDocumentCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents/update-statuses';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the ReportDocument resource.
     * @method
     * @name Api#updateStatusesReportDocumentCollection
     * @param {} reportDocumentIds - The updated ReportDocument resource
     * Parameters
     * {
     *   'reportDocumentIds'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    updateStatusesReportDocumentCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents/update-statuses';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['reportDocumentIds'] !== undefined) {
                body = parameters['reportDocumentIds'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getReportDocumentItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a ReportDocument resource.
     * @method
     * @name Api#getReportDocumentItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getReportDocumentItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'reportDocument'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putReportDocumentItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the ReportDocument resource.
     * @method
     * @name Api#putReportDocumentItem
     * @param {string} id - 
     * @param {} reportDocument - The updated ReportDocument resource
     * Parameters
     * {
     *   'id': ,
     *   'reportDocument'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putReportDocumentItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['reportDocument'] !== undefined) {
                body = parameters['reportDocument'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    generateReportDocumentItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents/{id}/generate-report';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a ReportDocument resource.
     * @method
     * @name Api#generateReportDocumentItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    generateReportDocumentItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_documents/{id}/generate-report';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getReportCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reports';
        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Report resources.
     * @method
     * @name Api#getReportCollection
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getReportCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reports';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'report'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postReportCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reports';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Report resource.
     * @method
     * @name Api#postReportCollection
     * @param {} report - The new Report resource
     * Parameters
     * {
     *   'report'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postReportCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reports';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['report'] !== undefined) {
                body = parameters['report'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getReportItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reports/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Report resource.
     * @method
     * @name Api#getReportItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getReportItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reports/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'report'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putReportItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reports/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Report resource.
     * @method
     * @name Api#putReportItem
     * @param {string} id - 
     * @param {} report - The updated Report resource
     * Parameters
     * {
     *   'id': ,
     *   'report'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putReportItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reports/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['report'] !== undefined) {
                body = parameters['report'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'review': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiReviewsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reviews';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Review resource.
     * @method
     * @name Api#postApiReviews
     * @param {} review - 
     * Parameters
     * {
     *   'review': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiReviews(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reviews';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['review'] !== undefined) {
                body = parameters['review'];
            }

            if (parameters['review'] === undefined) {
                reject(new Error('Missing required  parameter: review'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getReviewItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reviews/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Review resource.
     * @method
     * @name Api#getReviewItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getReviewItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/reviews/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'riskTreatment'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postRiskTreatmentCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risk_treatments';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a RiskTreatment resource.
     * @method
     * @name Api#postRiskTreatmentCollection
     * @param {} riskTreatment - The new RiskTreatment resource
     * Parameters
     * {
     *   'riskTreatment'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postRiskTreatmentCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risk_treatments';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['riskTreatment'] !== undefined) {
                body = parameters['riskTreatment'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getRiskTreatmentItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risk_treatments/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a RiskTreatment resource.
     * @method
     * @name Api#getRiskTreatmentItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getRiskTreatmentItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risk_treatments/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'riskTreatment'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putRiskTreatmentItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risk_treatments/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the RiskTreatment resource.
     * @method
     * @name Api#putRiskTreatmentItem
     * @param {string} id - 
     * @param {} riskTreatment - The updated RiskTreatment resource
     * Parameters
     * {
     *   'id': ,
     *   'riskTreatment'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putRiskTreatmentItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risk_treatments/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['riskTreatment'] !== undefined) {
                body = parameters['riskTreatment'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteRiskTreatmentItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risk_treatments/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the RiskTreatment resource.
     * @method
     * @name Api#deleteRiskTreatmentItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteRiskTreatmentItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risk_treatments/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tests_risks_get_subresourceRiskCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks';
        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Risk resources.
     * @method
     * @name Api#api_tests_risks_get_subresourceRiskCollection
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * Parameters
     * {
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tests_risks_get_subresourceRiskCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'risk'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postRiskCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Risk resource.
     * @method
     * @name Api#postRiskCollection
     * @param {} risk - The new Risk resource
     * Parameters
     * {
     *   'risk'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postRiskCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['risk'] !== undefined) {
                body = parameters['risk'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    generateRiskCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks/generate-report';
        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Risk resources.
     * @method
     * @name Api#generateRiskCollection
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * Parameters
     * {
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    generateRiskCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks/generate-report';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    lastRisksRiskCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks/last-risks';
        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Risk resources.
     * @method
     * @name Api#lastRisksRiskCollection
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * Parameters
     * {
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    lastRisksRiskCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks/last-risks';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getRiskItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Risk resource.
     * @method
     * @name Api#getRiskItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getRiskItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'risk'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putRiskItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Risk resource.
     * @method
     * @name Api#putRiskItem
     * @param {string} id - 
     * @param {} risk - The updated Risk resource
     * Parameters
     * {
     *   'id': ,
     *   'risk'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putRiskItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['risk'] !== undefined) {
                body = parameters['risk'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteRiskItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Risk resource.
     * @method
     * @name Api#deleteRiskItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteRiskItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/risks/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'sector'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postSectorCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/sectors';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Sector resource.
     * @method
     * @name Api#postSectorCollection
     * @param {} sector - The new Sector resource
     * Parameters
     * {
     *   'sector'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postSectorCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/sectors';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['sector'] !== undefined) {
                body = parameters['sector'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getSectorItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/sectors/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Sector resource.
     * @method
     * @name Api#getSectorItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getSectorItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/sectors/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'sector'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putSectorItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/sectors/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Sector resource.
     * @method
     * @name Api#putSectorItem
     * @param {string} id - 
     * @param {} sector - The updated Sector resource
     * Parameters
     * {
     *   'id': ,
     *   'sector'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putSectorItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/sectors/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['sector'] !== undefined) {
                body = parameters['sector'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteSectorItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/sectors/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Sector resource.
     * @method
     * @name Api#deleteSectorItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteSectorItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/sectors/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'sector'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveSectorItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/sectors/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the Sector resource.
     * @method
     * @name Api#unarchiveSectorItem
     * @param {string} id - 
     * @param {} sector - The updated Sector resource
     * Parameters
     * {
     *   'id': ,
     *   'sector'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveSectorItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/sectors/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['sector'] !== undefined) {
                body = parameters['sector'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'file': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiSecuredImagesUploadURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/secured_images/upload';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Upload document via input field.
     * @method
     * @name Api#postApiSecuredImagesUpload
     * @param {file} file - 
     * Parameters
     * {
     *   'file': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiSecuredImagesUpload(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/secured_images/upload';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['file'] !== undefined) {
                form['file'] = parameters['file'];
            }

            if (parameters['file'] === undefined) {
                reject(new Error('Missing required  parameter: file'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getSecuredImageItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/secured_images/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a SecuredImage resource.
     * @method
     * @name Api#getSecuredImageItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getSecuredImageItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/secured_images/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getSpecialismItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/specialisms/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Specialism resource.
     * @method
     * @name Api#getSpecialismItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getSpecialismItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/specialisms/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTesterExternalCompanyCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_external_companies';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of TesterExternalCompany resources.
     * @method
     * @name Api#getTesterExternalCompanyCollection
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTesterExternalCompanyCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_external_companies';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTesterExternalCompanyItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_external_companies/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a TesterExternalCompany resource.
     * @method
     * @name Api#getTesterExternalCompanyItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTesterExternalCompanyItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_external_companies/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'testId'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTesterProfileCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles';
        if (parameters['testId'] !== undefined) {
            queryParameters['test_id'] = parameters['testId'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of TesterProfile resources.
     * @method
     * @name Api#getTesterProfileCollection
     * @param {string} testId - Test ID
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'testId'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTesterProfileCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['testId'] !== undefined) {
                queryParameters['test_id'] = parameters['testId'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'testerProfile'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postTesterProfileCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a TesterProfile resource.
     * @method
     * @name Api#postTesterProfileCollection
     * @param {} testerProfile - The new TesterProfile resource
     * Parameters
     * {
     *   'testerProfile'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postTesterProfileCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['testerProfile'] !== undefined) {
                body = parameters['testerProfile'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'searchName'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    get_favoritesTesterProfileCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/favorites';
        if (parameters['searchName'] !== undefined) {
            queryParameters['searchName'] = parameters['searchName'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Search favorite testers
     * @method
     * @name Api#get_favoritesTesterProfileCollection
     * @param {string} searchName - Search favorite testers
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'searchName'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    get_favoritesTesterProfileCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/favorites';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['searchName'] !== undefined) {
                queryParameters['searchName'] = parameters['searchName'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTesterProfileItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a TesterProfile resource.
     * @method
     * @name Api#getTesterProfileItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTesterProfileItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'testerProfile'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putTesterProfileItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the TesterProfile resource.
     * @method
     * @name Api#putTesterProfileItem
     * @param {string} id - 
     * @param {} testerProfile - The updated TesterProfile resource
     * Parameters
     * {
     *   'id': ,
     *   'testerProfile'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putTesterProfileItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['testerProfile'] !== undefined) {
                body = parameters['testerProfile'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'show'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tester_profiles_certificates_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/certificates';

        path = path.replace('{id}', `${parameters['id']}`);
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Certificate resources.
     * @method
     * @name Api#api_tester_profiles_certificates_get_subresource
     * @param {string} id - 
     * @param {string} show - Active, archived or all sectors
     * Parameters
     * {
     *   'id': ,
     *   'show'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tester_profiles_certificates_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/certificates';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'show'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tester_profiles_dbs_checks_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/dbs_checks';

        path = path.replace('{id}', `${parameters['id']}`);
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of DBSCheck resources.
     * @method
     * @name Api#api_tester_profiles_dbs_checks_get_subresource
     * @param {string} id - 
     * @param {string} show - Active, archived or all sectors
     * Parameters
     * {
     *   'id': ,
     *   'show'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tester_profiles_dbs_checks_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/dbs_checks';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'testerProfile'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    put_favoriteTesterProfileItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/favorites';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the TesterProfile resource.
     * @method
     * @name Api#put_favoriteTesterProfileItem
     * @param {string} id - 
     * @param {} testerProfile - The updated TesterProfile resource
     * Parameters
     * {
     *   'id': ,
     *   'testerProfile'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    put_favoriteTesterProfileItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/favorites';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['testerProfile'] !== undefined) {
                body = parameters['testerProfile'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    delete_favoriteTesterProfileItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/favorites';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the TesterProfile resource.
     * @method
     * @name Api#delete_favoriteTesterProfileItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    delete_favoriteTesterProfileItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/favorites';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'show'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tester_profiles_insurances_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/insurances';

        path = path.replace('{id}', `${parameters['id']}`);
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Insurance resources.
     * @method
     * @name Api#api_tester_profiles_insurances_get_subresource
     * @param {string} id - 
     * @param {string} show - Active, archived or all sectors
     * Parameters
     * {
     *   'id': ,
     *   'show'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tester_profiles_insurances_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/insurances';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tester_profiles_reviews_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/reviews';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Review resources.
     * @method
     * @name Api#api_tester_profiles_reviews_get_subresource
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tester_profiles_reviews_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/reviews';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'show'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tester_profiles_work_histories_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/work_histories';

        path = path.replace('{id}', `${parameters['id']}`);
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of WorkHistory resources.
     * @method
     * @name Api#api_tester_profiles_work_histories_get_subresource
     * @param {string} id - 
     * @param {string} show - Active, archived or all sectors
     * Parameters
     * {
     *   'id': ,
     *   'show'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tester_profiles_work_histories_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/work_histories';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'show'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tester_profiles_work_histories_get_subresource_1URL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester/{id}/work-histories';

        path = path.replace('{id}', `${parameters['id']}`);
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of WorkHistory resources.
     * @method
     * @name Api#api_tester_profiles_work_histories_get_subresource_1
     * @param {string} id - 
     * @param {string} show - Active, archived or all sectors
     * Parameters
     * {
     *   'id': ,
     *   'show'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tester_profiles_work_histories_get_subresource_1(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester/{id}/work-histories';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'projectIds'?: ,
     *   'plannerNameEmail'?: ,
     *   'planner'?: ,
     *   'dateFromBefore'?: ,
     *   'dateFromStrictlyBefore'?: ,
     *   'dateFromAfter'?: ,
     *   'dateFromStrictlyAfter'?: ,
     *   'dateToBefore'?: ,
     *   'dateToStrictlyBefore'?: ,
     *   'dateToAfter'?: ,
     *   'dateToStrictlyAfter'?: ,
     *   'statuses'?: ,
     *   'questionAsked'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   'name'?: ,
     *   'clientCompany'?: ,
     *   'testerNameEmail'?: ,
     *   'testingCompany'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTestCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests';
        if (parameters['projectIds'] !== undefined) {
            queryParameters['project_ids'] = parameters['projectIds'];
        }

        if (parameters['plannerNameEmail'] !== undefined) {
            queryParameters['plannerNameEmail'] = parameters['plannerNameEmail'];
        }

        if (parameters['planner'] !== undefined) {
            queryParameters['planner[]'] = parameters['planner'];
        }

        if (parameters['dateFromBefore'] !== undefined) {
            queryParameters['dateFrom[before]'] = parameters['dateFromBefore'];
        }

        if (parameters['dateFromStrictlyBefore'] !== undefined) {
            queryParameters['dateFrom[strictly_before]'] = parameters['dateFromStrictlyBefore'];
        }

        if (parameters['dateFromAfter'] !== undefined) {
            queryParameters['dateFrom[after]'] = parameters['dateFromAfter'];
        }

        if (parameters['dateFromStrictlyAfter'] !== undefined) {
            queryParameters['dateFrom[strictly_after]'] = parameters['dateFromStrictlyAfter'];
        }

        if (parameters['dateToBefore'] !== undefined) {
            queryParameters['dateTo[before]'] = parameters['dateToBefore'];
        }

        if (parameters['dateToStrictlyBefore'] !== undefined) {
            queryParameters['dateTo[strictly_before]'] = parameters['dateToStrictlyBefore'];
        }

        if (parameters['dateToAfter'] !== undefined) {
            queryParameters['dateTo[after]'] = parameters['dateToAfter'];
        }

        if (parameters['dateToStrictlyAfter'] !== undefined) {
            queryParameters['dateTo[strictly_after]'] = parameters['dateToStrictlyAfter'];
        }

        if (parameters['statuses'] !== undefined) {
            queryParameters['statuses'] = parameters['statuses'];
        }

        if (parameters['questionAsked'] !== undefined) {
            queryParameters['question_asked'] = parameters['questionAsked'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters['name'] !== undefined) {
            queryParameters['name'] = parameters['name'];
        }

        if (parameters['clientCompany'] !== undefined) {
            queryParameters['clientCompany'] = parameters['clientCompany'];
        }

        if (parameters['testerNameEmail'] !== undefined) {
            queryParameters['testerNameEmail'] = parameters['testerNameEmail'];
        }

        if (parameters['testingCompany'] !== undefined) {
            queryParameters['testingCompany'] = parameters['testingCompany'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Test resources.
     * @method
     * @name Api#getTestCollection
     * @param {string} projectIds - Project ID's
     * @param {string} plannerNameEmail - 
     * @param {string} planner - 
     * @param {string} dateFromBefore - 
     * @param {string} dateFromStrictlyBefore - 
     * @param {string} dateFromAfter - 
     * @param {string} dateFromStrictlyAfter - 
     * @param {string} dateToBefore - 
     * @param {string} dateToStrictlyBefore - 
     * @param {string} dateToAfter - 
     * @param {string} dateToStrictlyAfter - 
     * @param {string} statuses - Statuses
     * @param {boolean} questionAsked - Question asked
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * @param {string} name - 
     * @param {string} clientCompany - 
     * @param {integer} testerNameEmail - 
     * @param {string} testingCompany - 
     * Parameters
     * {
     *   'projectIds'?: ,
     *   'plannerNameEmail'?: ,
     *   'planner'?: ,
     *   'dateFromBefore'?: ,
     *   'dateFromStrictlyBefore'?: ,
     *   'dateFromAfter'?: ,
     *   'dateFromStrictlyAfter'?: ,
     *   'dateToBefore'?: ,
     *   'dateToStrictlyBefore'?: ,
     *   'dateToAfter'?: ,
     *   'dateToStrictlyAfter'?: ,
     *   'statuses'?: ,
     *   'questionAsked'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   'name'?: ,
     *   'clientCompany'?: ,
     *   'testerNameEmail'?: ,
     *   'testingCompany'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTestCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['projectIds'] !== undefined) {
                queryParameters['project_ids'] = parameters['projectIds'];
            }

            if (parameters['plannerNameEmail'] !== undefined) {
                queryParameters['plannerNameEmail'] = parameters['plannerNameEmail'];
            }

            if (parameters['planner'] !== undefined) {
                queryParameters['planner[]'] = parameters['planner'];
            }

            if (parameters['dateFromBefore'] !== undefined) {
                queryParameters['dateFrom[before]'] = parameters['dateFromBefore'];
            }

            if (parameters['dateFromStrictlyBefore'] !== undefined) {
                queryParameters['dateFrom[strictly_before]'] = parameters['dateFromStrictlyBefore'];
            }

            if (parameters['dateFromAfter'] !== undefined) {
                queryParameters['dateFrom[after]'] = parameters['dateFromAfter'];
            }

            if (parameters['dateFromStrictlyAfter'] !== undefined) {
                queryParameters['dateFrom[strictly_after]'] = parameters['dateFromStrictlyAfter'];
            }

            if (parameters['dateToBefore'] !== undefined) {
                queryParameters['dateTo[before]'] = parameters['dateToBefore'];
            }

            if (parameters['dateToStrictlyBefore'] !== undefined) {
                queryParameters['dateTo[strictly_before]'] = parameters['dateToStrictlyBefore'];
            }

            if (parameters['dateToAfter'] !== undefined) {
                queryParameters['dateTo[after]'] = parameters['dateToAfter'];
            }

            if (parameters['dateToStrictlyAfter'] !== undefined) {
                queryParameters['dateTo[strictly_after]'] = parameters['dateToStrictlyAfter'];
            }

            if (parameters['statuses'] !== undefined) {
                queryParameters['statuses'] = parameters['statuses'];
            }

            if (parameters['questionAsked'] !== undefined) {
                queryParameters['question_asked'] = parameters['questionAsked'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters['name'] !== undefined) {
                queryParameters['name'] = parameters['name'];
            }

            if (parameters['clientCompany'] !== undefined) {
                queryParameters['clientCompany'] = parameters['clientCompany'];
            }

            if (parameters['testerNameEmail'] !== undefined) {
                queryParameters['testerNameEmail'] = parameters['testerNameEmail'];
            }

            if (parameters['testingCompany'] !== undefined) {
                queryParameters['testingCompany'] = parameters['testingCompany'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'test'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postTestCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Test resource.
     * @method
     * @name Api#postTestCollection
     * @param {} test - The new Test resource
     * Parameters
     * {
     *   'test'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postTestCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['test'] !== undefined) {
                body = parameters['test'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'projectIds'?: ,
     *   'planner'?: ,
     *   'planner'?: ,
     *   'dateFromBefore'?: ,
     *   'dateFromStrictlyBefore'?: ,
     *   'dateFromAfter'?: ,
     *   'dateFromStrictlyAfter'?: ,
     *   'dateToBefore'?: ,
     *   'dateToStrictlyBefore'?: ,
     *   'dateToAfter'?: ,
     *   'dateToStrictlyAfter'?: ,
     *   'statuses'?: ,
     *   'questionAsked'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    complete_testsTestCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/complete-tests';
        if (parameters['projectIds'] !== undefined) {
            queryParameters['project_ids'] = parameters['projectIds'];
        }

        if (parameters['planner'] !== undefined) {
            queryParameters['planner'] = parameters['planner'];
        }

        if (parameters['planner'] !== undefined) {
            queryParameters['planner[]'] = parameters['planner'];
        }

        if (parameters['dateFromBefore'] !== undefined) {
            queryParameters['dateFrom[before]'] = parameters['dateFromBefore'];
        }

        if (parameters['dateFromStrictlyBefore'] !== undefined) {
            queryParameters['dateFrom[strictly_before]'] = parameters['dateFromStrictlyBefore'];
        }

        if (parameters['dateFromAfter'] !== undefined) {
            queryParameters['dateFrom[after]'] = parameters['dateFromAfter'];
        }

        if (parameters['dateFromStrictlyAfter'] !== undefined) {
            queryParameters['dateFrom[strictly_after]'] = parameters['dateFromStrictlyAfter'];
        }

        if (parameters['dateToBefore'] !== undefined) {
            queryParameters['dateTo[before]'] = parameters['dateToBefore'];
        }

        if (parameters['dateToStrictlyBefore'] !== undefined) {
            queryParameters['dateTo[strictly_before]'] = parameters['dateToStrictlyBefore'];
        }

        if (parameters['dateToAfter'] !== undefined) {
            queryParameters['dateTo[after]'] = parameters['dateToAfter'];
        }

        if (parameters['dateToStrictlyAfter'] !== undefined) {
            queryParameters['dateTo[strictly_after]'] = parameters['dateToStrictlyAfter'];
        }

        if (parameters['statuses'] !== undefined) {
            queryParameters['statuses'] = parameters['statuses'];
        }

        if (parameters['questionAsked'] !== undefined) {
            queryParameters['question_asked'] = parameters['questionAsked'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Test resources.
     * @method
     * @name Api#complete_testsTestCollection
     * @param {string} projectIds - Project ID's
     * @param {string} planner - 
     * @param {string} planner - 
     * @param {string} dateFromBefore - 
     * @param {string} dateFromStrictlyBefore - 
     * @param {string} dateFromAfter - 
     * @param {string} dateFromStrictlyAfter - 
     * @param {string} dateToBefore - 
     * @param {string} dateToStrictlyBefore - 
     * @param {string} dateToAfter - 
     * @param {string} dateToStrictlyAfter - 
     * @param {string} statuses - Statuses
     * @param {boolean} questionAsked - Question asked
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'projectIds'?: ,
     *   'planner'?: ,
     *   'planner'?: ,
     *   'dateFromBefore'?: ,
     *   'dateFromStrictlyBefore'?: ,
     *   'dateFromAfter'?: ,
     *   'dateFromStrictlyAfter'?: ,
     *   'dateToBefore'?: ,
     *   'dateToStrictlyBefore'?: ,
     *   'dateToAfter'?: ,
     *   'dateToStrictlyAfter'?: ,
     *   'statuses'?: ,
     *   'questionAsked'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    complete_testsTestCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/complete-tests';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['projectIds'] !== undefined) {
                queryParameters['project_ids'] = parameters['projectIds'];
            }

            if (parameters['planner'] !== undefined) {
                queryParameters['planner'] = parameters['planner'];
            }

            if (parameters['planner'] !== undefined) {
                queryParameters['planner[]'] = parameters['planner'];
            }

            if (parameters['dateFromBefore'] !== undefined) {
                queryParameters['dateFrom[before]'] = parameters['dateFromBefore'];
            }

            if (parameters['dateFromStrictlyBefore'] !== undefined) {
                queryParameters['dateFrom[strictly_before]'] = parameters['dateFromStrictlyBefore'];
            }

            if (parameters['dateFromAfter'] !== undefined) {
                queryParameters['dateFrom[after]'] = parameters['dateFromAfter'];
            }

            if (parameters['dateFromStrictlyAfter'] !== undefined) {
                queryParameters['dateFrom[strictly_after]'] = parameters['dateFromStrictlyAfter'];
            }

            if (parameters['dateToBefore'] !== undefined) {
                queryParameters['dateTo[before]'] = parameters['dateToBefore'];
            }

            if (parameters['dateToStrictlyBefore'] !== undefined) {
                queryParameters['dateTo[strictly_before]'] = parameters['dateToStrictlyBefore'];
            }

            if (parameters['dateToAfter'] !== undefined) {
                queryParameters['dateTo[after]'] = parameters['dateToAfter'];
            }

            if (parameters['dateToStrictlyAfter'] !== undefined) {
                queryParameters['dateTo[strictly_after]'] = parameters['dateToStrictlyAfter'];
            }

            if (parameters['statuses'] !== undefined) {
                queryParameters['statuses'] = parameters['statuses'];
            }

            if (parameters['questionAsked'] !== undefined) {
                queryParameters['question_asked'] = parameters['questionAsked'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'test'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    exportTestCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/export';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Test resource.
     * @method
     * @name Api#exportTestCollection
     * @param {} test - The new Test resource
     * Parameters
     * {
     *   'test'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    exportTestCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/export';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['test'] !== undefined) {
                body = parameters['test'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'projectIds'?: ,
     *   'planner'?: ,
     *   'planner'?: ,
     *   'dateFromBefore'?: ,
     *   'dateFromStrictlyBefore'?: ,
     *   'dateFromAfter'?: ,
     *   'dateFromStrictlyAfter'?: ,
     *   'dateToBefore'?: ,
     *   'dateToStrictlyBefore'?: ,
     *   'dateToAfter'?: ,
     *   'dateToStrictlyAfter'?: ,
     *   'statuses'?: ,
     *   'questionAsked'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    lastReportsTestCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/last-reports';
        if (parameters['projectIds'] !== undefined) {
            queryParameters['project_ids'] = parameters['projectIds'];
        }

        if (parameters['planner'] !== undefined) {
            queryParameters['planner'] = parameters['planner'];
        }

        if (parameters['planner'] !== undefined) {
            queryParameters['planner[]'] = parameters['planner'];
        }

        if (parameters['dateFromBefore'] !== undefined) {
            queryParameters['dateFrom[before]'] = parameters['dateFromBefore'];
        }

        if (parameters['dateFromStrictlyBefore'] !== undefined) {
            queryParameters['dateFrom[strictly_before]'] = parameters['dateFromStrictlyBefore'];
        }

        if (parameters['dateFromAfter'] !== undefined) {
            queryParameters['dateFrom[after]'] = parameters['dateFromAfter'];
        }

        if (parameters['dateFromStrictlyAfter'] !== undefined) {
            queryParameters['dateFrom[strictly_after]'] = parameters['dateFromStrictlyAfter'];
        }

        if (parameters['dateToBefore'] !== undefined) {
            queryParameters['dateTo[before]'] = parameters['dateToBefore'];
        }

        if (parameters['dateToStrictlyBefore'] !== undefined) {
            queryParameters['dateTo[strictly_before]'] = parameters['dateToStrictlyBefore'];
        }

        if (parameters['dateToAfter'] !== undefined) {
            queryParameters['dateTo[after]'] = parameters['dateToAfter'];
        }

        if (parameters['dateToStrictlyAfter'] !== undefined) {
            queryParameters['dateTo[strictly_after]'] = parameters['dateToStrictlyAfter'];
        }

        if (parameters['statuses'] !== undefined) {
            queryParameters['statuses'] = parameters['statuses'];
        }

        if (parameters['questionAsked'] !== undefined) {
            queryParameters['question_asked'] = parameters['questionAsked'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Test resources.
     * @method
     * @name Api#lastReportsTestCollection
     * @param {string} projectIds - Project ID's
     * @param {string} planner - 
     * @param {string} planner - 
     * @param {string} dateFromBefore - 
     * @param {string} dateFromStrictlyBefore - 
     * @param {string} dateFromAfter - 
     * @param {string} dateFromStrictlyAfter - 
     * @param {string} dateToBefore - 
     * @param {string} dateToStrictlyBefore - 
     * @param {string} dateToAfter - 
     * @param {string} dateToStrictlyAfter - 
     * @param {string} statuses - Statuses
     * @param {boolean} questionAsked - Question asked
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'projectIds'?: ,
     *   'planner'?: ,
     *   'planner'?: ,
     *   'dateFromBefore'?: ,
     *   'dateFromStrictlyBefore'?: ,
     *   'dateFromAfter'?: ,
     *   'dateFromStrictlyAfter'?: ,
     *   'dateToBefore'?: ,
     *   'dateToStrictlyBefore'?: ,
     *   'dateToAfter'?: ,
     *   'dateToStrictlyAfter'?: ,
     *   'statuses'?: ,
     *   'questionAsked'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    lastReportsTestCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/last-reports';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['projectIds'] !== undefined) {
                queryParameters['project_ids'] = parameters['projectIds'];
            }

            if (parameters['planner'] !== undefined) {
                queryParameters['planner'] = parameters['planner'];
            }

            if (parameters['planner'] !== undefined) {
                queryParameters['planner[]'] = parameters['planner'];
            }

            if (parameters['dateFromBefore'] !== undefined) {
                queryParameters['dateFrom[before]'] = parameters['dateFromBefore'];
            }

            if (parameters['dateFromStrictlyBefore'] !== undefined) {
                queryParameters['dateFrom[strictly_before]'] = parameters['dateFromStrictlyBefore'];
            }

            if (parameters['dateFromAfter'] !== undefined) {
                queryParameters['dateFrom[after]'] = parameters['dateFromAfter'];
            }

            if (parameters['dateFromStrictlyAfter'] !== undefined) {
                queryParameters['dateFrom[strictly_after]'] = parameters['dateFromStrictlyAfter'];
            }

            if (parameters['dateToBefore'] !== undefined) {
                queryParameters['dateTo[before]'] = parameters['dateToBefore'];
            }

            if (parameters['dateToStrictlyBefore'] !== undefined) {
                queryParameters['dateTo[strictly_before]'] = parameters['dateToStrictlyBefore'];
            }

            if (parameters['dateToAfter'] !== undefined) {
                queryParameters['dateTo[after]'] = parameters['dateToAfter'];
            }

            if (parameters['dateToStrictlyAfter'] !== undefined) {
                queryParameters['dateTo[strictly_after]'] = parameters['dateToStrictlyAfter'];
            }

            if (parameters['statuses'] !== undefined) {
                queryParameters['statuses'] = parameters['statuses'];
            }

            if (parameters['questionAsked'] !== undefined) {
                queryParameters['question_asked'] = parameters['questionAsked'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTestItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Test resource.
     * @method
     * @name Api#getTestItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTestItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'test'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putTestItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Test resource.
     * @method
     * @name Api#putTestItem
     * @param {string} id - 
     * @param {} test - The updated Test resource
     * Parameters
     * {
     *   'id': ,
     *   'test'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putTestItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['test'] !== undefined) {
                body = parameters['test'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteTestItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the Test resource.
     * @method
     * @name Api#deleteTestItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteTestItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tests_additional_services_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/additional_services';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of AdditionalService resources.
     * @method
     * @name Api#api_tests_additional_services_get_subresource
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tests_additional_services_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/additional_services';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'test'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    change_statusTestItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/change-status';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Test resource.
     * @method
     * @name Api#change_statusTestItem
     * @param {string} id - 
     * @param {} test - The updated Test resource
     * Parameters
     * {
     *   'id': ,
     *   'test'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    change_statusTestItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/change-status';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['test'] !== undefined) {
                body = parameters['test'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    generateTestItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/generate-report';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Test resource.
     * @method
     * @name Api#generateTestItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    generateTestItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/generate-report';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    invoice_linkTestItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/invoice-link';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Test resource.
     * @method
     * @name Api#invoice_linkTestItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    invoice_linkTestItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/invoice-link';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tests_messages_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/messages';

        path = path.replace('{id}', `${parameters['id']}`);
        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Message resources.
     * @method
     * @name Api#api_tests_messages_get_subresource
     * @param {string} id - 
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * Parameters
     * {
     *   'id': ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tests_messages_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/messages';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'isAmendment'?: ,
     *   'orderCreatedAt'?: ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'dateAnsweredBefore'?: ,
     *   'dateAnsweredStrictlyBefore'?: ,
     *   'dateAnsweredAfter'?: ,
     *   'dateAnsweredStrictlyAfter'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tests_questions_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/questions';

        path = path.replace('{id}', `${parameters['id']}`);
        if (parameters['isAmendment'] !== undefined) {
            queryParameters['isAmendment'] = parameters['isAmendment'];
        }

        if (parameters['orderCreatedAt'] !== undefined) {
            queryParameters['order[createdAt]'] = parameters['orderCreatedAt'];
        }

        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters['dateAnsweredBefore'] !== undefined) {
            queryParameters['dateAnswered[before]'] = parameters['dateAnsweredBefore'];
        }

        if (parameters['dateAnsweredStrictlyBefore'] !== undefined) {
            queryParameters['dateAnswered[strictly_before]'] = parameters['dateAnsweredStrictlyBefore'];
        }

        if (parameters['dateAnsweredAfter'] !== undefined) {
            queryParameters['dateAnswered[after]'] = parameters['dateAnsweredAfter'];
        }

        if (parameters['dateAnsweredStrictlyAfter'] !== undefined) {
            queryParameters['dateAnswered[strictly_after]'] = parameters['dateAnsweredStrictlyAfter'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Question resources.
     * @method
     * @name Api#api_tests_questions_get_subresource
     * @param {string} id - 
     * @param {boolean} isAmendment - 
     * @param {string} orderCreatedAt - 
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * @param {string} dateAnsweredBefore - 
     * @param {string} dateAnsweredStrictlyBefore - 
     * @param {string} dateAnsweredAfter - 
     * @param {string} dateAnsweredStrictlyAfter - 
     * Parameters
     * {
     *   'id': ,
     *   'isAmendment'?: ,
     *   'orderCreatedAt'?: ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'dateAnsweredBefore'?: ,
     *   'dateAnsweredStrictlyBefore'?: ,
     *   'dateAnsweredAfter'?: ,
     *   'dateAnsweredStrictlyAfter'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tests_questions_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/questions';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['isAmendment'] !== undefined) {
                queryParameters['isAmendment'] = parameters['isAmendment'];
            }

            if (parameters['orderCreatedAt'] !== undefined) {
                queryParameters['order[createdAt]'] = parameters['orderCreatedAt'];
            }

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters['dateAnsweredBefore'] !== undefined) {
                queryParameters['dateAnswered[before]'] = parameters['dateAnsweredBefore'];
            }

            if (parameters['dateAnsweredStrictlyBefore'] !== undefined) {
                queryParameters['dateAnswered[strictly_before]'] = parameters['dateAnsweredStrictlyBefore'];
            }

            if (parameters['dateAnsweredAfter'] !== undefined) {
                queryParameters['dateAnswered[after]'] = parameters['dateAnsweredAfter'];
            }

            if (parameters['dateAnsweredStrictlyAfter'] !== undefined) {
                queryParameters['dateAnswered[strictly_after]'] = parameters['dateAnsweredStrictlyAfter'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tests_report_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/report';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Report resource.
     * @method
     * @name Api#api_tests_report_get_subresource
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tests_report_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/report';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'test'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    reset_messagesTestItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/reset-messages';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Test resource.
     * @method
     * @name Api#reset_messagesTestItem
     * @param {string} id - 
     * @param {} test - The updated Test resource
     * Parameters
     * {
     *   'id': ,
     *   'test'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    reset_messagesTestItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/reset-messages';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['test'] !== undefined) {
                body = parameters['test'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'test'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    reset_questionsTestItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/reset-questions';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Test resource.
     * @method
     * @name Api#reset_questionsTestItem
     * @param {string} id - 
     * @param {} test - The updated Test resource
     * Parameters
     * {
     *   'id': ,
     *   'test'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    reset_questionsTestItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/reset-questions';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['test'] !== undefined) {
                body = parameters['test'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tests_risks_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/risks';

        path = path.replace('{id}', `${parameters['id']}`);
        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Risk resources.
     * @method
     * @name Api#api_tests_risks_get_subresource
     * @param {string} id - 
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * Parameters
     * {
     *   'id': ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tests_risks_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/risks';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tests_sow_documents_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/sow_documents';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Document resources.
     * @method
     * @name Api#api_tests_sow_documents_get_subresource
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tests_sow_documents_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/sow_documents';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'test'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    set_feedback_sentTestItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/set-feedback';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Test resource.
     * @method
     * @name Api#set_feedback_sentTestItem
     * @param {string} id - 
     * @param {} test - The updated Test resource
     * Parameters
     * {
     *   'id': ,
     *   'test'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    set_feedback_sentTestItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/set-feedback';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['test'] !== undefined) {
                body = parameters['test'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_tests_test_documents_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/test_documents';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Document resources.
     * @method
     * @name Api#api_tests_test_documents_get_subresource
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_tests_test_documents_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/{id}/test_documents';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'search'?: ,
     *   'nameEmailPhone'?: ,
     *   'consultantProject'?: ,
     *   'email'?: ,
     *   'phone'?: ,
     *   'userFilter'?: ,
     *   'userDbsFilter'?: ,
     *   'show'?: ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   'userTestingCompanyFilter'?: ,
     *   'userClientCompanyFilter'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getUserCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users';
        if (parameters['search'] !== undefined) {
            queryParameters['search'] = parameters['search'];
        }

        if (parameters['nameEmailPhone'] !== undefined) {
            queryParameters['nameEmailPhone'] = parameters['nameEmailPhone'];
        }

        if (parameters['consultantProject'] !== undefined) {
            queryParameters['consultantProject'] = parameters['consultantProject'];
        }

        if (parameters['email'] !== undefined) {
            queryParameters['email'] = parameters['email'];
        }

        if (parameters['phone'] !== undefined) {
            queryParameters['phone'] = parameters['phone'];
        }

        if (parameters['userFilter'] !== undefined) {
            queryParameters['userFilter'] = parameters['userFilter'];
        }

        if (parameters['userDbsFilter'] !== undefined) {
            queryParameters['user_dbs_filter'] = parameters['userDbsFilter'];
        }

        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters['userTestingCompanyFilter'] !== undefined) {
            queryParameters['userTestingCompanyFilter'] = parameters['userTestingCompanyFilter'];
        }

        if (parameters['userClientCompanyFilter'] !== undefined) {
            queryParameters['userClientCompanyFilter'] = parameters['userClientCompanyFilter'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of User resources.
     * @method
     * @name Api#getUserCollection
     * @param {string} search - FullTextFilter on firstName, lastName
     * @param {string} nameEmailPhone - User Name, Email or phone
     * @param {string} consultantProject - Consultant project id
     * @param {string} email - 
     * @param {string} phone - 
     * @param {string} userFilter - Admin, tester or client users
     * @param {boolean} userDbsFilter - Enable DBS check
     * @param {string} show - Active, archived or all sectors
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * @param {string} userTestingCompanyFilter - Company id for filter
     * @param {string} userClientCompanyFilter - Company id for filter
     * Parameters
     * {
     *   'search'?: ,
     *   'nameEmailPhone'?: ,
     *   'consultantProject'?: ,
     *   'email'?: ,
     *   'phone'?: ,
     *   'userFilter'?: ,
     *   'userDbsFilter'?: ,
     *   'show'?: ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   'userTestingCompanyFilter'?: ,
     *   'userClientCompanyFilter'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getUserCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['search'] !== undefined) {
                queryParameters['search'] = parameters['search'];
            }

            if (parameters['nameEmailPhone'] !== undefined) {
                queryParameters['nameEmailPhone'] = parameters['nameEmailPhone'];
            }

            if (parameters['consultantProject'] !== undefined) {
                queryParameters['consultantProject'] = parameters['consultantProject'];
            }

            if (parameters['email'] !== undefined) {
                queryParameters['email'] = parameters['email'];
            }

            if (parameters['phone'] !== undefined) {
                queryParameters['phone'] = parameters['phone'];
            }

            if (parameters['userFilter'] !== undefined) {
                queryParameters['userFilter'] = parameters['userFilter'];
            }

            if (parameters['userDbsFilter'] !== undefined) {
                queryParameters['user_dbs_filter'] = parameters['userDbsFilter'];
            }

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters['userTestingCompanyFilter'] !== undefined) {
                queryParameters['userTestingCompanyFilter'] = parameters['userTestingCompanyFilter'];
            }

            if (parameters['userClientCompanyFilter'] !== undefined) {
                queryParameters['userClientCompanyFilter'] = parameters['userClientCompanyFilter'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'user'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postUserCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a User resource.
     * @method
     * @name Api#postUserCollection
     * @param {} user - The new User resource
     * Parameters
     * {
     *   'user'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postUserCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['user'] !== undefined) {
                body = parameters['user'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTimesheetManagersCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/timesheet-managers';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of managers resources.
     * @method
     * @name Api#getTimesheetManagersCollection
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTimesheetManagersCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/timesheet-managers';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getUserItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a User resource.
     * @method
     * @name Api#getUserItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getUserItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'user'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putUserItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the User resource.
     * @method
     * @name Api#putUserItem
     * @param {string} id - 
     * @param {} user - The updated User resource
     * Parameters
     * {
     *   'id': ,
     *   'user'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putUserItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['user'] !== undefined) {
                body = parameters['user'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteUserItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the User resource.
     * @method
     * @name Api#deleteUserItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteUserItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'user'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    archiveUserItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}/archive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the User resource.
     * @method
     * @name Api#archiveUserItem
     * @param {string} id - 
     * @param {} user - The updated User resource
     * Parameters
     * {
     *   'id': ,
     *   'user'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    archiveUserItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}/archive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['user'] !== undefined) {
                body = parameters['user'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'user'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveUserItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the User resource.
     * @method
     * @name Api#unarchiveUserItem
     * @param {string} id - 
     * @param {} user - The updated User resource
     * Parameters
     * {
     *   'id': ,
     *   'user'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveUserItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['user'] !== undefined) {
                body = parameters['user'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'show'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getWorkHistoryCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories';
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of WorkHistory resources.
     * @method
     * @name Api#getWorkHistoryCollection
     * @param {string} show - Active, archived or all sectors
     * Parameters
     * {
     *   'show'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getWorkHistoryCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'workHistory'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postWorkHistoryCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a WorkHistory resource.
     * @method
     * @name Api#postWorkHistoryCollection
     * @param {} workHistory - The new WorkHistory resource
     * Parameters
     * {
     *   'workHistory'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postWorkHistoryCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['workHistory'] !== undefined) {
                body = parameters['workHistory'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getWorkHistoryItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a WorkHistory resource.
     * @method
     * @name Api#getWorkHistoryItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getWorkHistoryItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'workHistory'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putWorkHistoryItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the WorkHistory resource.
     * @method
     * @name Api#putWorkHistoryItem
     * @param {string} id - 
     * @param {} workHistory - The updated WorkHistory resource
     * Parameters
     * {
     *   'id': ,
     *   'workHistory'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putWorkHistoryItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['workHistory'] !== undefined) {
                body = parameters['workHistory'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteWorkHistoryItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Removes the WorkHistory resource.
     * @method
     * @name Api#deleteWorkHistoryItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteWorkHistoryItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'workHistory'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveWorkHistoryItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the WorkHistory resource.
     * @method
     * @name Api#unarchiveWorkHistoryItem
     * @param {string} id - 
     * @param {} workHistory - The updated WorkHistory resource
     * Parameters
     * {
     *   'id': ,
     *   'workHistory'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveWorkHistoryItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/work_histories/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['workHistory'] !== undefined) {
                body = parameters['workHistory'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiAccountDeleteURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/account/delete';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Request account deletion.
     * @method
     * @name Api#postApiAccountDelete
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiAccountDelete(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/account/delete';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'email'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    sendInviteToTesterURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/testing_company_manager/invite/send';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Request send invite
     * @method
     * @name Api#sendInviteToTester
     * @param {} email - Tester email to send invitation
     * Parameters
     * {
     *   'email'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    sendInviteToTester(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/testing_company_manager/invite/send';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['email'] !== undefined) {
                body = parameters['email'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'token': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    sendInviteToCompanyConfirmationURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/testing_company_manager/confirm/invitation/{token}';

        path = path.replace('{token}', `${parameters['token']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     *  Sends invite To Company Confirmation
     * @method
     * @name Api#sendInviteToCompanyConfirmation
     * @param {string} token - Submit token
     * Parameters
     * {
     *   'token': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    sendInviteToCompanyConfirmation(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/testing_company_manager/confirm/invitation/{token}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{token}', `${parameters['token']}`);

            if (parameters['token'] === undefined) {
                reject(new Error('Missing required  parameter: token'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'model': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    registerTesterByManagerURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/register/tester-by-manager';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * register tester profile by testing company manager
     * @method
     * @name Api#registerTesterByManager
     * @param {} model - new Tester account model
     * Parameters
     * {
     *   'model': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    registerTesterByManager(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/register/tester-by-manager';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['model'] !== undefined) {
                body = parameters['model'];
            }

            if (parameters['model'] === undefined) {
                reject(new Error('Missing required  parameter: model'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'testerProfileId': ,
     *   'availableForBooking'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    changeReceiveBookingRequestsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{testerProfileId}/available-for-booking';

        path = path.replace('{testerProfileId}', `${parameters['testerProfileId']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * add or remove tester permissions for Testing Company manager
     * @method
     * @name Api#changeReceiveBookingRequests
     * @param {string} testerProfileId - Tester profile id
     * @param {} availableForBooking - available for booking flag
     * Parameters
     * {
     *   'testerProfileId': ,
     *   'availableForBooking'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    changeReceiveBookingRequests(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{testerProfileId}/available-for-booking';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{testerProfileId}', `${parameters['testerProfileId']}`);

            if (parameters['testerProfileId'] === undefined) {
                reject(new Error('Missing required  parameter: testerProfileId'));
                return;
            }

            if (parameters['availableForBooking'] !== undefined) {
                body = parameters['availableForBooking'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'testerId': ,
     *   'testingCompany'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    createCompanyManagerURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{testerId}/testing-company-manager';

        path = path.replace('{testerId}', `${parameters['testerId']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * create Company Manager
     * @method
     * @name Api#createCompanyManager
     * @param {string} testerId - Tester profile id
     * @param {} testingCompany - testing company name
     * Parameters
     * {
     *   'testerId': ,
     *   'testingCompany'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    createCompanyManager(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{testerId}/testing-company-manager';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{testerId}', `${parameters['testerId']}`);

            if (parameters['testerId'] === undefined) {
                reject(new Error('Missing required  parameter: testerId'));
                return;
            }

            if (parameters['testingCompany'] !== undefined) {
                body = parameters['testingCompany'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'testerId': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteCompanyManagerURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{testerId}/testing-company-manager';

        path = path.replace('{testerId}', `${parameters['testerId']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * delete Company Manager
     * @method
     * @name Api#deleteCompanyManager
     * @param {string} testerId - Tester profile id
     * Parameters
     * {
     *   'testerId': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteCompanyManager(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{testerId}/testing-company-manager';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{testerId}', `${parameters['testerId']}`);

            if (parameters['testerId'] === undefined) {
                reject(new Error('Missing required  parameter: testerId'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    sendReset2FAURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}/reset-2fa';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * create Company Manager
     * @method
     * @name Api#sendReset2FA
     * @param {string} id - user id
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    sendReset2FA(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/{id}/reset-2fa';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getDynamicPermissionsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/permissions/extra/planner';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * get planners extraPermissions
     * @method
     * @name Api#getDynamicPermissions
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getDynamicPermissions(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/permissions/extra/planner';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTestingCompaniesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/testing_companies';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * get Testing Companies
     * @method
     * @name Api#getTestingCompanies
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTestingCompanies(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/testing_companies';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTestingCompanyItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/testing_companies/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a TestingCompany resource.
     * @method
     * @name Api#getTestingCompanyItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTestingCompanyItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/testing_companies/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'clientAccountId': ,
     *   'model'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    updateClientByAdminURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/client_account/{clientAccountId}';

        path = path.replace('{clientAccountId}', `${parameters['clientAccountId']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * update client by admin
     * @method
     * @name Api#updateClientByAdmin
     * @param {string} clientAccountId - client id
     * @param {} model - client account model
     * Parameters
     * {
     *   'clientAccountId': ,
     *   'model'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    updateClientByAdmin(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/client_account/{clientAccountId}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{clientAccountId}', `${parameters['clientAccountId']}`);

            if (parameters['clientAccountId'] === undefined) {
                reject(new Error('Missing required  parameter: clientAccountId'));
                return;
            }

            if (parameters['model'] !== undefined) {
                body = parameters['model'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unlinkTesterURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/testers/{id}/remove-from-company';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * unlink Tester from company
     * @method
     * @name Api#unlinkTester
     * @param {string} id - user id
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unlinkTester(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/testers/{id}/remove-from-company';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'dateFrom'?: ,
     *   'dateTo'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiChartsTestsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/charts/tests';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get tests data for charts
     * @method
     * @name Api#getApiChartsTests
     * @param {query} dateFrom - First day for getting data for charts
     * @param {query} dateTo - Last day for getting data for charts
     * Parameters
     * {
     *   'dateFrom'?: ,
     *   'dateTo'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiChartsTests(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/charts/tests';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'dateFrom'?: ,
     *   'dateTo'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiChartsProjectsCostURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/charts/projects-cost';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get projects cost
     * @method
     * @name Api#getApiChartsProjectsCost
     * @param {query} dateFrom - First day for getting data of project cost
     * @param {query} dateTo - Last day for getting data of project cost
     * Parameters
     * {
     *   'dateFrom'?: ,
     *   'dateTo'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiChartsProjectsCost(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/charts/projects-cost';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiClientAccountURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/client_account';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Client account data.
     * @method
     * @name Api#getApiClientAccount
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiClientAccount(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/client_account';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'accountData': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApiClientAccountURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/client_account';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Client account data.
     * @method
     * @name Api#putApiClientAccount
     * @param {} accountData - 
     * Parameters
     * {
     *   'accountData': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApiClientAccount(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/client_account';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['accountData'] !== undefined) {
                body = parameters['accountData'];
            }

            if (parameters['accountData'] === undefined) {
                reject(new Error('Missing required  parameter: accountData'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiCompanyDetailsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company/details';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieve a Company details
     * @method
     * @name Api#getApiCompanyDetails
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiCompanyDetails(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company/details';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'companyDetails': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApiCompanyDetailsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company/details';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the Company details.
     * @method
     * @name Api#putApiCompanyDetails
     * @param {} companyDetails - 
     * Parameters
     * {
     *   'companyDetails': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApiCompanyDetails(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company/details';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['companyDetails'] !== undefined) {
                body = parameters['companyDetails'];
            }

            if (parameters['companyDetails'] === undefined) {
                reject(new Error('Missing required  parameter: companyDetails'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'contactUsData': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiPublicContactUsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/contact_us';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Send Contact Us form data.
     * @method
     * @name Api#postApiPublicContactUs
     * @param {} contactUsData - 
     * Parameters
     * {
     *   'contactUsData': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiPublicContactUs(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/contact_us';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'multipart/form-data';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['contactUsData'] !== undefined) {
                body = parameters['contactUsData'];
            }

            if (parameters['contactUsData'] === undefined) {
                reject(new Error('Missing required  parameter: contactUsData'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiAdminFinancesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/finances';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of Finance resource.
     * @method
     * @name Api#getApiAdminFinances
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiAdminFinances(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/finances';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'finances': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApiAdminFinancesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/finances';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Update the Finance resource.
     * @method
     * @name Api#putApiAdminFinances
     * @param {} finances - 
     * Parameters
     * {
     *   'finances': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApiAdminFinances(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/finances';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['finances'] !== undefined) {
                body = parameters['finances'];
            }

            if (parameters['finances'] === undefined) {
                reject(new Error('Missing required  parameter: finances'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'key': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiAdminFinancesByKeyURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/finances/{key}';

        path = path.replace('{key}', `${parameters['key']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the value for the Finance key.
     * @method
     * @name Api#getApiAdminFinancesByKey
     * @param {string} key - 
     * Parameters
     * {
     *   'key': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiAdminFinancesByKey(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/admin/finances/{key}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{key}', `${parameters['key']}`);

            if (parameters['key'] === undefined) {
                reject(new Error('Missing required  parameter: key'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'username': ,
     *   'password': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiTokenURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/token';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Returns JWT token.
     * @method
     * @name Api#postApiToken
     * @param {string} username - User email
     * @param {string} password - User password
     * Parameters
     * {
     *   'username': ,
     *   'password': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiToken(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/token';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['username'] !== undefined) {
                form['_username'] = parameters['username'];
            }

            if (parameters['username'] === undefined) {
                reject(new Error('Missing required  parameter: username'));
                return;
            }

            if (parameters['password'] !== undefined) {
                form['_password'] = parameters['password'];
            }

            if (parameters['password'] === undefined) {
                reject(new Error('Missing required  parameter: password'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'refreshToken': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiTokenRefreshURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/token/refresh';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Returns JWT token.
     * @method
     * @name Api#postApiTokenRefresh
     * @param {string} refreshToken - Refresh token
     * Parameters
     * {
     *   'refreshToken': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiTokenRefresh(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/token/refresh';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['refreshToken'] !== undefined) {
                form['refresh_token'] = parameters['refreshToken'];
            }

            if (parameters['refreshToken'] === undefined) {
                reject(new Error('Missing required  parameter: refreshToken'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'refreshToken': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiTokenInvalidateURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/token/invalidate';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Invalidate JWT Refresh token.
     * @method
     * @name Api#postApiTokenInvalidate
     * @param {string} refreshToken - Refresh token
     * Parameters
     * {
     *   'refreshToken': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiTokenInvalidate(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/token/invalidate';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['refreshToken'] !== undefined) {
                form['refresh_token'] = parameters['refreshToken'];
            }

            if (parameters['refreshToken'] === undefined) {
                reject(new Error('Missing required  parameter: refreshToken'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiTwoFactorAuthInitURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/two-factor-auth/init';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Check two factor authentication code.
     * @method
     * @name Api#getApiTwoFactorAuthInit
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiTwoFactorAuthInit(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/two-factor-auth/init';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'twoFaDigitCode': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiTwoFactorAuthCheckURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/two-factor-auth/check';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Check two factor authentication code.
     * @method
     * @name Api#postApiTwoFactorAuthCheck
     * @param {integer} twoFaDigitCode - Code
     * Parameters
     * {
     *   'twoFaDigitCode': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiTwoFactorAuthCheck(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/two-factor-auth/check';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['twoFaDigitCode'] !== undefined) {
                form['twoFaDigitCode'] = parameters['twoFaDigitCode'];
            }

            if (parameters['twoFaDigitCode'] === undefined) {
                reject(new Error('Missing required  parameter: twoFaDigitCode'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'passwords': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiPasswordChangeURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/password/change';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Change user's password.
     * @method
     * @name Api#postApiPasswordChange
     * @param {} passwords - 
     * Parameters
     * {
     *   'passwords': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiPasswordChange(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/password/change';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['passwords'] !== undefined) {
                body = parameters['passwords'];
            }

            if (parameters['passwords'] === undefined) {
                reject(new Error('Missing required  parameter: passwords'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'email': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiPublicPasswordResetURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/password/reset';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Initialize reset user's password procedure.
     * @method
     * @name Api#postApiPublicPasswordReset
     * @param {} email - 
     * Parameters
     * {
     *   'email': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiPublicPasswordReset(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/password/reset';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['email'] !== undefined) {
                body = parameters['email'];
            }

            if (parameters['email'] === undefined) {
                reject(new Error('Missing required  parameter: email'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'token': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiPublicPasswordResetByTokenURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/password/reset/{token}';

        path = path.replace('{token}', `${parameters['token']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Reset user's password.
     * @method
     * @name Api#getApiPublicPasswordResetByToken
     * @param {string} token - Password reset token
     * Parameters
     * {
     *   'token': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiPublicPasswordResetByToken(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/password/reset/{token}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{token}', `${parameters['token']}`);

            if (parameters['token'] === undefined) {
                reject(new Error('Missing required  parameter: token'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'projects': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiProjectsReassignURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects/reassign';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Reassign a Tests from one Project to another.
     * @method
     * @name Api#postApiProjectsReassign
     * @param {} projects - 
     * Parameters
     * {
     *   'projects': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiProjectsReassign(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/projects/reassign';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['projects'] !== undefined) {
                body = parameters['projects'];
            }

            if (parameters['projects'] === undefined) {
                reject(new Error('Missing required  parameter: projects'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'client': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiPublicRegisterClientURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/register/client';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Register Client.
     * @method
     * @name Api#postApiPublicRegisterClient
     * @param {} client - 
     * Parameters
     * {
     *   'client': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiPublicRegisterClient(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/register/client';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['client'] !== undefined) {
                body = parameters['client'];
            }

            if (parameters['client'] === undefined) {
                reject(new Error('Missing required  parameter: client'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'tester': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiPublicRegisterTesterURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/register/tester';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Register Tester.
     * @method
     * @name Api#postApiPublicRegisterTester
     * @param {} tester - 
     * Parameters
     * {
     *   'tester': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiPublicRegisterTester(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/public/register/tester';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['tester'] !== undefined) {
                body = parameters['tester'];
            }

            if (parameters['tester'] === undefined) {
                reject(new Error('Missing required  parameter: tester'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'reportTemplate': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiReportTemplatesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_templates';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a Report Template resource.
     * @method
     * @name Api#postApiReportTemplates
     * @param {} reportTemplate - 
     * Parameters
     * {
     *   'reportTemplate': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiReportTemplates(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_templates';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['reportTemplate'] !== undefined) {
                body = parameters['reportTemplate'];
            }

            if (parameters['reportTemplate'] === undefined) {
                reject(new Error('Missing required  parameter: reportTemplate'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiReportTemplatesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_templates';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves Report Template resources.
     * @method
     * @name Api#getApiReportTemplates
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiReportTemplates(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_templates';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'specialismId': ,
     *   'reportTemplate': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApiReportTemplatesBySpecialismIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_templates/{specialismId}';

        path = path.replace('{specialismId}', `${parameters['specialismId']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the Report Template resource.
     * @method
     * @name Api#putApiReportTemplatesBySpecialismId
     * @param {string} specialismId - 
     * @param {} reportTemplate - 
     * Parameters
     * {
     *   'specialismId': ,
     *   'reportTemplate': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApiReportTemplatesBySpecialismId(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_templates/{specialismId}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{specialismId}', `${parameters['specialismId']}`);

            if (parameters['specialismId'] === undefined) {
                reject(new Error('Missing required  parameter: specialismId'));
                return;
            }

            if (parameters['reportTemplate'] !== undefined) {
                body = parameters['reportTemplate'];
            }

            if (parameters['reportTemplate'] === undefined) {
                reject(new Error('Missing required  parameter: reportTemplate'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'specialismId': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiReportTemplatesBySpecialismIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_templates/{specialismId}';

        path = path.replace('{specialismId}', `${parameters['specialismId']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the Report Template resource.
     * @method
     * @name Api#getApiReportTemplatesBySpecialismId
     * @param {string} specialismId - 
     * Parameters
     * {
     *   'specialismId': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiReportTemplatesBySpecialismId(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/report_templates/{specialismId}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{specialismId}', `${parameters['specialismId']}`);

            if (parameters['specialismId'] === undefined) {
                reject(new Error('Missing required  parameter: specialismId'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'specialisms': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiSpecialismsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/specialisms';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates specialisms resource.
     * @method
     * @name Api#postApiSpecialisms
     * @param {} specialisms - 
     * Parameters
     * {
     *   'specialisms': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiSpecialisms(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/specialisms';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['specialisms'] !== undefined) {
                body = parameters['specialisms'];
            }

            if (parameters['specialisms'] === undefined) {
                reject(new Error('Missing required  parameter: specialisms'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiSpecialismsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/specialisms';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves specialisms resource.
     * @method
     * @name Api#getApiSpecialisms
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiSpecialisms(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/specialisms';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'subUser': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiUsersCreateSubUserURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/create_sub_user';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Create SubUser.
     * @method
     * @name Api#postApiUsersCreateSubUser
     * @param {} subUser - 
     * Parameters
     * {
     *   'subUser': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiUsersCreateSubUser(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/create_sub_user';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['subUser'] !== undefined) {
                body = parameters['subUser'];
            }

            if (parameters['subUser'] === undefined) {
                reject(new Error('Missing required  parameter: subUser'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'planners': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiTestsReassignURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/reassign';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Reassign a Tests from one Planner to another.
     * @method
     * @name Api#postApiTestsReassign
     * @param {} planners - 
     * Parameters
     * {
     *   'planners': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiTestsReassign(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/reassign';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['planners'] !== undefined) {
                body = parameters['planners'];
            }

            if (parameters['planners'] === undefined) {
                reject(new Error('Missing required  parameter: planners'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'testerFromId': ,
     *   'testerToId': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    reassignCompanyTesterURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/reassign-on-tester';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Reassign a Tests from one Tester to another.
     * @method
     * @name Api#reassignCompanyTester
     * @param {} testerFromId - 
     * @param {} testerToId - 
     * Parameters
     * {
     *   'testerFromId': ,
     *   'testerToId': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    reassignCompanyTester(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tests/reassign-on-tester';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['testerFromId'] !== undefined) {
                body = parameters['testerFromId'];
            }

            if (parameters['testerFromId'] === undefined) {
                reject(new Error('Missing required  parameter: testerFromId'));
                return;
            }

            if (parameters['testerToId'] !== undefined) {
                body = parameters['testerToId'];
            }

            if (parameters['testerToId'] === undefined) {
                reject(new Error('Missing required  parameter: testerToId'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiBankDetailsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/bank_details';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieve tester's Bank Details
     * @method
     * @name Api#getApiBankDetails
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiBankDetails(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/bank_details';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'bankDetails': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiBankDetailsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/bank_details';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a tester's Bank Details resource
     * @method
     * @name Api#postApiBankDetails
     * @param {} bankDetails - 
     * Parameters
     * {
     *   'bankDetails': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiBankDetails(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/bank_details';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['bankDetails'] !== undefined) {
                body = parameters['bankDetails'];
            }

            if (parameters['bankDetails'] === undefined) {
                reject(new Error('Missing required  parameter: bankDetails'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'bankDetails': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApiBankDetailsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/bank_details';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the tester's Bank Details resource
     * @method
     * @name Api#putApiBankDetails
     * @param {} bankDetails - 
     * Parameters
     * {
     *   'bankDetails': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApiBankDetails(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/bank_details';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['bankDetails'] !== undefined) {
                body = parameters['bankDetails'];
            }

            if (parameters['bankDetails'] === undefined) {
                reject(new Error('Missing required  parameter: bankDetails'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiTesterProfileByIdBankDetailsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profile/{id}/bank_details';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieve tester's Bank Details for specified tester
     * @method
     * @name Api#getApiTesterProfileByIdBankDetails
     * @param {integer} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiTesterProfileByIdBankDetails(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profile/{id}/bank_details';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiTesterProfileRightsToWorkURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profile/rights_to_work';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Tester rights to work countries list.
     * @method
     * @name Api#getApiTesterProfileRightsToWork
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiTesterProfileRightsToWork(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profile/rights_to_work';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'accountData': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApiTesterProfileRightsToWorkURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profile/rights_to_work';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Tester rights to work countries list
     * @method
     * @name Api#putApiTesterProfileRightsToWork
     * @param {} accountData - 
     * Parameters
     * {
     *   'accountData': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApiTesterProfileRightsToWork(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profile/rights_to_work';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['accountData'] !== undefined) {
                body = parameters['accountData'];
            }

            if (parameters['accountData'] === undefined) {
                reject(new Error('Missing required  parameter: accountData'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiTesterAccountURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_account';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Tester account data.
     * @method
     * @name Api#getApiTesterAccount
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiTesterAccount(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_account';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'accountData': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApiTesterAccountURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_account';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Tester account data.
     * @method
     * @name Api#putApiTesterAccount
     * @param {} accountData - 
     * Parameters
     * {
     *   'accountData': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApiTesterAccount(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_account';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['accountData'] !== undefined) {
                body = parameters['accountData'];
            }

            if (parameters['accountData'] === undefined) {
                reject(new Error('Missing required  parameter: accountData'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiTesterProfilesByIdUpdateURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/update';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieve tester's Profile Details
     * @method
     * @name Api#getApiTesterProfilesByIdUpdate
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiTesterProfilesByIdUpdate(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/update';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'testerSettings': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApiTesterProfilesByIdUpdateURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/update';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the tester's Profile settings resource
     * @method
     * @name Api#putApiTesterProfilesByIdUpdate
     * @param {string} id - 
     * @param {} testerSettings - 
     * Parameters
     * {
     *   'id': ,
     *   'testerSettings': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApiTesterProfilesByIdUpdate(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_profiles/{id}/update';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['testerSettings'] !== undefined) {
                body = parameters['testerSettings'];
            }

            if (parameters['testerSettings'] === undefined) {
                reject(new Error('Missing required  parameter: testerSettings'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiTesterSpecialismsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_specialisms';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a Tester Specialisms resource.
     * @method
     * @name Api#getApiTesterSpecialisms
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiTesterSpecialisms(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_specialisms';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'specialisms': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApiTesterSpecialismsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_specialisms';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the Tester Specialisms resource.
     * @method
     * @name Api#putApiTesterSpecialisms
     * @param {} specialisms - 
     * Parameters
     * {
     *   'specialisms': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApiTesterSpecialisms(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/tester_specialisms';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['specialisms'] !== undefined) {
                body = parameters['specialisms'];
            }

            if (parameters['specialisms'] === undefined) {
                reject(new Error('Missing required  parameter: specialisms'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'managers': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiUsersClientUserReassignURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/client_user/reassign';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Reassign Client User and projects.
     * @method
     * @name Api#postApiUsersClientUserReassign
     * @param {} managers - 
     * Parameters
     * {
     *   'managers': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiUsersClientUserReassign(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/client_user/reassign';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['managers'] !== undefined) {
                body = parameters['managers'];
            }

            if (parameters['managers'] === undefined) {
                reject(new Error('Missing required  parameter: managers'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'user': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putApiUsersClientUserByIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/client_user/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Update Client User.
     * @method
     * @name Api#putApiUsersClientUserById
     * @param {string} id - 
     * @param {} user - 
     * Parameters
     * {
     *   'id': ,
     *   'user': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putApiUsersClientUserById(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/client_user/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['user'] !== undefined) {
                body = parameters['user'];
            }

            if (parameters['user'] === undefined) {
                reject(new Error('Missing required  parameter: user'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'info'?: ,
     *   'actionsTypes'?: ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCompanyLogCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_logs';
        if (parameters['info'] !== undefined) {
            queryParameters['info'] = parameters['info'];
        }

        if (parameters['actionsTypes'] !== undefined) {
            queryParameters['actions_types'] = parameters['actionsTypes'];
        }

        if (parameters['createdAtBefore'] !== undefined) {
            queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
        }

        if (parameters['createdAtStrictlyBefore'] !== undefined) {
            queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
        }

        if (parameters['createdAtAfter'] !== undefined) {
            queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
        }

        if (parameters['createdAtStrictlyAfter'] !== undefined) {
            queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of CompanyLog resources.
     * @method
     * @name Api#getCompanyLogCollection
     * @param {string} info - Audit log info
     * @param {string} actionsTypes - Log action type
     * @param {string} createdAtBefore - 
     * @param {string} createdAtStrictlyBefore - 
     * @param {string} createdAtAfter - 
     * @param {string} createdAtStrictlyAfter - 
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'info'?: ,
     *   'actionsTypes'?: ,
     *   'createdAtBefore'?: ,
     *   'createdAtStrictlyBefore'?: ,
     *   'createdAtAfter'?: ,
     *   'createdAtStrictlyAfter'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCompanyLogCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_logs';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['info'] !== undefined) {
                queryParameters['info'] = parameters['info'];
            }

            if (parameters['actionsTypes'] !== undefined) {
                queryParameters['actions_types'] = parameters['actionsTypes'];
            }

            if (parameters['createdAtBefore'] !== undefined) {
                queryParameters['createdAt[before]'] = parameters['createdAtBefore'];
            }

            if (parameters['createdAtStrictlyBefore'] !== undefined) {
                queryParameters['createdAt[strictly_before]'] = parameters['createdAtStrictlyBefore'];
            }

            if (parameters['createdAtAfter'] !== undefined) {
                queryParameters['createdAt[after]'] = parameters['createdAtAfter'];
            }

            if (parameters['createdAtStrictlyAfter'] !== undefined) {
                queryParameters['createdAt[strictly_after]'] = parameters['createdAtStrictlyAfter'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getCompanyLogItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_logs/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a CompanyLog resource.
     * @method
     * @name Api#getCompanyLogItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getCompanyLogItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_logs/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiCompanyDomainsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_domains';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get list of the company domains.
     * @method
     * @name Api#getApiCompanyDomains
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiCompanyDomains(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_domains';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'domains': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiCompanyDomainsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_domains';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Reassign Client User and projects.
     * @method
     * @name Api#postApiCompanyDomains
     * @param {} domains - 
     * Parameters
     * {
     *   'domains': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiCompanyDomains(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_domains';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['domains'] !== undefined) {
                body = parameters['domains'];
            }

            if (parameters['domains'] === undefined) {
                reject(new Error('Missing required  parameter: domains'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getApiCompanyIpWhiteListsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_ip_whitelists';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get list of the company IP white lists.
     * @method
     * @name Api#getApiCompanyIpWhiteLists
     * Parameters
     * {
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getApiCompanyIpWhiteLists(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_ip_whitelists';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'ipRanges': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postApiCompanyIpWhiteListsURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_ip_whitelists';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Company Ip WhiteLists.
     * @method
     * @name Api#postApiCompanyIpWhiteLists
     * @param {} ipRanges - 
     * Parameters
     * {
     *   'ipRanges': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postApiCompanyIpWhiteLists(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/company_ip_whitelists';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['ipRanges'] !== undefined) {
                body = parameters['ipRanges'];
            }

            if (parameters['ipRanges'] === undefined) {
                reject(new Error('Missing required  parameter: ipRanges'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'page'?: ,
     *   'items'?: ,
     *   'companies'?: ,
     *   'yearMonth'?: ,
     *   'projects'?: ,
     *   'consultants'?: ,
     *   'statuses'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTimeExpensesWeekCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks';
        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters['companies'] !== undefined) {
            queryParameters['companies'] = parameters['companies'];
        }

        if (parameters['yearMonth'] !== undefined) {
            queryParameters['yearMonth'] = parameters['yearMonth'];
        }

        if (parameters['projects'] !== undefined) {
            queryParameters['projects'] = parameters['projects'];
        }

        if (parameters['consultants'] !== undefined) {
            queryParameters['consultants'] = parameters['consultants'];
        }

        if (parameters['statuses'] !== undefined) {
            queryParameters['statuses'] = parameters['statuses'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of TimeExpensesWeek resources.
     * @method
     * @name Api#getTimeExpensesWeekCollection
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * @param {string} companies - Company sectors
     * @param {string} yearMonth - Month sectors
     * @param {string} projects - Project selector
     * @param {string} consultants - Consultant selector
     * @param {string} statuses - Status selector
     * Parameters
     * {
     *   'page'?: ,
     *   'items'?: ,
     *   'companies'?: ,
     *   'yearMonth'?: ,
     *   'projects'?: ,
     *   'consultants'?: ,
     *   'statuses'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTimeExpensesWeekCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters['companies'] !== undefined) {
                queryParameters['companies'] = parameters['companies'];
            }

            if (parameters['yearMonth'] !== undefined) {
                queryParameters['yearMonth'] = parameters['yearMonth'];
            }

            if (parameters['projects'] !== undefined) {
                queryParameters['projects'] = parameters['projects'];
            }

            if (parameters['consultants'] !== undefined) {
                queryParameters['consultants'] = parameters['consultants'];
            }

            if (parameters['statuses'] !== undefined) {
                queryParameters['statuses'] = parameters['statuses'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTimeExpensesWeekItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a TimeExpensesWeek resource.
     * @method
     * @name Api#getTimeExpensesWeekItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTimeExpensesWeekItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'timeExpensesWeek'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    change_statusTimeExpensesWeekItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/{id}/change_status';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the TimeExpensesWeek resource.
     * @method
     * @name Api#change_statusTimeExpensesWeekItem
     * @param {string} id - 
     * @param {} timeExpensesWeek - The updated TimeExpensesWeek resource
     * Parameters
     * {
     *   'id': ,
     *   'timeExpensesWeek'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    change_statusTimeExpensesWeekItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/{id}/change_status';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['timeExpensesWeek'] !== undefined) {
                body = parameters['timeExpensesWeek'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    api_time_expenses_weeks_days_get_subresourceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/{id}/days';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of TimesheetDay resources.
     * @method
     * @name Api#api_time_expenses_weeks_days_get_subresource
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    api_time_expenses_weeks_days_get_subresource(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/{id}/days';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTimesheetDayCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/timesheet_days';
        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the collection of TimesheetDay resources.
     * @method
     * @name Api#getTimesheetDayCollection
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * Parameters
     * {
     *   'page'?: ,
     *   'items'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTimesheetDayCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/timesheet_days';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'timesheetDay'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    postTimesheetDayCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/timesheet_days';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a TimesheetDay resource.
     * @method
     * @name Api#postTimesheetDayCollection
     * @param {} timesheetDay - The new TimesheetDay resource
     * Parameters
     * {
     *   'timesheetDay'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    postTimesheetDayCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/timesheet_days';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['timesheetDay'] !== undefined) {
                body = parameters['timesheetDay'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTimesheetDayItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/timesheet_days/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a TimesheetDay resource.
     * @method
     * @name Api#getTimesheetDayItem
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTimesheetDayItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/timesheet_days/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'timesheetDay'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    putTimesheetDayItemURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/timesheet_days/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the TimesheetDay resource.
     * @method
     * @name Api#putTimesheetDayItem
     * @param {string} id - 
     * @param {} timesheetDay - The updated TimesheetDay resource
     * Parameters
     * {
     *   'id': ,
     *   'timesheetDay'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    putTimesheetDayItem(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/timesheet_days/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['timesheetDay'] !== undefined) {
                body = parameters['timesheetDay'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteTimesheetDayURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/timesheet_days/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * delete TimesheetDay
     * @method
     * @name Api#deleteTimesheetDay
     * @param {string} id - Day id
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteTimesheetDay(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/timesheet_days/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'poNumber'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    updateTimesheetPoNumberURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/{id}/update_purchase_order_number';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the TimesheetWeek resource.
     * @method
     * @name Api#updateTimesheetPoNumber
     * @param {string} id - 
     * @param {} poNumber - The updated TimesheetDay resource
     * Parameters
     * {
     *   'id': ,
     *   'poNumber'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    updateTimesheetPoNumber(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/{id}/update_purchase_order_number';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['poNumber'] !== undefined) {
                body = parameters['poNumber'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'timeExpensesWeeks'?: ,
     *   'status'?: ,
     *   'comment'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    updateTimesheetExpensesWeeksStatusURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/change_status';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the TimesheetDay resource.
     * @method
     * @name Api#updateTimesheetExpensesWeeksStatus
     * @param {} timeExpensesWeeks - The updated TimesheetDay resource
     * @param {} status - timeExpensesWeeks status
     * @param {} comment - timeExpensesWeeks comment
     * Parameters
     * {
     *   'timeExpensesWeeks'?: ,
     *   'status'?: ,
     *   'comment'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    updateTimesheetExpensesWeeksStatus(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/change_status';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['timeExpensesWeeks'] !== undefined) {
                body = parameters['timeExpensesWeeks'];
            }

            if (parameters['status'] !== undefined) {
                body = parameters['status'];
            }

            if (parameters['comment'] !== undefined) {
                body = parameters['comment'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   'name'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTimesheetTimeCategoriesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories';
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters['name'] !== undefined) {
            queryParameters['name'] = parameters['name'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a TimesheetDay resource.
     * @method
     * @name Api#getTimesheetTimeCategories
     * @param {string} show - Active, archived or all sectors
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * @param {string} name - The category name
     * Parameters
     * {
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   'name'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTimesheetTimeCategories(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters['name'] !== undefined) {
                queryParameters['name'] = parameters['name'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'name'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    addTimesheetTimeCategoriesCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a TimesheetDay resource.
     * @method
     * @name Api#addTimesheetTimeCategoriesCollection
     * @param {} name - The new TimesheetTimeCategory resource
     * Parameters
     * {
     *   'name'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    addTimesheetTimeCategoriesCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['name'] !== undefined) {
                body = parameters['name'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTimeCategoryByIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get  a TimesheetTimeCategory resource.
     * @method
     * @name Api#getTimeCategoryById
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTimeCategoryById(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'name'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    updateTimesheetTimeCategoryByIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the TimesheetTimeCategory resource.
     * @method
     * @name Api#updateTimesheetTimeCategoryById
     * @param {string} id - 
     * @param {} name - The updated TimesheetTimeCategory resource
     * Parameters
     * {
     *   'id': ,
     *   'name'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    updateTimesheetTimeCategoryById(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['name'] !== undefined) {
                body = parameters['name'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteTimesheetTimeCategoryByIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * delete TimesheetTimeCategory
     * @method
     * @name Api#deleteTimesheetTimeCategoryById
     * @param {string} id - TimesheetTimeCategory id
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteTimesheetTimeCategoryById(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'category'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveTimesheetTimeCategoryByIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the TimesheetTimeCategory resource.
     * @method
     * @name Api#unarchiveTimesheetTimeCategoryById
     * @param {string} id - 
     * @param {} category - The updated TimesheetTimeCategory resource
     * Parameters
     * {
     *   'id': ,
     *   'category'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveTimesheetTimeCategoryById(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_categories/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['category'] !== undefined) {
                body = parameters['category'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   'name'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTimesheetExpensesTypesURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types';
        if (parameters['show'] !== undefined) {
            queryParameters['show'] = parameters['show'];
        }

        if (parameters['page'] !== undefined) {
            queryParameters['page'] = parameters['page'];
        }

        if (parameters['items'] !== undefined) {
            queryParameters['items'] = parameters['items'];
        }

        if (parameters['name'] !== undefined) {
            queryParameters['name'] = parameters['name'];
        }

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a TimesheetExpensesTypes resource.
     * @method
     * @name Api#getTimesheetExpensesTypes
     * @param {string} show - Active, archived or all sectors
     * @param {integer} page - The collection page number
     * @param {integer} items - The number of items per page
     * @param {string} name - The category name
     * Parameters
     * {
     *   'show'?: ,
     *   'page'?: ,
     *   'items'?: ,
     *   'name'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTimesheetExpensesTypes(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['show'] !== undefined) {
                queryParameters['show'] = parameters['show'];
            }

            if (parameters['page'] !== undefined) {
                queryParameters['page'] = parameters['page'];
            }

            if (parameters['items'] !== undefined) {
                queryParameters['items'] = parameters['items'];
            }

            if (parameters['name'] !== undefined) {
                queryParameters['name'] = parameters['name'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'name'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    addTimesheetExpensesTypesCollectionURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a TimesheetExpensesType resource.
     * @method
     * @name Api#addTimesheetExpensesTypesCollection
     * @param {} name - The new TimesheetExpensesType resource
     * Parameters
     * {
     *   'name'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    addTimesheetExpensesTypesCollection(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['name'] !== undefined) {
                body = parameters['name'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getTimesheetExpensesTypeByIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Get  a TimesheetExpensesType resource.
     * @method
     * @name Api#getTimesheetExpensesTypeById
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getTimesheetExpensesTypeById(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'name'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    updateTimesheetExpensesTypeByIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Replaces the TimesheetExpensesType resource.
     * @method
     * @name Api#updateTimesheetExpensesTypeById
     * @param {string} id - 
     * @param {} name - The updated TimesheetExpensesType resource
     * Parameters
     * {
     *   'id': ,
     *   'name'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    updateTimesheetExpensesTypeById(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['name'] !== undefined) {
                body = parameters['name'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    deleteTimesheetExpensesTypeByIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * delete TimesheetExpensesType
     * @method
     * @name Api#deleteTimesheetExpensesTypeById
     * @param {string} id - TimesheetExpensesType id
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    deleteTimesheetExpensesTypeById(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('DELETE', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'type'?: ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    unarchiveTimesheetExpensesTypeByIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types/{id}/unarchive';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Updates the TimesheetExpensesType resource.
     * @method
     * @name Api#unarchiveTimesheetExpensesTypeById
     * @param {string} id - 
     * @param {} type - The updated TimesheetExpensesType resource
     * Parameters
     * {
     *   'id': ,
     *   'type'?: ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    unarchiveTimesheetExpensesTypeById(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/expenses_types/{id}/unarchive';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            headers['Content-Type'] = 'application/ld+json,application/json,text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['type'] !== undefined) {
                body = parameters['type'];
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PATCH', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'consultant': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    createProjectConsultantURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/consultant';

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        queryParameters = {};

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Creates a project consultant resource
     * @method
     * @name Api#createProjectConsultant
     * @param {} consultant - 
     * Parameters
     * {
     *   'consultant': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    createProjectConsultant(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/consultant';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            if (parameters['consultant'] !== undefined) {
                body = parameters['consultant'];
            }

            if (parameters['consultant'] === undefined) {
                reject(new Error('Missing required  parameter: consultant'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            // @todo: temporary fix
            // form = queryParameters;
            // queryParameters = {};

            this.request('POST', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'consultant': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    updateProjectConsultantURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/consultant/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Project Consultant.
     * @method
     * @name Api#updateProjectConsultant
     * @param {string} id - 
     * @param {} consultant - 
     * Parameters
     * {
     *   'id': ,
     *   'consultant': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    updateProjectConsultant(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/users/consultant/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters['consultant'] !== undefined) {
                body = parameters['consultant'];
            }

            if (parameters['consultant'] === undefined) {
                reject(new Error('Missing required  parameter: consultant'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('PUT', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    getConsultantProfileByIdURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/consultant_profiles/{id}';

        path = path.replace('{id}', `${parameters['id']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves a consultant profile resource.
     * @method
     * @name Api#getConsultantProfileById
     * @param {string} id - 
     * Parameters
     * {
     *   'id': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    getConsultantProfileById(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/consultant_profiles/{id}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    /**
     * Type desription
     * {
     *   'id': ,
     *   'type': ,
     *   
     *   $queryParameters?: any,
     *   $domain?: string
     * }
     */
    get_time_expenses_weeks_invoiceURL(parameters = {}) {
        let queryParameters = {};
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/{id}/invoice_link/{type}';

        path = path.replace('{id}', `${parameters['id']}`);

        path = path.replace('{type}', `${parameters['type']}`);

        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                queryParameters[parameterName] = parameters.$queryParameters[parameterName];
            });
        }

        let keys = Object.keys(queryParameters);
        return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '');
    }

    /**
     * Retrieves the TimesheetWeek invoice.
     * @method
     * @name Api#get_time_expenses_weeks_invoice
     * @param {string} id - 
     * @param {string} type - 
     * Parameters
     * {
     *   'id': ,
     *   'type': ,
     *   
     *   $queryParameters,
     *   $domain
     * }
     */
    get_time_expenses_weeks_invoice(parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let path = '/api/time_expenses_weeks/{id}/invoice_link/{type}';
        let body;
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/ld+json, application/json, text/html';
            //headers['Content-Type'] = 'application/json';
            //headers['Accept'] = 'application/json';

            path = path.replace('{id}', `${parameters['id']}`);

            if (parameters['id'] === undefined) {
                reject(new Error('Missing required  parameter: id'));
                return;
            }

            path = path.replace('{type}', `${parameters['type']}`);

            if (parameters['type'] === undefined) {
                reject(new Error('Missing required  parameter: type'));
                return;
            }

            if (parameters.$queryParameters) {
                Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
                    queryParameters[parameterName] = parameters.$queryParameters[parameterName];
                });
            }

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

    getByUrl(path, parameters = {}) {
        const domain = parameters.$domain ? parameters.$domain : this.domain;
        let body = {};
        let queryParameters = {};
        let headers = {};
        let form = {};
        return new Promise((resolve, reject) => {
            headers['Accept'] = 'application/json';

            this.request('GET', domain + path, body, headers, queryParameters, form, reject, resolve);
        });
    }

}

/* tslint:enable:no-string-literal */
/* tslint:enable:jsdoc-format */