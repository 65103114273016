import * as React from "react";
import Button from "@material-ui/core/Button/Button";
import { Link, Route, Switch } from "react-router-dom";
import ProjectList from "./ProjectList";
import ProjectSearchForm from "./ProjectSearchForm";
import ProjectCreate from "./ProjectCreate";
import ProjectUpdate from "./ProjectUpdate";
import { withRouter } from "react-router-dom";
import { getCreateLink } from "../../../../helpers/router-helper";
import PermissionBlock from "../../../../components/auth/PermissionBlock";
import { Permission } from "../../../../constants/permission";

class Project extends React.Component<any, {}> {
  baseUrl = this.props.match.path;

  createComponent = () => <ProjectCreate backRoute={this.baseUrl} />;
  updateComponent = () => <ProjectUpdate backRoute={this.baseUrl} />;

  render() {
    return (
      <div>
        <div>
          <div className="crud-sections-header">
            <PermissionBlock
              permissions={[
                Permission.CAN_MANAGE_PROJECT,
                Permission.CAN_CREATE_EDIT_PROJECT,
              ]}
            >
              <Link to={getCreateLink(this.baseUrl)}>
                <button className="btn-new">New&nbsp;Project</button>
              </Link>
            </PermissionBlock>
          </div>
          <Switch>
            <Route
              path={getCreateLink(this.baseUrl)}
              render={this.createComponent}
            />
            <Route path={this.baseUrl + "/:id"} render={this.updateComponent} />
          </Switch>
          <ProjectSearchForm />
          <ProjectList baseUrl={this.baseUrl} />
        </div>
      </div>
    );
  }
}

export default withRouter(Project);
