import * as React from 'react';
import Button from "@material-ui/core/Button/Button";
import {Link, Route, Switch} from "react-router-dom";
import AdminUsersList from "./AdminUsersList";
import AdminUsersSearchForm from "./AdminUsersSearchForm";
import AdminUsersCreate from "./AdminUsersCreate";
import AdminUsersUpdate from "./AdminUsersUpdate";
import {withRouter} from 'react-router-dom';
import {getCreateLink, getEditLink} from '../../../../helpers/router-helper';


class AdminUsers extends React.Component<any, {}> {
  baseUrl = this.props.match.path;

  createComponent = () => <AdminUsersCreate backRoute={this.baseUrl}/>;
  updateComponent = () => <AdminUsersUpdate backRoute={this.baseUrl}/>;

  render() {
    return (
      <div>
        <div className="crud-sections-header">
          <h4>Admin Users</h4>
          <Link to={getCreateLink(this.baseUrl)}>
            <Button variant="contained" color="default">
              New Admin User
            </Button>
          </Link>
        </div>
        <hr/>
        <Switch>
          <Route path={getCreateLink(this.baseUrl)} render={this.createComponent}/>
          <Route path={this.baseUrl + "/:id"} render={this.updateComponent}/>
        </Switch>
        <AdminUsersSearchForm/>
        <AdminUsersList baseUrl={this.baseUrl}/>
      </div>
    );
  }
}

export default withRouter(AdminUsers);
