import React from "react";
import { WrappedFieldArrayProps } from "redux-form/lib/FieldArray";

export const AddExpensesDocumentBtn = (props: WrappedFieldArrayProps) => {
  const { fields } = props;
  const { push } = fields;
  const isActive = fields.length < 5;
  let file;

  const onChange = (event: React.ChangeEvent<any>) => {
    push({ document: event.target.files[0] });
  };
  const handleClick = (event: React.ChangeEvent<any>) => {
    file.value = "";
    file.click();
  };

  if (isActive) {
    return (
      <div className="pl-4">
        <button
          className="btn-new"
          type="button"
          onClick={event => {
            handleClick(event);
          }}
        >
          Upload Receipt
          <input
            type="file"
            accept="
                 application/pdf,application/msword,
                 application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                 application/vnd.oasis.opendocument.text,
                 application/vnd.ms-powerpoint,
                 application/vnd.openxmlformats-officedocument.presentationml.presentation,
                 application/vnd.oasis.opendocument.presentation,
                 application/vnd.ms-excel,
                 application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                 application/vnd.oasis.opendocument.spreadsheet,
                 text/csv,text/plain,
                 .docx,
                 .ods,
                 .odt,
                 .odg,
                 .xlsx,
                 .xls,
                 .csv,
                 .pptx,
                 .ppt,
                 image/png,
                 image/jpeg,
                 image/gif"
            ref={ref => (file = ref)}
            onChange={onChange}
            hidden
          />
        </button>
      </div>
    );
  }

  return null;
};
