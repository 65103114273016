import * as React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import ReduxTextField from '../../components/forms/ReduxTextField';
import {
  composeValidators,
  fieldsNotEmpty,
  fieldIsValidPassword,
  fieldArePasswordsMatched,
} from '../../helpers/validations/validation';
import {withStyles, Modal, Typography, Grid, Button} from '@material-ui/core';
import {changePasswordModalOpen, changePasswordModalClose} from '../../actions';
import {getIsOpenModalChangePassword} from "../../selectors/modal";
import {defaultStyleWidth} from '../../styles/material/popup-style';
import {postChangePassword} from '../../actions';
import {getUserEmail} from "../../selectors/auth";
import {handleSpaceKeyPress} from "../../helpers/validations/emptyTestFieldValidation";

const FORM_NAME = "ChangePasswordModal";

class ChangePasswordModal extends React.Component<any> {
  handleClose = () => {
    this.props.changePasswordModalClose();

    Object.keys(this.props.initialValues).map(value => {
      this.props.untouch(value);
      this.props.change(value, "");
    });
  };

  render() {
    const {handleSubmit} = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
      >
        <div className={this.props.classes.paper}>
          <Typography variant="title" component="h3" id="modal-title">
            Change password
          </Typography>
          <hr/>
          <form noValidate
                autoComplete="off"
                onSubmit={handleSubmit((values) => {
                  this.props.postChangePassword(values, this.props.email, FORM_NAME);
                })}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Field
                  inputProps={{minLength: 8, maxLength: 16}}
                  name="current_password"
                  label="Current password"
                  type="password"
                  margin="normal"
                  component={ReduxTextField}
                  onKeyPress={handleSpaceKeyPress}
                />
                <Field
                  inputProps={{minLength: 8, maxLength: 16}}
                  name="new_password"
                  label="New password"
                  type="password"
                  margin="normal"
                  component={ReduxTextField}
                  onKeyPress={handleSpaceKeyPress}
                />
                <Field
                  inputProps={{minLength: 8, maxLength: 16}}
                  name="repeated_new_password"
                  label="Confirm new password"
                  type="password"
                  margin="normal"
                  component={ReduxTextField}
                  onKeyPress={handleSpaceKeyPress}
                />
                <div className="d-flex justify-content-between mt-4">
                  <Button
                    variant="contained"
                    color="default"
                    type={'reset'}
                    onClick={this.handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Change
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalChangePassword(state),
    email: getUserEmail(state),
    initialValues: {
      current_password: "",
      new_password: "",
      repeated_new_password: "",
    },
  };
}

const connectedComponent = connect(mapStateToProps, {
  changePasswordModalClose,
  changePasswordModalOpen,
  postChangePassword,
})(reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  validate: composeValidators(
    fieldsNotEmpty(['current_password', 'new_password', 'repeated_new_password']),
    fieldIsValidPassword(['new_password', 'repeated_new_password']),
    fieldArePasswordsMatched(['new_password', 'repeated_new_password']),
  )
})(ChangePasswordModal));

export default withStyles(defaultStyleWidth(320))(connectedComponent);
