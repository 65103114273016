import * as React from 'react';
import {composeValidators, fieldIsEmail, fieldsNotEmpty} from "../../../../helpers/validations/validation";
import {InjectedFormProps, reduxForm} from "redux-form";
import { connect } from "react-redux";
import { usersRequest } from "../../../../actions/users";
import parameterFetcher from '../../../../components/parametersFetcher';
import selector from '../../../../selectors/users';
import AdminUsersForm from "./AdminUsersForm";
import routerModal from '../../../../components/routerModal';
import {WithStyles} from "@material-ui/core/styles/withStyles";

const FORM_NAME = 'AdminUsersUpdate';

class AdminUsersUpdate extends React.Component<InjectedFormProps<{}, {}> & {
  putData: (...any) => void,
  backRoute: string
} & WithStyles<any>, any> {
  render() {
    const { handleSubmit, putData, backRoute } = this.props;

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit((values) => {
          putData(values, FORM_NAME, backRoute);
        })}>
        <AdminUsersForm backRoute={backRoute}/>
      </form>
    )
  }
}

const formConnected = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty(['firstName', 'lastName', 'email',]),
    fieldIsEmail(['email', ]),
  )
})(AdminUsersUpdate);

const connected = parameterFetcher(
  connect(undefined, {
    putData: usersRequest.putItem,
  })(formConnected),
  FORM_NAME,
  usersRequest.getItem,
  selector.getItemById
);

export default routerModal(connected);
