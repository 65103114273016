import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import selector from "../../../selectors/users";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from "redux-form";
import { bindActionCreators } from "redux";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import { carouselFilter } from "../../../actions";
import { isChangesExist } from "../../../helpers/props-checker";
import { Button, ListItemText, MenuItem } from "@material-ui/core";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import ReduxDialingCodeSelectSearchField from "../../../components/forms/ReduxDialingCodeSelectSearchField";
import {
  getTestStatusName,
  TestStatus,
  TestStatusNames
} from "../../../constants/test-status";
import { getCompanies } from "../../../actions/finances";
import { testingCompaniesRequest } from "../../../actions/testing-companies";
import testingCompaniesSelector from "../../../selectors/testing-companies";
import { ITestsAdminFilterParamsForCSV } from "../../../types";
import { downloadTestsCSVDocument } from "../../../api/requests/document-download";
import { ITest } from "../../../models/test.interface";
import testSelector from "../../../selectors/tester-bookings";

export const TESTS_SEARCH_FORM = "TestsSearchForm";
const selectorForm = formValueSelector(TESTS_SEARCH_FORM);

interface IOption {
  id: number;
  name: string;
}
type OmitKeys = "statuses" | "plannerNameEmail" | "testerNameEmail";
interface IValuesData extends Omit<ITestsAdminFilterParamsForCSV, OmitKeys> {
  statuses?: number;
  plannerName?: string;
  testerName?: string;
  active: string;
  text: string;
}

class TestsSearchForm extends React.Component<
  InjectedFormProps<{}, {}> & {
    submitFilterChanges: (type: string, text: string) => void;
    downloadTestsCSVDocument: (params: ITestsAdminFilterParamsForCSV) => void;
    valuesData: IValuesData;
    companies: { companiesForManagedServices: string[] };
    testingCompanies: string[];
    loadCompanies: () => void;
    loadTestingCompanies: () => void;
    testsList: ITest[];
  }
> {
  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["valuesData"], nextProps, this.props)) {
      this.props.submitFilterChanges(
        nextProps.valuesData.active,
        nextProps.valuesData.text
      );
    }
  }

  componentWillMount() {
    this.props.loadCompanies();
    this.props.loadTestingCompanies();
  }

  renderOption = (item: IOption) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  );

  render() {
    const {
      reset,
      companies: { companiesForManagedServices },
      testingCompanies,
      valuesData,
      downloadTestsCSVDocument,
      testsList = []
    } = this.props;
    let filterParams: ITestsAdminFilterParamsForCSV = {
      name: valuesData?.name,
      plannerNameEmail: valuesData?.plannerName,
      testerNameEmail: valuesData?.testerName,
      dateFrom: valuesData?.dateFrom,
      dateTo: valuesData?.dateTo,
      testingCompany: valuesData?.testingCompany,
      clientCompany: valuesData?.clientCompany
    };
    if (!!valuesData?.statuses) {
      filterParams = { ...filterParams, statuses: [valuesData.statuses] };
    }
    return (
      <>
        <div className="text-right mb-4">
          <Button
            variant="contained"
            color="primary"
            type="button"
            className="full-width-in-mobile"
            disabled={!testsList.length}
            onClick={() => downloadTestsCSVDocument(filterParams)}
          >
            DOWNLOAD CSV
          </Button>
        </div>
        <form className="mb-4">
          <Paper className="p-4">
            <div className="row">
              <div className="col-md-4">
                <FieldGeneric
                  name="dateFrom"
                  label="From"
                  InputLabelProps={{
                    shrink: true
                  }}
                  component={ReduxDatePicker}
                  margin="normal"
                />
              </div>
              <div className="col-md-4">
                <FieldGeneric
                  name="dateTo"
                  label="To"
                  InputLabelProps={{
                    shrink: true
                  }}
                  addEndOfDay={true}
                  component={ReduxDatePicker}
                  margin="normal"
                />
              </div>
              <div className="col-md-4">
                <FieldGeneric
                  name="statuses"
                  label="Test status"
                  margin="normal"
                  component={ReduxSelectField}
                >
                  <MenuItem value="">None</MenuItem>
                  {Object.entries(TestStatusNames).map(([key, value]) => (
                    <MenuItem key={value} value={TestStatus[key]}>
                      {value}
                      <ListItemText primary={getTestStatusName(value)} />
                    </MenuItem>
                  ))}
                </FieldGeneric>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <Field
                  name="name"
                  label="Test title"
                  component={ReduxTextField}
                  margin="normal"
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="plannerName"
                  label="Planner Name, Email"
                  component={ReduxTextField}
                  margin="normal"
                />
              </div>
              <div className="col-md-4">
                <FieldGeneric
                  name="clientCompany"
                  label="Client Company"
                  component={ReduxDialingCodeSelectSearchField}
                  items={companiesForManagedServices || []}
                  renderItem={this.renderOption}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Field
                  name="testerName"
                  label="Tester Name, Email"
                  component={ReduxTextField}
                  margin="normal"
                />
              </div>
              <div className="col-md-4">
                <FieldGeneric
                  name="testingCompany"
                  label="Testing Company"
                  component={ReduxDialingCodeSelectSearchField}
                  items={testingCompanies || []}
                  renderItem={this.renderOption}
                />
              </div>
              <div className="col-md-4 d-flex align-items-end justify-content-end">
                <button
                  className="btn-refresh mb-2 mt-2"
                  type="button"
                  onClick={reset}
                >
                  Reset Filters
                </button>
              </div>
            </div>
          </Paper>
        </form>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    valuesData: {
      ...selectorForm(
        state,
        "statuses",
        "plannerName",
        "testerName",
        "dateFrom",
        "dateTo",
        "testingCompany",
        "clientCompany",
        "name"
      )
    },
    initialValues: {
      text: selector.getFilterTextFields(state),
      active: selector.getFilterActivityStatus(state)
    },
    companies: state.finances,
    testingCompanies: testingCompaniesSelector.getItems(state),
    testsList: testSelector.getItems(state)
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      submitFilterChanges: carouselFilter.submitFilterChanges,
      loadCompanies: getCompanies || {},
      loadTestingCompanies: testingCompaniesRequest.getItems,
      downloadTestsCSVDocument
    },
    dispatch
  );
};

const connectForm = reduxForm({
  form: TESTS_SEARCH_FORM
})(TestsSearchForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectForm);
