import * as React from "react";
import Select from "@material-ui/core/Select/Select";
import {
  InputLabel,
  FormControl,
  FormHelperText,
  WithStyles,
  Icon
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { WrappedFieldProps } from "redux-form/lib/Field";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import TextField from "@material-ui/core/TextField/TextField";
import { connect } from "react-redux";
import { FORM_SEARCH_FIELD_SET } from "../../constants/action-type";
import { createAction } from "redux-actions";
import { IAnyProps } from "../../interfaces/any-props.interface";
import { getFormSearchField } from "../../selectors/form-search-fields";
import { ReactNode } from "react";

class ReduxSelectSearchField extends React.Component<
  WrappedFieldProps & {
    [key: string]: any;
    children?: ReactNode;
    searchText: string;
  } & WithStyles<any>
> {
  render() {
    const {
      input,
      label,
      meta: { touched, error },
      classes,
      renderItem,
      items,
      allowDisable,
      displayEmpty,
      children,
      ...custom
    } = this.props;

    const onChange = event => {
      input.onChange(event.target.value);
    };

    const close = event => {
      event.stopPropagation();
    };

    const reset = () => {
      input.onChange(0);
    };

    const handleSearchChange = event => {
      this.props.setLocationSearchText(event.target.value);
    };

    const filter = (item: any) => {
      return (
        (item.name || "")
          .toLocaleLowerCase()
          .indexOf(this.props.searchText.toLocaleLowerCase()) !== -1
      );
    };

    const focusInputField = input => {
      if (input) {
        setTimeout(() => {
          input.focus();
        }, 100);
      }
    };

    return (
      <div className="select-field-search">
        <FormControl
          className={classes.fullWidth}
          margin="normal"
          error={touched && error}
        >
          <InputLabel
            shrink={displayEmpty}
            htmlFor="select-multiple"
            error={touched && error}
          >
            {label}
          </InputLabel>
          <Select
            className={classes.fullWidth}
            fullWidth={true}
            {...input}
            onChange={onChange}
            displayEmpty={displayEmpty}
            {...custom}
          >
            {!!children && children}
            <MenuItem key={"Search"} onClick={close}>
              <TextField
                value={this.props.searchText}
                onChange={handleSearchChange}
                onClick={close}
                type="search"
                fullWidth
                autoFocus={true}
                inputRef={focusInputField}
                margin="normal"
              />
            </MenuItem>
            {items.filter(filter).map(renderItem)}
          </Select>
          {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
        </FormControl>
      </div>
    );
  }
}

const getFieldName = (ownProps: IAnyProps) =>
  `${ownProps.meta.form}_${ownProps.input.name}`;

const styled = withStyles({
  fullWidth: {
    width: "100%"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    width: 200
  },
  menu: {
    width: 200
  }
})(ReduxSelectSearchField as any);

const mapStateToProps = (state, ownProps: IAnyProps) => {
  return {
    searchText: getFormSearchField(getFieldName(ownProps))(state)
  };
};

export default connect(mapStateToProps, (dispatch, ownProps: IAnyProps) => {
  return {
    setLocationSearchText: text =>
      dispatch(
        createAction(FORM_SEARCH_FIELD_SET, () => {
          return {
            value: text,
            key: getFieldName(ownProps)
          };
        })()
      )
  };
})(styled);
