import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import Paper from "@material-ui/core/Paper/Paper";
import EnhancedTableHead from "../../../../../components/table/EnhancedTableHead";
import AdditionalDocumentsRow from "./AdditionalDocumentsRow";
import { Zoom } from "@material-ui/core";
import CreateAdditionalDocumentForm from "./AdditionalDocumentCreateModal";
import Icon from "@material-ui/core/Icon/Icon";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";
import { AdditionalDocumentType } from "../../../../../types";
import { useAdditionalDocumentsList } from "../../../../../hooks/use-additional-documents-list";
import { TableNoItems } from "../../../../../components/table/TableNoItems";
import { Grid } from "@material-ui/core";

interface IAdditionalDocumentsProps {
  testId?: number;
}

const AdditionalDocuments = (props: IAdditionalDocumentsProps) => {
  const { testId } = props;
  const [isOpenedModal, setIsOpenedModal] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const {
    additionalDocumentsList,
    getAdditionalDocumentsItems
  } = useAdditionalDocumentsList();
  const loadAdditionalDocuments = () =>
    getAdditionalDocumentsItems(testId as number);
  const closeModal = () => setIsOpenedModal(false);

  useEffect(() => {
    testId &&
      getAdditionalDocumentsItems(testId).then(() => setIsLoading(false));
  }, [testId]);

  if (isLoading) {
    return <TableNoItems asLoading hasWrapper />;
  }

  return (
    <div>
      <div className="block-header-wrapper flex-column-in-mobile">
        <h4 className="mt-4">
          Additional Documents
          <TooltipIcon
            placement={"top"}
            TransitionComponent={Zoom}
            disableFocusListener
            disableTouchListener
            title="Additional documents that can be created in addition to the final report"
          >
            <Icon
              style={{
                marginLeft: "5px",
                marginBottom: "2px",
                verticalAlign: "middle"
              }}
            >
              info
            </Icon>
          </TooltipIcon>
        </h4>
        {additionalDocumentsList.length < 5 && (
          <button
            className="btn-new mb=2"
            onClick={() => setIsOpenedModal(true)}
          >
            Add Additional Document
          </button>
        )}
      </div>

      {!additionalDocumentsList.length && <TableNoItems hasWrapper />}
      {!!additionalDocumentsList.length && (
        <div className="styled-block">
          <div className="table-responsive styled-table">
            <Table padding="dense">
              <EnhancedTableHead
                columns={["Document Name", "Uploaded Date", "", ""]}
              />
              <TableBody>
                {additionalDocumentsList.map(
                  (document: AdditionalDocumentType) => (
                    <AdditionalDocumentsRow
                      key={document.id}
                      additionalDocument={document}
                      reloadItems={loadAdditionalDocuments}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      )}

      {isOpenedModal && (
        <CreateAdditionalDocumentForm
          testId={testId}
          closeModal={closeModal}
          reloadItems={loadAdditionalDocuments}
        />
      )}
    </div>
  );
};

export default AdditionalDocuments;
