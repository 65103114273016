import React, { useContext } from "react";
import { TE_STATUS } from "../../../../constants/timesheet-expenses";
import {
  getTEStatusNameById,
  getTimesheetBillFileName
} from "../../../../helpers/timesheets-expenses";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getTimeExpensesWeekInvoiceAction } from "../../../../actions/time-expenses";
import {
  TimeSheetExpensesContextValueType,
  TimeSheetInvoiceLinkType,
  TimeSheetWeeksInvoiceAction
} from "../../../../types/timesheets-and-expenses";
import { TimeSheetExpensesContext } from "../../context/timeSheetsContext";

interface StatusCellProps {
  weekId?: number;
  weekNumber: string;
  statusId: TE_STATUS;
  consultantName: string;
  isEmptyTimeAndExpenses: boolean;
  invoiceNumber: string | null;
  expensesInvoiceNumber: string | null;
  getTimeExpensesWeekInvoice: (params: TimeSheetWeeksInvoiceAction) => void;
}

const StatusCell = ({
  statusId,
  weekId,
  weekNumber,
  isEmptyTimeAndExpenses,
  consultantName,
  invoiceNumber,
  expensesInvoiceNumber,
  getTimeExpensesWeekInvoice
}: StatusCellProps): JSX.Element | null => {
  const { isAdmin, isOwner } = useContext<TimeSheetExpensesContextValueType>(
    TimeSheetExpensesContext
  );
  const label = getTEStatusNameById(statusId);
  const statusLabel =
    statusId === TE_STATUS.STATUS_DRAFT && isEmptyTimeAndExpenses ? "" : label;
  if (!statusLabel) {
    return null;
  }

  const labelClassName = `timesheet-status timesheet-status--${statusLabel.toLowerCase()}`;
  if (statusId === TE_STATUS.STATUS_INVOICED && (isAdmin || isOwner)) {
    const billFileName = getTimesheetBillFileName(consultantName, weekNumber);

    return (
      <>
        <span className={labelClassName}>{statusLabel}</span>
        {invoiceNumber && (
          <button
            className="btn-cancel-default timesheet-status-link timesheet-status-link--invoice"
            onClick={() =>
              getTimeExpensesWeekInvoice({
                weekId: weekId as number,
                invoiceLinkType: TimeSheetInvoiceLinkType.INVOICE,
                fileName: invoiceNumber
              })
            }
          >
            {invoiceNumber}
          </button>
        )}
        {expensesInvoiceNumber && (
          <button
            className="btn-cancel-default timesheet-status-link timesheet-status-link--invoice"
            onClick={() =>
              getTimeExpensesWeekInvoice({
                weekId: weekId as number,
                invoiceLinkType: TimeSheetInvoiceLinkType.EXPENSES_INVOICE,
                fileName: expensesInvoiceNumber
              })
            }
          >
            {expensesInvoiceNumber}
          </button>
        )}

        {isAdmin && (invoiceNumber || expensesInvoiceNumber) && (
          <button
            className="btn-cancel-default timesheet-status-link timesheet-status--invoiced"
            onClick={() =>
              getTimeExpensesWeekInvoice({
                weekId: weekId as number,
                invoiceLinkType: TimeSheetInvoiceLinkType.BILL,
                fileName: billFileName
              })
            }
          >
            & Billed
          </button>
        )}
      </>
    );
  }
  return <span className={labelClassName}>{statusLabel}</span>;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTimeExpensesWeekInvoice: getTimeExpensesWeekInvoiceAction
    },
    dispatch
  );
};

const connected = connect(null, mapDispatchToProps)(StatusCell);

export default connected;
