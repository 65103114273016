import React from "react";
import { WrappedFieldArrayProps } from "redux-form/lib/FieldArray";

export const AddRepeaterFieldButton = (props: WrappedFieldArrayProps) => {
  const {
    fields: { name, push }
  } = props;
  const initialValues = {
    projects: { project: "", projectRate: "" }
  };

  return (
    <div className="ml-md-5 ml-sm-0 pt-2">
      <button
        className="btn-new"
        type="button"
        onClick={() => push(initialValues[name])}
      >
        Assign project
      </button>
    </div>
  );
};
