import React from "react";
import DownloadRepeaterButton from "./DownloadRepeaterButton";
import RemoveRepeaterButton from "./RemoveDocumentRepeaterBtn";
import { MAX_TIMESHEET_FILE_SIZE } from "../../../../constants/document";
import { VALIDATION_ERROR_MESSAGES } from "../../../../constants/timesheet-expenses";

export const TimesheetDocumentsRepeater = props => {
  const { fields } = props;
  return (
    <>
      {fields.map((name: string, index: number) => {
        const field = fields.get(index);
        let largeFileError =
          field.document?.size && field.document.size > MAX_TIMESHEET_FILE_SIZE;

        return (
          <div key={index} className="document-repeater">
            <div className="document-repeater__item">
              <div className="document-repeater__remove">
                <RemoveRepeaterButton
                  fields={fields}
                  index={index}
                  id={field?.document?.id}
                />
              </div>
              <DownloadRepeaterButton
                id={field?.document?.id}
                name={field?.document?.name}
              />
            </div>
            {largeFileError && (
              <span className="error-color">
                {VALIDATION_ERROR_MESSAGES.LARGE_FILE_SIZE}
              </span>
            )}
          </div>
        );
      })}
    </>
  );
};
