import * as React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCellEdit from "./TableCellEdit";
import TableCellArchive from "./TableCellArchive";
import TableCell from "@material-ui/core/TableCell/TableCell";
import * as _ from "lodash";
import { FILTER_TYPE } from "../../constants/filter";

class EnhancedTableRow extends React.Component<
  {
    item: any;
    baseUrl: string;
    showStatus: FILTER_TYPE;
    onArchive: (item: any) => void;
    onUnarchive: (item: any) => void;
    needActions: any;
    children: any[] | any;
  },
  any
> {
  onArchive = () => {
    this.props.onArchive(this.props.item);
  };

  onUnarchive = () => {
    this.props.onUnarchive(this.props.item);
  };

  render() {
    const { item, needActions } = this.props;

    return (
      <TableRow key={item.id}>
        {_.isArray(this.props.children) ? (
          this.props.children.map((data, index) => (
            <TableCell key={index}>{data}</TableCell>
          ))
        ) : (
          <TableCell>{this.props.children}</TableCell>
        )}
        {needActions && (
          <>
            <TableCellEdit
              status={this.props.showStatus}
              item={item}
              baseUrl={this.props.baseUrl}
            />
            <TableCellArchive
              onArchive={this.onArchive}
              onUnarchive={this.onUnarchive}
              item={item}
            />
          </>
        )}
      </TableRow>
    );
  }
}

export default EnhancedTableRow;
