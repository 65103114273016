import * as React from "react";
import { Paper, WithStyles, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import { styles } from "../../../styles/material/tesrer-card";
import {
  ITesterIncoming,
  ITesterProfile,
  ITesterProfileGet
} from "../../../models/tester-profile.interface";
import {
  getProfile,
  getTesterSpecialisms
} from "../../../selectors/tester-profile";
import { ISpecialism } from "../../../models/specialism.interface";
import ProfileEdit from "./ProfileEdit";
import { RouteComponentProps } from "react-router-dom";
import { getSpecialismsFlat } from "../../../helpers/specialisms";
import { getTesterLocationString } from "../../../helpers/location-helper";
import {
  API_SECURE_DOCUMENTS_ENDPOINT_URL,
  BASE_URL
} from "../../../constants/urls";
import { getTesterPriceRange } from "../../../helpers/tester-profile";
import { downloadSecureDocument } from "../../../api/requests/document-download";
import countriesSelector from "../../../selectors/countries";
import { ICountry } from "../../../models/dialing-code.interface";
import { replaceNToBr } from "../../../helpers/converter-helper";
import { RawHTML } from "../../../components/RawHTML";
import { Switch as Slider } from "@material-ui/core";
import {
  changeReceiveBookingRequests,
  changeReceiveBookingRequestsAction
} from "../../../actions";
import { connect } from "react-redux";
import { Group } from "../../../constants/group";
import { TableNoItems } from "../../../components/table/TableNoItems";

interface IProps {
  countries: ICountry[];
  profile: ITesterProfile & ITesterIncoming & ITesterProfileGet;
  profileIsLoaded: boolean;
  specialisms: ISpecialism[];
  changeReceiveBookingRequests: any;
  [key: string]: any;
}

class ProfileCard extends React.Component<
  IProps & WithStyles & RouteComponentProps<{ id: string }>
> {
  baseUrl = this.props.match.path;

  editComponent = () => <ProfileEdit backRoute={this.baseUrl} />;

  highlight = () => {
    const { specialisms, profile } = this.props;

    if ((specialisms && !specialisms.length) || !profile.bio) {
      return " box-shadow-danger";
    }

    return "";
  };

  downloadFile = () => {
    const certificateId = this.props.profile.companyCrestCertificate.replace(
      API_SECURE_DOCUMENTS_ENDPOINT_URL,
      ""
    );

    if (certificateId) {
      this.props.dispatch(downloadSecureDocument(certificateId));
    }
  };

  getCountryName(id: number) {
    const country = this.props.countries.find(
      (country: ICountry) => country.id === id
    );

    return country ? country.name : "";
  }

  handleSliderChange = () => {
    const {
      profile,
      dispatch,
      changeReceiveBookingRequestsAction
    } = this.props;
    if (profile && profile.user) {
      changeReceiveBookingRequestsAction(
        profile.user.testerProfileId,
        !profile.availableForBooking
      );
      dispatch(changeReceiveBookingRequests(!profile.availableForBooking));
    }
  };

  render() {
    if (!this.props.profileIsLoaded) {
      return <TableNoItems asLoading hasWrapper />;
    }
    return (
      <div>
        <Paper className={"p-4" + this.highlight()}>
          <div className="d-flex justify-content-between">
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <h4>My Profile</h4>
              {this.props.profile.user &&
                this.props.profile.user.groupName === Group.MANAGER && (
                  <>
                    <Slider
                      checked={this.props.profile.availableForBooking}
                      onChange={this.handleSliderChange}
                    />{" "}
                    Receive booking requests (allow clients to find my account)
                  </>
                )}
            </div>
            <Link to={`${this.baseUrl}/edit-profile`}>
              <Button size="small" color="primary">
                Edit
              </Button>
            </Link>
          </div>
          {this.props.profile.user &&
            this.props.profile.user.groupName === Group.MANAGER && (
              <div className="mb-4 tester-profile-note">
                Note: By disabling this feature your profile will not be seen in
                client searches
              </div>
            )}
          <hr />

          <div className="row">
            <div className="col-md-9">
              <div className="table-responsive">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{`${this.props.profile.testerFirstName} ${this.props.profile.testerLastName}`}</td>
                    </tr>

                    <tr>
                      <th>Rate</th>
                      <td>{getTesterPriceRange(this.props.profile)}</td>
                    </tr>

                    {this.props.profile.vatNumber ? (
                      <tr>
                        <th>Vat Number</th>
                        <td>{this.props.profile.vatNumber}</td>
                      </tr>
                    ) : null}

                    {this.props.profile.isGovernmentCleared ? (
                      <tr>
                        <th>Government Cleared</th>
                        <td>Yes</td>
                      </tr>
                    ) : null}

                    {this.props.profile.companyCrestCertificate ? (
                      <tr>
                        <th>CREST certificate</th>
                        <td>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.downloadFile}
                          >
                            Download
                          </Button>
                        </td>
                      </tr>
                    ) : null}

                    {this.props.profile.checkRegisteredCompany ? (
                      <tr>
                        <th>CHECK Scheme ID</th>
                        <td>{this.props.profile.checkRegisteredCompany}</td>
                      </tr>
                    ) : null}

                    {this.props.profile.country ? (
                      <tr>
                        <th>Country of origin</th>
                        <td>
                          {this.getCountryName(this.props.profile.country)}
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <th>Location</th>
                      <td>{getTesterLocationString(this.props.profile)}</td>
                    </tr>

                    <tr>
                      <th scope="row" className={this.props.classes.th}>
                        {!!this.props.profile.testingCompanyName
                          ? "Testing Company"
                          : "Company"}
                      </th>
                      <td>
                        {this.props.profile.testingCompanyName ||
                          this.props.profile.companyName}
                      </td>
                    </tr>

                    {this.props.profile.companyNumber ? (
                      <tr>
                        <th>Company registration number</th>
                        <td>{this.props.profile.companyNumber}</td>
                      </tr>
                    ) : null}

                    {this.props.specialisms ? (
                      <tr>
                        <th>Specialisms</th>
                        <td>
                          {getSpecialismsFlat(this.props.specialisms)
                            .map(a => a.name)
                            .join(", ")}
                        </td>
                      </tr>
                    ) : null}

                    {this.props.profile.linkedinLink ? (
                      <tr>
                        <th>LinkedIn profile</th>
                        <td>
                          <a
                            target="_blank"
                            href={this.props.profile.linkedinLink}
                          >
                            LinkedIn
                          </a>
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <th scope="row" className={this.props.classes.th}>
                        Bio
                      </th>
                      <td>
                        <div>
                          <RawHTML>
                            {replaceNToBr(
                              this.props.profile.bio
                                ? this.props.profile.bio
                                : ""
                            )}
                          </RawHTML>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-3">
              <span className="rounded-image">
                <img
                  className="d-block w-100"
                  src={
                    this.props.profile.profileImagePath
                      ? BASE_URL + this.props.profile.profileImagePath
                      : "/images/user.svg"
                  }
                  alt={`${this.props.profile.testerFirstName} ${this.props.profile.testerLastName}`}
                />
              </span>
            </div>
          </div>
        </Paper>
        <Switch>
          <Route
            path={`${this.baseUrl}/edit-profile`}
            render={this.editComponent}
          />
        </Switch>
      </div>
    );
  }
}

const styled = withStyles(styles as any)(ProfileCard) as any;
const componentWithRouter = withRouter(styled);

export default connect<any>(
  state => {
    return {
      profile: getProfile(state).item,
      profileIsLoaded: getProfile(state).loaded,
      countries: countriesSelector.getItems(state),
      specialisms: getTesterSpecialisms(state).item
    };
  },
  {
    changeReceiveBookingRequestsAction
  }
)(componentWithRouter);
