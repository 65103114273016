import getCrudSelectors from "../helpers/selector-helper";
import { createSelector } from "reselect";

const getUsers = (state) => state.users;

const crudSelectors = getCrudSelectors(getUsers);

const getFilterName = (state) => state.name;
const getFilterEmail = (state) => state.email;
const getFilterPhone = (state) => state.phone;
const getFilterDate = (state) => state.date;

const getFilterTextName = createSelector(
  crudSelectors.getFilter,
  getFilterName
);

const getFilterTextEmail = createSelector(
  crudSelectors.getFilter,
  getFilterEmail
);

const getFilterTextPhone = createSelector(
  crudSelectors.getFilter,
  getFilterPhone
);

const getFilterTextDate = createSelector(
  crudSelectors.getFilter,
  getFilterDate
);

export const getExtraPermissions = (state) =>
  state.users.permissions.extraPermissions;

export default crudSelectors;
